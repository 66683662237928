import React, { Suspense } from 'react';
import useApplicationContext from '../../hooks/useApplicationContext';
import usePrompterSession from '../../state/PrompterSessionState';
import { EndpointRole } from '@fluidprompter/core';

const PeerConnectionAccountRequiredRenderer = React.lazy(() => import('./PeerConnectionAccountRequiredRenderer'));

const PeerConnectionAccountRequired = () => {

  const {
    appContext,
  } = useApplicationContext();
  const { isLoading, isAuthenticated } = appContext;

  const connectedDevices = usePrompterSession(state => state.connectedDevices);
  const connectedPrompters = connectedDevices.filter(device => device.endpointRole === EndpointRole.Prompter);

  // If we are finished loading auth state, and we are in fact not-authenticated
  const isOpen = process.env.REACT_APP_ENV !== 'development' && !isLoading && !isAuthenticated && connectedPrompters.length > 1;

  return (
    <Suspense>
      {isOpen && <PeerConnectionAccountRequiredRenderer />}
    </Suspense>
  );
};

export default PeerConnectionAccountRequired;