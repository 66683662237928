import React, { useEffect } from 'react';
import { AppMessageMap } from '@fluidprompter/core';

import AppController, { Handler } from './AppController';
import { MessageHandlerEvent } from './MessageHandlerEvent';
import { MessageContext } from './MessageContext';

export { MessageHandlerEvent, MessageContext };
export const AppControllerContext = React.createContext<AppController | null>(null);
const P = AppControllerContext.Provider;

/**
 * Return the event emitter.
 *
 * @return {AppController}
 */
export function useAppController (): AppController {
  const appController = React.useContext(AppControllerContext);
  if (!appController) throw new Error('useAppController: missing context');
  return appController;
}

export function useMessageHandler<MessageType extends keyof AppMessageMap>(
  message: MessageType,
  messageHandler: Handler<AppMessageMap[MessageType]>
) {
  const appController = useAppController();
  React.useEffect(() => {
    appController.on(message, messageHandler);
    return () => {
      appController.off(message, messageHandler);
    };
  }, [appController, message, messageHandler]);
}

interface ProviderProps {
  children?: React.ReactNode,
}
/**
 * Create an event emitter that will be available to all deeply nested child elements using the useBus() hook.
 *
 * @param {{ children?: import('react').ReactNode }} props
 */
export function Provider(props: ProviderProps): JSX.Element {
  const { children } = props;

  const appController = AppController.instance;

  useEffect(() => {
    // Create listener references
    const handleWindowFocus = appController.handleWindowFocus.bind(appController);  //
    const handleWindowBlur = appController.handleWindowBlur.bind(appController);
    const handleWindowPageShow = appController.handleWindowPageShow.bind(appController);
    const handleDocumentVisibilityChange = appController.handleDocumentVisibilityChange.bind(appController);

    // Subscribe to page lifecycle events.
    window.addEventListener('focus', handleWindowFocus);
    window.addEventListener('blur', handleWindowBlur);
    window.addEventListener('pageshow', handleWindowPageShow);
    document.addEventListener('visibilitychange', handleDocumentVisibilityChange);

    // Clean-up
    return () => {
      // Unsubscribe to page lifecycle events.
      window.removeEventListener('focus', handleWindowFocus);
      window.removeEventListener('blur', handleWindowBlur);
      window.removeEventListener('pageshow', handleWindowPageShow);
      document.removeEventListener('visibilitychange', handleDocumentVisibilityChange);
    };
  }, [appController]);

  return (<P value={appController}>{children}</P>);
}

export default AppController;