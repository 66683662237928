import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AlarmIcon from '@mui/icons-material/Alarm';

import useConfigurationStore from '../../../state/ConfigurationStore';
import usePrompterSession from '../../../state/PrompterSessionState';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import './RenderSecondsElapsed.scss';

const elapsedTimeStringFromSeconds = function(totalSeconds?: number): string {

  const totalElapsedMs = totalSeconds || 0;

  const elapsedHours = Math.floor((totalElapsedMs / (60 * 60)) % 24);
  const elapsedMinutes = Math.floor((totalElapsedMs / 60) % 60);
  const elapsedSeconds = Math.floor((totalElapsedMs) % 60);

  const elapsedHoursString = ('0' + elapsedHours).slice(-2);
  const elapsedMinutesString = ('0' + elapsedMinutes).slice(-2);
  const elapsedSecondsString = ('0' + elapsedSeconds).slice(-2);

  return (elapsedHours > 0) ? `${elapsedHoursString}:${elapsedMinutesString}:${elapsedSecondsString}` : `${elapsedMinutesString}:${elapsedSecondsString}`;
};

interface RenderSecondsElapsedProps {
  openMenuFn: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

function useRemainingTimeCalculator() {
  //
  // Avoid recreating the useEffect everytime the value changes state. Instead keep a local ref of the last set value.
  //
  const lastValue = useRef<number>(0);
  const scrollSpeed = useRef<number>(useConfigurationStore.getState().scrollSpeed);
  const scrollPosition = useRef<number>(usePrompterSession.getState().scrollPosition || 0);

  const [remainingSeconds, setRemainingSeconds] = useState<number>();
  const viewportMeta = usePrompterSession(state => state.viewportMeta);
  const scriptNodesMeta = usePrompterSession(state => state.scriptNodesMeta);

  const updateRemainingSeconds = React.useCallback(() => {
    // If we are just too early in the application life cycle we won't have the information we need to estimate remaining time.
    if(!scriptNodesMeta) {
      return;
    }

    const contentHeight = scriptNodesMeta.contentHeight;
    const viewportHeight = viewportMeta.viewportHeight;

    const totalScrollRange = contentHeight - viewportHeight;

    const currentScrollSpeed = scrollSpeed.current; // useConfigurationStore.getState().scrollSpeed;
    const currentScrollPosition = scrollPosition.current;

    const remainingTime = Math.round((totalScrollRange - currentScrollPosition) / currentScrollSpeed);

    if(lastValue.current !== remainingTime) {
      lastValue.current = remainingTime;
      setRemainingSeconds(remainingTime);
    }
  }, [scriptNodesMeta]);

  //
  // Subscribe to changes in the scrollSpeed
  //
  React.useEffect(() => {
    const unsubscribe = useConfigurationStore.subscribe(
      (config, previousConfig) => {
        if(config.scrollSpeed === previousConfig.scrollSpeed) {
          return;
        }

        scrollSpeed.current = config.scrollSpeed;
        updateRemainingSeconds();
      }
    );

    return unsubscribe;
  }, [updateRemainingSeconds]);

  //
  // Subscribe to changes in the scrollPosition
  //
  React.useEffect(() => {
    const unsubscribe = usePrompterSession.subscribe(
      (config, previousConfig) => {
        const currentScrollPosition = config.scrollPosition;
        const previousScrollPosition = previousConfig.scrollPosition;
        if(!currentScrollPosition || currentScrollPosition === previousScrollPosition) {
          return;
        }

        scrollPosition.current = currentScrollPosition;
        updateRemainingSeconds();
      }
    );

    return unsubscribe;
  }, [updateRemainingSeconds]);

  //
  // Initial component mount.
  //
  React.useEffect(() => {
    updateRemainingSeconds();
  }, [updateRemainingSeconds]);

  return remainingSeconds;
}

interface RenderSecondsElapsedRenderProps {
  openMenuFn: (e: React.MouseEvent<HTMLButtonElement>) => void;
  viewportIsLarge?: boolean;
}

const RenderSecondsElapsedTypography = () => {
  const elapsedPlaySeconds = usePrompterSession(state => state.elapsedPlaySeconds);

  return (<>
    <Typography component={'span'} fontSize='inherit' color='error'> E: </Typography>
    <Typography component={'span'} fontSize='inherit'>{elapsedTimeStringFromSeconds(elapsedPlaySeconds)}</Typography>
  </>);
};

const RenderSecondsRemainingTypography = () => {
  const timeRemainingSeconds = useRemainingTimeCalculator();

  return (<>
    <Typography component={'span'} fontSize='inherit' color='info.main'>  R: </Typography>
    <Typography component={'span'} fontSize='inherit'>{timeRemainingSeconds !== undefined ? elapsedTimeStringFromSeconds(timeRemainingSeconds) : '?'}</Typography>
  </>);
};

const RenderSecondsElapsedRenderer = React.memo(function RenderSecondsElapsedRenderer(props: RenderSecondsElapsedRenderProps) {
  return (
    <Button
      className={'RenderSecondsElapsed'}
      size="large"
      startIcon={ <AlarmIcon /> }
      onFocus={(e) => { e.target.blur(); }}
      onClick={props.openMenuFn}
    >
      <RenderSecondsElapsedTypography />
      {props.viewportIsLarge && <RenderSecondsRemainingTypography />}
    </Button>
  );
});

function RenderSecondsElapsed(props: RenderSecondsElapsedProps) {
  const theme = useTheme();
  const viewportIsLarge = useMediaQuery(theme.breakpoints.up('md'));

  return (<RenderSecondsElapsedRenderer openMenuFn={props.openMenuFn} viewportIsLarge={viewportIsLarge} />);
}

export default RenderSecondsElapsed;