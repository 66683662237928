import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';

import useConfigMessageHandlers from '../../hooks/useConfigMessageHandlers';

import { isIOS, isTablet } from 'react-device-detect';

import SessionTimerDisplay from '../Navbar/SessionTimerDisplay';
import CenterControls from './CenterControls';
import SpeedSlider from './SpeedSlider';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import './ControlBar.scss';

const ControlBar = () => {

  const theme = useTheme();
  const viewportIsLargeOrLarger = useMediaQuery(theme.breakpoints.up('md'));
  const viewportIsMediumOrLarger = useMediaQuery(theme.breakpoints.up('sm'));

  useConfigMessageHandlers();

  return (
    <AppBar
      className='ControlBar'
      position="absolute"
      elevation={0}
      sx={{
        top: 'auto',
        bottom: (isIOS && isTablet ? '13px' : 0),
        /*
        transform: (state.isPlaying && store.cursorHidden) ? 'translateY(100%)': 'none',
        transition: 'transform 500ms ease-in-out',
        */
      }}
    >
      <Toolbar disableGutters>
        <Grid container columns={12} marginLeft={viewportIsLargeOrLarger ? 2 : 1} marginRight={viewportIsLargeOrLarger ? 2 : 1}>
          <Grid item xs={6} sm={4} order={{ xs: 2, sm: 1 }} container alignItems="center" justifyContent="start">
            <SessionTimerDisplay initialType="ElapsedSeconds" />
          </Grid>
          <Grid item xs={12} sm={4} order={{ xs: 1, sm: 2 }} container alignItems="center" justifyContent="center">
            <CenterControls viewportIsMediumOrLarger={viewportIsMediumOrLarger} viewportIsLargeOrLarger={viewportIsLargeOrLarger} />
          </Grid>
          <Grid item xs={6} sm={4} order={{ xs: 3, sm: 3 }} container alignItems="center" justifyContent="end" columnGap={viewportIsLargeOrLarger ? 2 : 1} /*paddingRight={viewportIsLarge ? 2 : 1}*/>
            <SpeedSlider viewportIsLargeOrLarger={viewportIsLargeOrLarger} />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default ControlBar;