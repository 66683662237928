import React from 'react';
import PositionLedgerEntry from './PositionLedgerEntry';
import useConfigurationStore from '../../state/ConfigurationStore';
import usePrompterSession from '../../state/PrompterSessionState';
import { IPrompterSegmentsContainerRef } from '../PrompterEditor';
import AHDSRStateMachine from '../../controllers/AHDSRStateMachine';

export interface IViewportRefs {
  segmentsContainerRef: React.RefObject<IPrompterSegmentsContainerRef>,
  prompterViewportRef: React.RefObject<HTMLElement>,
  requestRef: React.MutableRefObject<number>,
  previousLedgerRef: React.MutableRefObject<PositionLedgerEntry | undefined>,
  scrollReversedRef: React.MutableRefObject<boolean>,
  scrollSpeedRef: React.MutableRefObject<number>,
  scrollPositionMaxRef: React.MutableRefObject<number>,
  ahdsrRef: React.MutableRefObject<AHDSRStateMachine | undefined>,
}

interface usePrompterViewportRefsProps {
  prompterViewportRef: React.RefObject<HTMLElement>;
}

function usePrompterViewportRefs(props: usePrompterViewportRefsProps): IViewportRefs {

  // Reference to our main PrompterViewport div.
  const { prompterViewportRef } = props;

  // Reference to hold any requestAnimationFrame identifiers.
  const requestRef = React.useRef<number>(-1);

  // Ref for our prompterSegmentsContainer contains a few methods to interact with prompter segments container.
  const segmentsContainerRef = React.useRef<IPrompterSegmentsContainerRef>(null);

  // Reference to hold the previous state of the teleprompter scroll animation
  const previousLedgerRef = React.useRef<PositionLedgerEntry>();

  // Fetch initial scrollReversed state without taking on a dependency that would cause rerender when it changes.
  const scrollReversedRef = React.useRef<boolean>(useConfigurationStore.getState().scrollReversed === true);

  // Fetch initial scrollSpeed state without taking on a dependency that would cause rerender when it changes.
  const scrollSpeedRef = React.useRef<number>(useConfigurationStore.getState().scrollSpeed);

  // Connect to the store on mount, disconnect on unmount, catch state-changes in a reference
  React.useEffect(() => {
    const unsubscribe = useConfigurationStore.subscribe((state) => {
      scrollSpeedRef.current = state.scrollSpeed;
      scrollReversedRef.current = (state.scrollReversed === true);
    });

    return unsubscribe;
  }, []);

  // Fetch initial scrollPositionMax state without taking on a dependency that would cause rerender when it changes.
  const scrollPositionMaxRef = React.useRef<number>(usePrompterSession.getState().getScrollPositionMax());

  // Connect to the store on mount, disconnect on unmount, catch state-changes in a reference
  React.useEffect(() => {
    const unsubscribe = usePrompterSession.subscribe(
      state => (scrollPositionMaxRef.current = state.getScrollPositionMax())
    );

    return unsubscribe;
  }, []);

  // AHDSR State Machine for momentary prompter scrolling.
  // The instance will be created in the usePrompterAHDSRScrolling hook.
  const ahdsrRef = React.useRef<AHDSRStateMachine>();

  //
  // We don't want to return a new object (containing the same instances of Refs) every time this
  // hook runs as that results in downstream components with a dependency on viewportRefs to also
  // rerender/rerun.
  //
  return React.useMemo<IViewportRefs>(() => ({
    prompterViewportRef,
    requestRef,
    segmentsContainerRef,
    previousLedgerRef,
    scrollReversedRef,
    scrollSpeedRef,
    scrollPositionMaxRef,
    ahdsrRef,
  }), [
    prompterViewportRef,
    requestRef,
    segmentsContainerRef,
    previousLedgerRef,
    scrollReversedRef,
    scrollSpeedRef,
    scrollPositionMaxRef,
    ahdsrRef,
  ]);
}

export default usePrompterViewportRefs;