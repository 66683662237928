import React, { useMemo } from 'react';
import StatsCollector from '../utils/StatsCollector';

function useStatsCollector(collectionPeriod?: number) {
  const configMs: number = collectionPeriod || 1000;

  const statsCollector = useMemo<StatsCollector>(() => new StatsCollector(configMs), [configMs]);

  return statsCollector.sample;
}

export default useStatsCollector;