import React, { useEffect, useCallback, useMemo, forwardRef, useImperativeHandle, useRef } from 'react';
import { useAppController, useMessageHandler } from '../../controllers/AppController';

// Slate Imports
import { Editor, BaseEditor, createEditor, Range, Descendant, Node, Transforms, BaseOperation, Path } from 'slate';
import { ReactEditor, withReact } from 'slate-react';
import { PrompterEditor, ElementTypes, PrompterElement, PrompterSegment, PrompterText, BRoll, Pause } from '../../models/EditorTypes';

// Slate Editor Plugins
import { withHistory, HistoryEditor } from 'slate-history';  // TODO: Google 'slate-history HistoryEditor.undo()'
import withSlateCustomizations from './withSlateCustomizations';
// import * as Y from 'yjs';
// import { withCursors, withYHistory, withYjs, YjsEditor } from '@slate-yjs/core';
// import RemoteCursorOverlay from './Collaboration/RemoteCursorOverlay';

// Slate Editor Rendering
import PrompterEditorRenderer, { PrompterEditorRendererProps } from './PrompterEditorRenderer';
import WordLimitNotice from './WordLimitNotice';
import EndElementOffScript from './EndElementOffScript';

import useResizeObserver from '@react-hook/resize-observer';
import isHotkey from 'is-hotkey';

import useEditorTextMetrics from './useEditorTextMetrics';

import useSelection from './useSelection';

import usePrompterContentMeasurements from './usePrompterContentMeasurements';
import useEditorLoadScriptCommandHandler from './useEditorLoadScriptCommandHandler';

import useEditorFormatCommandHandlers from './useEditorFormatCommandHandlers';
import useEditorFocusCommandHandler from './useEditorFocusCommandHandler';
import useEditorSelectAllCommandHandler from './useEditorSelectAllCommandHandler';
import useEditorSplitSegmentCommandHandler from './useEditorSplitSegmentCommandHandler';
import useVoiceTypingCommandHandlers, { VoiceTypingCommands } from './useVoiceTypingCommandHandlers';
import usePrompterInputMiddleware from './usePrompterInputMiddleware';

import { EditorOperationsMessage, EndpointRole, ScrollDirection } from '@fluidprompter/core';
import { PrompterScrolledEventArgs } from '../PrompterViewport/usePrompterScrolledHandler';
import { PrompterResizedEventArgs } from '../PrompterViewport/usePrompterResizedHandler';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

import pipe from 'lodash/fp/pipe';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';

import usePrompterSession from '../../state/PrompterSessionState';

import ShotLogModal from './ShotLogModal';
import useSegmentElementLogic from './SegmentElement/useSegmentElementLogic';
import usePauseElementLogic from './PauseElement/usePauseElementLogic';
// import useEndElementLogic from './EndElement/useEndElementLogic';
import { IViewportFuncs } from '../PrompterViewport/usePrompterViewportFuncs';

import CameraRollIcon from '@mui/icons-material/CameraRoll';
import PauseIcon from '@mui/icons-material/Pause';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

import './PrompterContentContainer.scss';
import './PrompterTheme.scss';
import { isIOS, isMacOs } from 'react-device-detect';

export interface PrompterViewportProps {
  textSize: number;
  textColor: string;
  contentWidth: number;
  leftGutter: number;
  rightGutter: number;
  flipHorizontal: boolean;
  flipVertical: boolean;
}

// const sharedType = provider.document.get('content', Y.XmlText) as Y.XmlText;

const withYjsMiddleware = (editor: BaseEditor) => {
  return editor;
  /*
  return withYHistory(
    withCursors(
      withYjs(editor, sharedType, { autoConnect: false }),
      provider.awareness,
      {
        data: cursorData,
      }
    )
  );
  */
};

//
// Pipe our Slate editor plugins
// The first item in this list is fed the return value of the next function in the list, and so on.
//
const pipeEditorPlugins = pipe(
  withHistory,
  withSlateCustomizations,  // This is our FluidPrompter customizations for slate.

  // withYjs,
  // withCursors,
  // withYHistory,
  withYjsMiddleware,

  withReact,
  // withImage
);

export type PerformScrollFunctionType = (documentScrollPosition: number, scrollBehavior?:ScrollBehavior) => void;

interface IPrompterSegmentsContainerDimensions {
  viewportHeight: number,
  contentHeight: number,
  scrollPositionMax: number,
}

export interface IPrompterSegmentsContainerRef {
  prompterContentRef: React.RefObject<HTMLDivElement>;
  getContainerDimensions: () => IPrompterSegmentsContainerDimensions;
  getViewportHeight: () => number;
  getContentHeight: () => number;
  scroll: PerformScrollFunctionType;
}

interface PrompterContentProps {
  viewportFuncs: IViewportFuncs;

  textSize: number;
  textColor: string;
  lineHeight: number;
  contentWidth: number;
  leftGutter: number;
  rightGutter: number;
  flipHorizontal: boolean;
  flipVertical: boolean;
  // onScroll: (e: PrompterScrolledEventArgs) => void;
  onResize: (e: PrompterResizedEventArgs) => void;

  readOnly: boolean;
}

const PrompterEditorContainer = forwardRef<IPrompterSegmentsContainerRef, PrompterContentProps>(function PrompterEditorContainer(props: PrompterContentProps, ref) {

  const appController = useAppController();

  const scrollTopRef = React.useRef(0);
  const lineHeightRef = React.useRef(0);

  const editor = useMemo(() => pipeEditorPlugins(createEditor()) as PrompterEditor, []);
  const prompterContentRef = React.useRef<HTMLDivElement>(null);

  // Hook will recalculate script textMetrics when the script content changes.
  const {
    calculateTextMetrics,
    requestUpdateTextMetrics,
  } = useEditorTextMetrics();

  //
  // When another prompter peer begins editing (focus slate) all other peers must stop editing
  // by blurring the slate editor.
  //
  useMessageHandler('editor.active', (e) => {
    const { message, originatedRemotely } = e;
    const { sender } = message;
    const senderIsRemote = sender?.role === EndpointRole.Remote;

    const prompterSession = usePrompterSession.getState();

    //
    // If we receive Play/Pause/Edit/Navigate commands from a remote peer, that peer is currently
    // acting as prompter leader.
    //
    // If this message was sent by a prompter (and not a headless remote), then we will re-evaluate
    // whether we are the current leader or not.
    const isLeader = e.checkIAmLeader(prompterSession);

    e.sendToPeers = isLeader && (!originatedRemotely || senderIsRemote);

    if(originatedRemotely) {
      // ReactEditor.deselect(editor);
      ReactEditor.blur(editor);
    }

    if(
      originatedRemotely
      && !isLeader
      && !senderIsRemote
    ) {
      //
      // Apply the remote prompter script position to the local prompter.
      //
      e.syncScrollPosition();
    }
  });

  //
  // Implements both measuring script nodes in the DOM and then figuring out their relative
  // position compared to the viewport and cue position.
  //
  const {
    //
    // Returns a promise that resolves with the next calculated ScriptNodesMeta (dimensions for
    // each script node, etc).
    // TODO: promiseNextScriptNodesMeta,
    //
    // Returns a promise that resolves with the next calculated ScriptNodesState (before/after
    // cue position, etc)
    // TODO: promiseNextScriptNodesState,

    //
    // Measure all script nodes - call this when the script content changes
    doMeasureScriptNodes,
    //
    // Gets called in `onPrompterScrolled` to perform a recalculation of our script node positions
    // compared to the current prompter scroll position
    doRecalculateSegmentPositions,
  } = usePrompterContentMeasurements(appController, editor, prompterContentRef);

  //
  // What do we want to do when the editor content changes?
  //
  const calculateTextMetricsWithWordLimit = useCallback((doc: Descendant[]) => {
    const { wordLimit } = usePrompterSession.getState();
    calculateTextMetrics(doc, wordLimit);
  }, [calculateTextMetrics]);
  const onReplaceScriptAfterRender = useCallback((doc: Descendant[]) => {
    try {
      doMeasureScriptNodes();
    } catch(err) {
      console.error('doMeasureScriptNodes()', err);
    }

    try {
      doRecalculateSegmentPositions();
    } catch(err) {
      console.error('doRecalculateSegmentPositions()', err);
    }
  }, [doMeasureScriptNodes, doRecalculateSegmentPositions]);
  const doUpdateEditorMetaOnScriptChange = useCallback((doc: Descendant[]) => {
    calculateTextMetricsWithWordLimit(doc);

    onReplaceScriptAfterRender(doc);
  }, [calculateTextMetricsWithWordLimit, onReplaceScriptAfterRender]);

  const requestUpdateEditorMetaOnScriptChange = useCallback(throttle((doc: Descendant[], loadScriptInProgress: boolean) => {
    // console.log(`requestUpdateEditorMetaOnScriptChange(loadScriptInProgress = ${loadScriptInProgress})`);

    //
    // If we are currently loading a new script, then this onEditorChangeCallback is being executed
    // as a result of editor normalization.
    if(loadScriptInProgress) {
      return;
    }

    doUpdateEditorMetaOnScriptChange(doc);
  }, 500, { leading: false, trailing: true }), [doUpdateEditorMetaOnScriptChange]);

  //
  // Handler for 'script.request', 'script.state', 'editor.operations' app messages.
  //
  // Provides `onEditorChange()` event handler to be passed to the editor.
  // onEditorChange() handler will dispatch any editor operations the originated locally and then
  // call throttled functions `requestUpdateTextMetrics()`, `requestMeasureScriptNodes()` and
  // `requestRecalculateSegmentPositions()`
  //
  const {
    editableKey,
    onEditorChange,
  } = useEditorLoadScriptCommandHandler({
    appController,
    editor,
    viewportFuncs: props.viewportFuncs,
    onReplaceScriptBeforeRender: calculateTextMetricsWithWordLimit,
    onReplaceScriptAfterRender,
    onEditorChangeCallback: requestUpdateEditorMetaOnScriptChange,
  });

  // Handler for bold, italic, underline, etc
  const formatCommands = useEditorFormatCommandHandlers(editor, props.viewportFuncs);

  // Handler for 'prompter.editor.focusatcue' command.
  useEditorFocusCommandHandler(editor, props.viewportFuncs);

  // Handler for 'prompter.editor.selectall' command.
  useEditorSelectAllCommandHandler(editor, props.viewportFuncs);

  // Handler for 'prompter.editor.segment.split' command.
  useEditorSplitSegmentCommandHandler(editor);

  // Handlers for 'prompter.editor.voicetyping.start' and 'prompter.editor.voicetyping.stop'
  const voiceTypingCommands: VoiceTypingCommands = useVoiceTypingCommandHandlers(editor, props.viewportFuncs);

  // User Input Middleware
  usePrompterInputMiddleware(props.viewportFuncs);

  //
  // Prompt the user before trying to navigate away from FluidPromtper in the browser.
  // This helps prevent unwanted state loss due to accidental navigation.
  //
  // TODO: Make the beforeunload listener conditional on some application state so we can switch this on/off based on what we are doing.
  //
  /*
  const alertUser = (e: BeforeUnloadEvent) => {
    e.preventDefault()
    e.returnValue = ''
  };

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);

    return () => {
      window.removeEventListener('beforeunload', alertUser);
    }
  }, []);
  */

  //
  // This will first handle shortcuts locally to the editor if appropriate, then if not handled locally, forward the key event to our globaly keyboard shortcuts.
  //
  const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
    // event.ctrlKey: boolean
    // event.altKey
    // event.shiftKey: boolean
    // event.metaKey: boolean
    // event.code: string
    // event.repeat: boolean
    // event.key: string

    // if(event.key === 'enter') {
    //   alert('onKeyDown enter');
    // }

    if(event.key === '/' &&
      editor.selection !== null &&
      Range.isCollapsed(editor.selection) &&  // Only if the selection is collapsed (not selecting characters)
      editor.selection.anchor.offset === 0  // Only at the start of an element (newline = new paragraph)
    ) {
      event.preventDefault(); // Typing / may bring up search in some browsers like Firefox - we don't want that.

      const [node, path] = Editor.node(editor, editor.selection);
      if(!Editor.isEditor(node) && Editor.string(editor, path) === '') {
        /*
        const linkDOMNode = ReactEditor.toDOMNode(editor, node);
        const {
          x: nodeX,
          height: nodeHeight,
          y: nodeY,
        } = linkDOMNode.getBoundingClientRect();
        */

        // alert('Typed slash on a new line!');
        appController.dispatchMessage('prompter.local.contextmenu');

        return; // No further handling of this keypress is necessary.
      }
    }

    if (isHotkey('mod+b', event)) {
      event.preventDefault();
      event.stopPropagation();
      formatCommands.toggleBold();
      return;
    }
    if (isHotkey('mod+i', event)) {
      event.preventDefault();
      event.stopPropagation();
      formatCommands.toggleItalic();
      return;
    }
    if (isHotkey('mod+u', event)) {
      event.preventDefault();
      event.stopPropagation();
      formatCommands.toggleUnderline();
      return;
    }
    if (isHotkey('mod+shift+s', event)) {
      event.preventDefault();
      event.stopPropagation();
      appController.dispatchMessage('prompter.editor.voicetyping.start');
      return;
    }

    if (isHotkey((isMacOs || isIOS) ? 'Cmd+Shift+X' : 'Alt+Shift+5', event)) {  // "mod+shift+s"
      // Windows: Alt + Shift + 5
      // MacOS: ⌘ + Shift + x
      event.preventDefault();
      event.stopPropagation();
      formatCommands.toggleStrikethrough();
      return;
    }
    if (isHotkey('mod+shift+h', event)) {
      event.preventDefault();
      event.stopPropagation();
      formatCommands.toggleHighlight();
      return;
    }
  }, [editor, formatCommands]);

  const onPaste: React.ClipboardEventHandler<HTMLDivElement> = useCallback((e: React.ClipboardEvent<HTMLDivElement>) => {
    //const text = e.clipboardData.types;   // types: application/x-slate-fragment,text/html,text/plain
    //
    // console.log(`onPaste handler fired ${text}`, e.clipboardData);

    e.preventDefault();
    const clipboardText = e.clipboardData.getData('text/plain');
    Transforms.insertText(editor, clipboardText);
  }, [editor]);

  //
  // Method to manually change the prompter scroll position.
  //
  const performScroll = React.useCallback(function(documentScrollPosition: number, scrollBehavior: ScrollBehavior = 'auto') {
    const scrollEl = document.scrollingElement;
    if(!scrollEl) {
      console.log('ERROR: document.scrollingElement is null');
    }
    if(scrollEl) {
      const viewportHeight = scrollEl.clientHeight; // scrollEl.clientHeight
      const contentHeight = scrollEl.scrollHeight;  // scrollEl.scrollHeight
      const scrollPositionMax = contentHeight - viewportHeight;

      // const domScrollPosition = Math.round(props.flipVertical ? scrollPositionMax - documentScrollPosition : documentScrollPosition);
      const domScrollPosition = props.flipVertical ? scrollPositionMax - documentScrollPosition : documentScrollPosition;

      // console.log(`2.) PrompterSurface.performScroll(${props.flipVertical ? 'flipVertical=true' : ''}) documentScrollPosition = ${documentScrollPosition}, domScrollPosition = ${domScrollPosition}`);

      scrollEl.scroll({
        top: domScrollPosition,
        behavior: scrollBehavior
      });
    }
  }, [props.flipVertical]);

  /**
   * Handler for the teleprompter onScroll event, this is fired any time the prompter
   * is scrolled whether programatically or via user input.
   * @param {*} e
   */
  // useWhatChanged([props.onScroll, props.flipVertical, lineHeightRef]);
  /*
  const onScrollDomEventThrottled = React.useCallback(throttle(() => {

    // TODO: Because this is throttled, we need to caclulate our own scroll direction based on the
    // scroll position delta between throttled callback executions. It's possible we scroll a
    // little bit in both directions and we need to figure out the net change in scroll position.

    // console.log(`3.) PrompterSurface.onScrollDomEventThrottled(${props.flipVertical ? 'flipVertical=true' : ''}) documentScrollPosition = ${documentScrollPosition}, domScrollPosition = ${domScrollPosition}`);
    const prompterState = doRecalculateSegmentPositions();
    /-*
    if(prompterState && prompterState.scriptPosition) {
      const { scriptPosition } = prompterState;
      console.log(scriptPosition);

      const currentPosition = usePrompterSession.getState().getScrollPositionByScriptPosition(scriptPosition);
      console.log(`usePrompterSession.getScrollPositionByScriptPosition: ${currentPosition}`, scriptPosition);

      // Transmit change to other connected prompters.
      // appController.dispatchMessage('prompter.local.contextmenu');
    }
    *-/
  }, 1000/8), [props.onScroll, props.flipVertical, lineHeightRef, doRecalculateSegmentPositions]);
  */

  // const viewportOnScrolled = props.onScroll;
  const onPrompterScrolled = React.useCallback((e: Event /*e: React.UIEvent<HTMLDivElement, UIEvent>*/) => {
    const scrollEl = document.scrollingElement;
    if(!scrollEl) {
      return;
    }

    e.preventDefault();
    // e.stopPropagation();  // Scroll events don't bubble by default.

    const viewportHeight = scrollEl.clientHeight;
    const contentHeight = scrollEl.scrollHeight;
    const scrollPositionMax = contentHeight - viewportHeight;

    // Enforce minimum and maxim scroll positions - is this even necessary?
    const domScrollPosition = scrollEl.scrollTop;
    // if(domScrollPosition < 0) { domScrollPosition = 0; }
    // if(domScrollPosition > scrollPositionMax) { domScrollPosition = scrollPositionMax; }

    //
    // If we are flipping the prompter vertically, let's invert the scroll position.
    // This way components outside the PrompterSurface don't need to be aware of
    // whether or not we are flipped or not flipped.
    //
    const documentScrollPosition = props.flipVertical ? scrollPositionMax - domScrollPosition : domScrollPosition;

    // Determine which direction we are currently scrolling
    const scrollPositionDelta = documentScrollPosition - scrollTopRef.current;
    const scrollDirection = (scrollPositionDelta === 0) ? ScrollDirection.None : ((scrollPositionDelta > 0) ? ScrollDirection.Down : ScrollDirection.Up);
    // console.log(`onScroll ${scrollDirection}`);

    // Save our current scrollPosition, will be used if we re-render after toggling
    // flipVertical to preserve relative position in the script.
    scrollTopRef.current = documentScrollPosition;

    //
    // Throttle our expensive calculations, they don't need to be done 60 times per second.
    //
    // onScrollDomEventThrottled();
    doRecalculateSegmentPositions();  // Let's try completely unthrottled...

    // viewportOnScrolled({
    //   scrollDirection,
    //   scrollPosition: documentScrollPosition, // This should be the true scroll Position in the DOM (don't care if the teleprompter is vertically flipped right now or not.)
    //   contentHeight,
    //   viewportHeight,
    //   lineHeight: lineHeightRef.current,
    // });
  }, [props.flipVertical /*, viewportOnScrolled*/]);

  //
  // Attach scroll listener to `document.scrollingElement`
  //
  useEffect(() => {
    window.addEventListener('scroll', onPrompterScrolled);

    return () => {
      window.removeEventListener('scroll', onPrompterScrolled);
    };
  }, [onPrompterScrolled]);

  //
  // useImperativeHandle will define what is returned for a ref of this component.
  //
  useImperativeHandle(ref, () => ({
    prompterContentRef,
    getContainerDimensions: (): IPrompterSegmentsContainerDimensions => {
      const scrollerEl = document.scrollingElement;
      if(!scrollerEl) {
        throw new Error('document.scrollingElement is null or undefined');
      }

      // Get our maximum scroll position.
      const viewportHeight = scrollerEl.clientHeight;
      const contentHeight = scrollerEl.scrollHeight;
      const scrollPositionMax = contentHeight - viewportHeight;

      return {
        viewportHeight,
        contentHeight,
        scrollPositionMax,
      };
    },
    getViewportHeight: (): number => {
      if(!document.scrollingElement) {
        throw new Error('document.scrollingElement is null or undefined');
      }
      return document.scrollingElement.clientHeight;
    },
    getContentHeight: (): number => {
      if(!document.scrollingElement) {
        throw new Error('document.scrollingElement is null or undefined');
      }
      return document.scrollingElement.scrollHeight;
    },
    scroll: performScroll
  }), [performScroll]);

  //
  // Resize events can trigger a bunch of resize events (as many OSes animate a window maximize, restore or minimize trigger 20-30 resize events for the frame involved in the animation)
  //
  const handleResizeEvent = React.useCallback(debounce((/*e: ResizeObserverEntry*/) => {
    // console.log('PrompterContentContainer resized!');
    const scrollerElement = document.scrollingElement;
    const contentElement = prompterContentRef.current;
    if(!scrollerElement || !contentElement) {
      console.log('PrompterContentContainer resized: missing scrollerElement || contentElement');
      return;
    }

    // Get line height in px, we will use this later when  computing segment positions.
    // When computing segment positions, we really want to know whether the first line
    // of any segment is at the top of the screen, bottom of the screen or on the cue point.
    const computedStyle = window.getComputedStyle(contentElement);
    lineHeightRef.current = parseInt(computedStyle.lineHeight);

    // entry.contentRect.height; // Height in pixels
    // e.target.clientHeight
    props.onResize({
      contentHeight: scrollerElement.scrollHeight,
      viewportHeight: scrollerElement.clientHeight,
      lineHeight: lineHeightRef.current,
    });
  }, 50), [prompterContentRef, props.onResize]);

  /**
   * Whenever the prompter content is resized, we need to recalculate any viewport measurements we
   * need for our scroll function.
   */
  useResizeObserver(prompterContentRef, handleResizeEvent);

  useEffect(() => {
    if(editor.selection && props.readOnly) {
      Transforms.deselect(editor);
      window.getSelection()?.removeAllRanges();
    }
  }, [editor, props.readOnly]);


  //
  // Context Menu Stuff
  //
  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  /*
  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX + 2,
          mouseY: event.clientY - 6,
        }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
      // Other native context menus might behave different.
      // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
        null,
    );
  };
  */
  const handleClose = () => {
    setContextMenu(null);
  };
  const insertBrollElement = () => {
    setContextMenu(null);

    const paragraphNodeEntry = Editor.above(editor, {
      match: (n: Node) => {
        const node = n as PrompterElement;
        return node.type === 'paragraph';
      },
    });
    if (paragraphNodeEntry == null) {
      return;
    }

    const [, path] = paragraphNodeEntry;

    const proposedNode: BRoll = {
      type: ElementTypes.BROLL,
      description: '',
      children: [{ text: '' }]
    };

    Transforms.insertNodes<Descendant>(editor, proposedNode, {
      at: path,
    });
    Transforms.select(editor, path);
  };
  const insertPauseElement = () => {
    setContextMenu(null);

    const paragraphNodeEntry = Editor.above(editor, {
      match: (n: Node) => {
        const node = n as PrompterElement;
        return node.type === 'paragraph';
      },
    });
    if (paragraphNodeEntry == null) {
      return;
    }

    const [, path] = paragraphNodeEntry;

    const proposedNode: Pause = {
      type: ElementTypes.PAUSE,
      children: [{ text: '' }]
    };

    Transforms.insertNodes<Descendant>(editor, proposedNode, {
      at: path,
    });
    Transforms.select(editor, path);
  };
  /* TEMP TODO: Commented this out for a public release as its not ready for prime time.
  useMessageHandler('prompter.local.contextmenu', () => {
    console.log('HANDLE prompter.local.contextmenu');
    if(editor.selection !== null &&
      Range.isCollapsed(editor.selection)
    ) {
      const [node, path] = Editor.node(editor, editor.selection);
      if(!Editor.isEditor(node) && Editor.string(editor, path) === '') {
        const linkDOMNode = ReactEditor.toDOMNode(editor, node);
        const {
          x: nodeX,
          height: nodeHeight,
          y: nodeY,
        } = linkDOMNode.getBoundingClientRect();

        setContextMenu({
          mouseX: nodeX,
          mouseY: nodeY,
        });

        //alert('Typed slash on a new line!');
      }
    }
  });
  */

  useMessageHandler('prompter.editor.undo', () => {
    HistoryEditor.undo(editor);
  });

  useMessageHandler('prompter.editor.redo', () => {
    HistoryEditor.redo(editor);
  });

  //
  // Register logic that handles node state events.
  //
  useSegmentElementLogic();
  usePauseElementLogic();
  // useEndElementLogic();

  //
  // These props are passed to the renderer for the SlateEditor.
  // These should not change frequently to minimize rerenders of Slate.
  //
  const viewportProps: PrompterViewportProps = {
    textSize: props.textSize,
    textColor: props.textColor,
    contentWidth: props.contentWidth,
    leftGutter: props.leftGutter,
    rightGutter: props.rightGutter,
    flipHorizontal: props.flipHorizontal,
    flipVertical: props.flipVertical,
  };
  const renderProps: PrompterEditorRendererProps = {
    ...viewportProps,
    prompterContentRef,

    lineHeight: props.lineHeight,

    readOnly: props.readOnly,

    editor,
    editableKey,
    formatCommands,
    voiceTypingCommands,

    onChange: onEditorChange,
    onKeyDown,
    onPaste,
  };

  return (
    <>
      <PrompterEditorRenderer {...renderProps} />
      <WordLimitNotice {...viewportProps} />
      <EndElementOffScript {...viewportProps} />
      <ShotLogModal />
      {contextMenu !== null && <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <ListItemButton disabled>Content Type</ListItemButton>
        <MenuItem autoFocus onClick={insertBrollElement}>
          <ListItemIcon>
            <CameraRollIcon />
          </ListItemIcon>
          <ListItemText>B-Roll</ListItemText>
        </MenuItem>
        <MenuItem onClick={insertPauseElement}>
          <ListItemIcon>
            <PauseIcon />
          </ListItemIcon>
          <ListItemText>Pause</ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <FormatQuoteIcon />
          </ListItemIcon>
          <ListItemText>Quote</ListItemText>
        </MenuItem>
      </Menu>}
    </>
  );
});

export default PrompterEditorContainer;