import React, { useCallback, useState } from 'react';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Popover from '@mui/material/Popover';
import IconButton, { iconButtonClasses } from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import { CirclePicker, ColorResult } from 'react-color';

import PaletteIcon from '@mui/icons-material/Palette';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ClearIcon from '@mui/icons-material/Clear';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import isHotkey from 'is-hotkey';
import { styled } from '@mui/material/styles';

const HeaderTextField = styled(TextField)({
  flexGrow: 1,
  '& .MuiOutlinedInput-input': {
    margin: '0 0.5em',
    fontSize: '2rem',
    textTransform: 'uppercase',
    padding: 0,
  }
});

const StyledIconButton = styled(IconButton)({
  userSelect: 'none',
  transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  color: '#4E4E4E',  //'rgba(255, 255, 255, 0.25)',
  '&:hover': {
    color: '#fff',
  },
  /*"&.Mui-disabled": {
    pointerEvents: "unset", // allow :hover styles to be triggered
    cursor: "not-allowed", // and custom cursor can be defined without :hover state
    color: '#fff',
  },*/
  [`&.${iconButtonClasses.disabled}`]: {
    pointerEvents: 'unset', // allow :hover styles to be triggered
    cursor: 'not-allowed', // and custom cursor can be defined without :hover state
    color: 'rgba(222,222,222,0.8)',
  },
});

interface SegmentElementHeaderProps {
  number: number;
  title: string;
  color?: string;
  backgroundColor?: string;
  // isEditing?: boolean;
  readOnly?: boolean;
  onTitleChanged?: (newTitle: string) => void;
  onRequestDelete?: () => void;
}

function SegmentElementHeader(props: SegmentElementHeaderProps) {
  const { readOnly, onTitleChanged, onRequestDelete } = props;

  const [segmentColor, setSegmentColor] = React.useState<string | null>(props.color || '#131313');
  const [segmentBackgroundColor, setSegmentBackgroundColor] = React.useState<string | null>(props.backgroundColor || '#131313');

  const [colorPickerAnchorEl, setColorPickerAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleColorPickerClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setColorPickerAnchorEl(event.currentTarget);
  };
  const handleColorPickerClose = () => {
    setColorPickerAnchorEl(null);
  };
  const isColorPickerOpen = Boolean(colorPickerAnchorEl);
  //const colorPickerId = isColorPickerOpen ? `${props.segmentId}-color-popover` : undefined;

  const onColorChange = function(color: ColorResult) {
    setColorPickerAnchorEl(null);
    setSegmentColor(color.hex);
    // console.log('ColorResult', color);
  };
  const onClearColor = (e: React.MouseEvent<HTMLButtonElement>) => {
    setColorPickerAnchorEl(null);
    setSegmentColor('#131313');
  };



  const [isEditingTitle, setEditingTitle] = useState(false);
  const [title, setTitle] = useState(props.title);

  const onTitleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setTitle(event.target.value);
    },
    [setTitle]
  );

  const applyTitleChange = useCallback((captionInput: string) => {
    setTitle(captionInput);
    if(onTitleChanged) {
      onTitleChanged(captionInput);
    }
  }, [onTitleChanged]);

  const startTitleEdit = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if(readOnly || isEditingTitle) {
      return;
    }

    setEditingTitle(true);
  }, [readOnly, isEditingTitle]);

  const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!isHotkey('enter', e)) {
      return;
    }
    e.preventDefault();

    // User pressed enter!
    // Now let's stop stop editing the promptersegment title.
    const inputEl = e.target as HTMLInputElement;
    applyTitleChange(inputEl.value);
    setEditingTitle(false);
  }, [applyTitleChange, setEditingTitle]);

  const finishTitleEdit = useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
      if(!isEditingTitle) {
        return;
      }

      applyTitleChange(title);
      setEditingTitle(false);
    }, [isEditingTitle, applyTitleChange, title]
  );

  const handleDeleteRequest = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if(onRequestDelete) { onRequestDelete(); }
    }, [onRequestDelete]
  );

  return (
    <div
      className='SegmentElementHeader'
      contentEditable={false}
      style={{
        // backgroundColor: `${segmentBackgroundColor}`,
        color: (segmentColor !== '#131313') ? `${segmentColor}` : '#4e4e4e'
      }}
    >
      {props.number > 0 &&
      <span
        className='SegmentNumber'
        style={{
          /*backgroundColor: `${segmentColor}`*/
          color: (segmentColor !== '#131313') ? '#eee' : '#4e4e4e'
        }}
      >{props.number}</span>
      }
      {isEditingTitle ? (
        <HeaderTextField
          variant="outlined"
          autoFocus={true}
          defaultValue={title}
          onChange={onTitleChange}
          onBlur={finishTitleEdit}
          InputProps={{
            onKeyDown: onKeyDown,
          }}
        />
      ) : (
        <h1 onClick={startTitleEdit}>
          {props.title}
        </h1>
      )}

      <StyledIconButton
        className="HideWhenPrompting"
        size="large"
        edge="start"
        // color="inherit"
        aria-label="Segment Options"
        sx={{ mr: 1 }}
        onFocus={(e) => { e.target.blur(); }}
        onClick={handleColorPickerClick}
      >
        <PaletteIcon />
      </StyledIconButton>
      <Popover
        //id={colorPickerId}
        open={isColorPickerOpen}
        anchorEl={colorPickerAnchorEl}
        onClose={handleColorPickerClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            color: '#eee',
            padding: 2,
            backgroundColor: '#131313',
          },
        }}
      >
        <Typography sx={{ pb: 2 }}>Choose Segment Color</Typography>
        <CirclePicker
          width='264px'       // default 252px
          circleSize={28}     // default 28
          circleSpacing={16}  // default 14
          onChange={onColorChange}
          // If I want to customize the color pallette:
          // colors={["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b"]}
        />
        <Stack
          direction="row"
          justifyContent="center"
          sx={{
            mt: 2,
          }}
        >
          <Button
            variant="outlined"
            startIcon={<ClearIcon/>}
            onClick={onClearColor}
          >Clear Color</Button>
        </Stack>
      </Popover>
      {props.onRequestDelete && <StyledIconButton
        className="HideWhenPrompting"
        size="large"
        edge="start"
        // color="inherit"
        aria-label="Delete Segment"
        sx={{ mr: 1 }}
        onFocus={(e) => { e.target.blur(); }}
        onClick={handleDeleteRequest}
      >
        <DeleteForeverIcon />
      </StyledIconButton>}
    </div>
  );
}

export default SegmentElementHeader;