import { create } from 'zustand';

import createPrompterAttributesSlice, { IPrompterAttributesSlice } from './PrompterAttributesSlice';
import createPrompterModeSlice, { IPrompterModeSlice } from './PrompterModeSlice';
import createPrompterViewportSlice, { IPrompterViewportSlice } from './PrompterViewportSlice';
import createPrompterScriptSlice, { IPrompterScriptSlice } from './PrompterScriptSlice';
import createShotLoggingSlice, { IShotLoggingSlice } from './ShotLoggingSlice';
import createConnectedDevicesSlice, { IConnectedDevicesSlice } from './ConnectedDevicesSlice';
import createPrompterMediaSlice, { IPrompterMediaSlice, MediaSourceType } from './PrompterMediaSlice';

export {
  MediaSourceType,
};

// interface CalculateEstimatedTimeRemainingResults {
//   estimatedRemainingSeconds?: number,
// }
// const calculateEstimatedTimeRemaining = (state: PrompterSessionState, elapsedPlayMs: number): CalculateEstimatedTimeRemainingResults => {
//   const result = {};

//   // If we are just too early in the application life cycle we won't have the information we need to estimate remaining time.
//   if(!state.scriptNodesMeta || !state.scriptNodesState) {
//     return result;
//   }

//   const contentHeight = state.scriptNodesMeta?.contentHeight;
//   const viewportHeight = state.scriptNodesMeta?.viewportHeight;
//   const currentScrollPosition = state.scrollPosition;

//   const totalScrollRange = contentHeight - viewportHeight;
//   // const percentageComplete = currentScrollPosition / totalScrollRange;

//   const estimatedTotalMs = totalScrollRange * elapsedPlayMs / currentScrollPosition;
//   const estimatedRemainingMs = estimatedTotalMs - elapsedPlayMs;
//   const estimatedRemainingSeconds = Math.floor(estimatedRemainingMs / 1000);

//   //if((estimatedRemainingSeconds < state.estimatedRemainingSeconds) || (estimatedRemainingSeconds > state.estimatedRemainingSeconds + 2)) {
//   if(estimatedRemainingSeconds !== state.estimatedRemainingSeconds) {
//   // result['estimatedRemainingSeconds'] = estimatedRemainingSeconds;

//     // The number of estimated remaining seconds has changed! We don't want to return new values more frequently than the value actually changes to avoid unnecessary UI updates.
//     return {
//       estimatedRemainingSeconds
//     };
//   }

//   return {};
// };

export type PrompterSessionState = IPrompterAttributesSlice
  & IPrompterModeSlice
  & IPrompterViewportSlice
  & IPrompterScriptSlice
  & IShotLoggingSlice
  & IConnectedDevicesSlice
  & IPrompterMediaSlice;

const usePrompterSession = create<PrompterSessionState>()((...args) => ({
  ...createPrompterAttributesSlice(...args),
  ...createPrompterModeSlice(...args),
  ...createPrompterViewportSlice(...args),
  ...createPrompterScriptSlice(...args),

  // ShotLogging state maintins our shotlogEntries list and methods for initializing or finalizing
  // shotlog entries.
  ...createShotLoggingSlice(...args),

  // ConnectedDevices state contains list of all connected prompter instances, remotes, and other
  // peripherals.
  ...createConnectedDevicesSlice(...args),

  // Media slice will manage our video sharing/recording state including whether it is active,
  // what layout we are using, etc.
  ...createPrompterMediaSlice(...args),
}));

export default usePrompterSession;