import React, { useState, useCallback, createRef } from 'react';
import { ReactEditor, useSlateStatic, useFocused } from 'slate-react';
import { useConfirm } from 'material-ui-confirm';

import usePrompterSession from '../../../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import MailIcon from '@mui/icons-material/Mail';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import useResizeObserver from '@react-hook/resize-observer';
import { styled } from '@mui/material/styles';
import './SegmentSubscribe.scss';

const YouTubeButton = styled(Button)({
  width: '100%',
  color: '#fff',
  backgroundColor: '#ff0000',
  '&:hover': {
    backgroundColor: '#cc0000',
  },
  fontSize: '0.25em',
  zIndex: '1100',
});

const TwitterButton = styled(Button)({
  width: '100%',
  color: '#fff',
  backgroundColor: '#1da1f2',
  '&:hover': {
    backgroundColor: '#0b83cd',
  },
  fontSize: '0.25em',
  zIndex: '1100',
});

const EmailButton = styled(Button)({
  width: '100%',
  color: '#fff',
  backgroundColor: '#666',
  '&:hover': {
    backgroundColor: '#555',
  },
  fontSize: '0.25em',
  zIndex: '1100',
});

const SegmentSubscribe = React.memo(function SegmentSubscribe() {
  // const configStore = useConfigurationStore(state => ({
  //   contentJustification: state.contentJustification,
  // }), shallow);

  const prompterSession = usePrompterSession(state => ({
    viewportMeta: state.viewportMeta,
    scriptNodesMeta: state.scriptNodesMeta,
    isEditing: state.isEditing,
    shotloggingEnabled: state.shotloggingEnabled,
    setShotloggingEnabled: state.setShotloggingEnabled,
  }), shallow);

  const {
    scriptMarginEnd,
  } = prompterSession.viewportMeta;

  const confirm = useConfirm();

  // const editor = useSlateStatic();
  // const editorIsReadOnly = ReactEditor.isReadOnly(editor);
  // const focused = useFocused();    // Get the current focused state of the editor.

  const [checked, setChecked] = useState<boolean>(false);
  const requestDontShowAgain = useCallback(async (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {

    e.preventDefault();
    // (e.target as HTMLInputElement).checked = false;
    // alert('checked');
    setChecked(false);

    await confirm({
      title: 'You must be logged in to save user preferences.',
      description: 'Don\'t worry, the FREE account works!',
      confirmationText: 'Login',
      confirmationButtonProps: {
        variant: 'contained'
      },
      cancellationText: 'Cancel',
      cancellationButtonProps: {
        variant: 'outlined'
      },
    });

  }, [confirm]);

  // We need to keep track of the SegmentSubscribe height so that when we
  // position sticky the end element the script doesn't reflow (change height).
  const subscribeElementRef = createRef<HTMLDivElement>();
  const [subscribeElementHeight, setSubscribeElementHeight] = useState<number>(0);
  const handleContentResized = useCallback(async (e: ResizeObserverEntry) => {
    const proposedHeight = e.contentRect.height;
    if(proposedHeight !== subscribeElementHeight) {
      setSubscribeElementHeight(proposedHeight);
    }
  }, [subscribeElementHeight]);
  useResizeObserver(subscribeElementRef, handleContentResized);

  const centeringOffset = Math.min(scriptMarginEnd, (subscribeElementHeight / 2));
  const paddingBottomInPixels = scriptMarginEnd - centeringOffset;
  // console.log(`SegmentSubscribeContainer.paddingBottom = ${scriptMarginEnd} - ${centeringOffset} = ${paddingBottomInPixels}`);

  return (
    <div
      className='SegmentSubscribeContainer'
      style={{
        paddingBottom: paddingBottomInPixels
      }}
    >
      <div
        ref={subscribeElementRef}
        className="SegmentElement SegmentSubscribe"
      >
        <div
          className='SegmentElementHeader'
          style={{
            backgroundColor: '#ffff00',
            color: '#000',
          }}
        >
          <h1 style={{
            textAlign: 'center',
          }}
          >Subscribe to FluidPrompter!</h1>
        </div>
        <div
          className='SegmentElementBody'
          style={{
            // textAlign: configStore.contentJustification,
            borderColor: '#ffff00',
          }}
        >
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <EmailButton
                size="large"
                startIcon={<MailIcon style={{ fontSize: '3em' }}/>}
                onFocus={(e) => { e.target.blur(); }}
                onClick={() => {
                  window.open('https://www.fluidprompter.com/', '_blank');
                }}
              >
                Subscribe via Email
              </EmailButton>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <YouTubeButton
                size="large"
                startIcon={<YouTubeIcon style={{ fontSize: '3em' }}/>}
                onFocus={(e) => { e.target.blur(); }}
                onClick={() => {
                  // SUBSCRIBE LINK: https://www.youtube.com/@FluidPrompter?sub_confirmation=1
                  window.open('https://www.youtube.com/@FluidPrompter?sub_confirmation=1', '_blank');
                }}
              >/FluidPrompter on YouTube</YouTubeButton>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TwitterButton
                size="large"
                startIcon={<TwitterIcon style={{ fontSize: '3em' }}/>}
                onFocus={(e) => { e.target.blur(); }}
                onClick={() => {
                  // FOLLOW LINK: https://twitter.com/intent/follow?screen_name=FluidPrompter
                  // https://twitter.com/FluidPrompter
                  window.open('https://twitter.com/intent/follow?screen_name=FluidPrompter', '_blank');
                }}
              >@FluidPrompter on Twitter</TwitterButton>
            </Grid>
          </Grid>
          {/* https://www.instagram.com/fluidprompter/ */}
          {/* https://www.facebook.com/FluidPrompter/ */}
        </div>
        <div className="SegmentElementFooter">
          {/* <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={checked} onChange={requestDontShowAgain} />}
              label="Don't show this again on this device."
            />
          </FormGroup> */}
        </div>
      </div>
    </div>
  );
});

export default SegmentSubscribe;