import React from 'react';
import IconButton from '@mui/material/IconButton';

import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';

import classNames from 'classnames';

interface MediaPanelToolbarProps {
  hideToolbar: boolean;
  showContextMenuAtAnchor: (anchorElement: HTMLElement) => void;
}

function MediaPanelToolbar(props: MediaPanelToolbarProps) {
  const { hideToolbar, showContextMenuAtAnchor } = props;

  return (<div
    className={classNames('video-panel-toolbar', {
      hideToolbar,
    })}
  >
    <IconButton
      onFocus={(e) => { e.target.blur(); }}
      onClick={(e) => {
        showContextMenuAtAnchor(e.currentTarget);
      }}
    >
      <AutoAwesomeMosaicIcon />
    </IconButton>
  </div>);
}

export default MediaPanelToolbar;