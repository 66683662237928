var _a;
export class WeakRefFinalizationRegistry {
    /**
     * Create a new WeakRefFinalizationRegistry instance.
     * @param finalizationCallback The callback to be called when the target has been garbage collected
     */
    constructor(finalizationCallback) {
        this[_a] = 'FinalizationRegistry';
        this.REF_CHECKER_INTERVAL_MS = 500;
        this.finalizationCallback = finalizationCallback;
        this.unregisterTokenToTargetMap = new Map();
        this.startRefChecker();
    }
    /**
     * Syntax sugar to get the constructor of the class with TS
     * @returns The constructor of the class
     */
    static getConstructor() {
        return WeakRefFinalizationRegistry;
    }
    /**
     * Start the interval to check for garbage collected references
     */
    startRefChecker() {
        this.refCheckerInterval = setInterval(() => {
            for (const [unregisterTokenRef, { target, heldValue }] of this.unregisterTokenToTargetMap.entries()) {
                // Still valid ref, skip
                if (target.deref()) {
                    continue;
                }
                this.finalizationCallback(heldValue);
                this.unregisterTokenToTargetMap.delete(unregisterTokenRef);
            }
        }, this.REF_CHECKER_INTERVAL_MS);
        WeakRefFinalizationRegistry.refCheckerIntervals.push(this.refCheckerInterval);
    }
    /**
     * Stop the interval to check for garbage collected references
     */
    stopRefChecker() {
        if (!this.refCheckerInterval) {
            throw new Error('Ref checker interval is not running');
        }
        clearInterval(this.refCheckerInterval);
        this.refCheckerInterval = undefined;
    }
    /**
     * Register a target object to receive a callback when it has been garbage collected
     * @param target The target object to be monitored
     * @param heldValue The value to be passed to the finalization callback
     * @param unregisterToken The token to be used to unregister the finalization callback
     */
    register(target, heldValue, unregisterToken) {
        const unregisterTokenRef = new WeakRef(unregisterToken !== null && unregisterToken !== void 0 ? unregisterToken : target);
        const targetRef = new WeakRef(target);
        this.unregisterTokenToTargetMap.set(unregisterTokenRef, { target: targetRef, heldValue });
    }
    /**
     * Unregister a target object from receiving the finalization callback
     * @param unregisterToken The token to be used to unregister the finalization callback
     * @returns True if the target was successfully unregistered, otherwise false
     */
    unregister(unregisterToken) {
        for (const [unregisterTokenRef] of this.unregisterTokenToTargetMap.entries()) {
            if (unregisterTokenRef.deref() !== unregisterToken) {
                continue;
            }
            this.unregisterTokenToTargetMap.delete(unregisterTokenRef);
            return true;
        }
        return false;
    }
}
_a = Symbol.toStringTag;
// Testing only, allows to stop the RefChecker when not holding the reference to the instance
WeakRefFinalizationRegistry.refCheckerIntervals = [];
