/**
 * Convert objects containing classes to plain serializable objects
 * @param data The object to prepare for serialization
 * @returns The serializable object
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function prepareForSerialization(data) {
    // Do no convert arrays to objects but still prepare its content when they are object
    if (Array.isArray(data)) {
        return data.map((element) => {
            return prepareForSerialization(element);
        });
    }
    // Nothing to do when null or a primitive type
    if (!data || typeof data !== 'object') {
        return data;
    }
    const obj = {};
    const properties = Object.getOwnPropertyNames(data);
    for (const property of properties) {
        if (typeof data[property] === 'object') {
            obj[property] = prepareForSerialization(data[property]);
        }
        else {
            obj[property] = data[property];
        }
    }
    return obj;
}
