import { useCallback, useState } from 'react';
import DeviceHost from '../DeviceHost';
import { useMessageHandler } from '../../controllers/AppController';
import { BackgroundMessage } from '@fluidprompter/core';
import usePrompterSession, { MediaSourceType } from '../../state/PrompterSessionState';

interface DisplayMediaControllerExports {
  activeDisplayMedia: MediaStream | undefined,
  clearDisplayMedia: () => void;
}

function useDisplayMediaController(deviceHost: DeviceHost,): DisplayMediaControllerExports {
  //
  // If a local webcam is currently active, we will have a reference to it here.
  // We must clean-up any prior webcam usage prior to using a new webcam in the case of camera
  // switching.
  //
  const [activeDisplayMedia, setActiveDisplayMedia] = useState<MediaStream>();

  //
  //
  //
  const clearDisplayMedia = useCallback(() => {
    setActiveDisplayMedia(undefined);
  }, [setActiveDisplayMedia]);

  //
  //
  //
  const connectDesktop = useCallback(async () => {
    const mediaDevices = navigator.mediaDevices;

    const getDisplayMediaOptions: DisplayMediaStreamOptions = {
      selfBrowserSurface: 'exclude',  // Exclude the current tab from the list of tabs offered to the user.
      video: true,
      audio: false,
    };

    //
    // Only Chromium based browsers support a CaptureController to control the focus behaviour.
    //
    if (
      'CaptureController' in window &&
      'setFocusBehavior' in CaptureController.prototype
    ) {
      // CaptureController.setFocusBehavior() is supported.
      //
      // Create a new CaptureController instance
      const controller = new CaptureController();
      //
      // Do not move focus to the captured window.
      // Keep the capturing page (FluidPrompter) focused.
      controller.setFocusBehavior('no-focus-change');
      //
      // Associate this focus controller with our getDisplayMedia options.
      getDisplayMediaOptions.controller = controller;
    }

    //
    // Request a media capture stream from the browser. This will prompt the user for screen
    // sharing permissions and to choose a browser tab, window or screen to share.
    //
    const videoPromise = await mediaDevices.getDisplayMedia(getDisplayMediaOptions);

    //
    // Update our UI, if the above getDisplayMedia did not throw an error, then the user did not
    // reject the permission/sharing request.
    //
    const localPrompterState = usePrompterSession.getState();
    if(localPrompterState.mediaSourceType !== MediaSourceType.Desktop) {
      localPrompterState.setMediaSourceType(MediaSourceType.Desktop);
    }

    //
    // Let all our peer connections know that we have a video background to share.
    // PrompterPeer instances will begin sharing this media track via WebRTC.
    //
    deviceHost.appController.dispatchMessage(new BackgroundMessage(videoPromise, true));
  }, []);
  useMessageHandler('prompter.background.desktop', connectDesktop);

  //
  //
  //
  return {
    activeDisplayMedia,
    clearDisplayMedia,
  };
}

export default useDisplayMediaController;