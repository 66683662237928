import { ApplySyncAdjustmentMessage, BaseControlMessage, NavigateMessage, PrompterMode, SenderInfo } from '@fluidprompter/core';

import useConfigurationStore from '../state/ConfigurationStore';
import usePrompterSession from '../state/PrompterSessionState';

function calculateScrollPositionAdjustment(leaderInfo?: SenderInfo): BaseControlMessage | undefined {
  if(!leaderInfo) {
    // `senderState` is optional and not included in certain app messages - mostly messages that
    // do not affect the prompter position/state.
    return;
  }

  const {
    mode: leaderMode,
    scriptPosition: leaderScriptPosition,
    scrollSpeed: leaderScrollSpeed,
    scrollReversed: leaderScrollReversed,
  } = leaderInfo;
  if(!leaderScriptPosition) {
    // scriptPosition is optional and will be undefined if the message sender has not yet
    // rendered the DOM and measured the DOM elements on screen (first couple frames after
    // loading the page or loading a new script).
    return;
  }

  const {
    scrollSpeed: localScrollSpeed,
    scrollReversed: localScrollReversed,
  } = useConfigurationStore.getState();
  const {
    viewportMeta,
    scriptNodesMeta: localScriptNodesMeta,
    scrollPosition: localScrollPosition,
    getScrollPositionByScriptPosition,
  } =  usePrompterSession.getState();
  if(!localScriptNodesMeta) {
    // We can't calculate a scroll position if we've not yet rendered the UI/measured DOM nodes
    // indicated when `scriptNodesMeta` is undefined.
    return;
  }

  const remoteViewportPositionAsLocal = getScrollPositionByScriptPosition(leaderScriptPosition);
  if(!remoteViewportPositionAsLocal) {
    // We can't calculate a scroll position if we've not yet rendered the UI/measured DOM nodes.
    //
    // Note: the only reason `getScrollPositionByScriptPosition()` should return undefined is when
    // `scriptNodesMeta` is undefined which we already checked for above. So this case is more
    // about type safety and shouldn't normally happen.
    return;
  }

  const {
    nodePosition,
    scrollPosition: targetScrollTopAtCuePosition,
    heightRemaining: syncNodeHeightRemaining,
  } = remoteViewportPositionAsLocal;

  //
  // Calculate the scroll position the peer prompter is currently at and compare it to our actual
  // current scroll position. The difference between these +/- is our sync adjustment distance.
  //
  const targetScrollTop = targetScrollTopAtCuePosition - viewportMeta.cuePositionOffset;
  const trueSyncAdjustmentDistance = targetScrollTop - localScrollPosition;
  // Avoid over-compensating for error, we will reduce the adjustment distance to 80% of calculated.
  const syncAdjustmentDistance = trueSyncAdjustmentDistance * 0.8;
  const syncAdjustmentDistanceAbs = Math.abs(syncAdjustmentDistance);

  //
  // Scale the sync adjustment time as a shorter time for smaller sync delta and longer
  // adjustment time for big adjustments.
  //
  let syncAdjustmentTime = Math.min(
    1000,                // We really never want to take longer than 1/2 second to apply a sync adjustment.
    // syncAdjustmentDistanceAbs / localScriptNodesMeta.lineHeight * 1000  // Really small sync adjustments of 1-20 pixels can also be completed in a really short period of time.
  );

  //
  // If we are not currently playing, we are ok to "jump" to the current position, no need to
  // finesse this.
  //
  if(leaderMode !== PrompterMode.Playing) {
    //
    // If the sync adjustment distance is really small and we're not currently playing, then we
    // don't need to apply the adjustment.
    //
    if(syncAdjustmentDistanceAbs < Math.round(localScriptNodesMeta.lineHeight * 0.9)) {
      // This sync adjustment is too small to be relevant while editing.
      return;
    }

    return new NavigateMessage(NavigateMessage.Target.Position, {
      scrollPosition: targetScrollTop,
      scrollBehavior: 'smooth',
    });
  }

  //
  // If the sync adjustment distance is really small, its not likely to be very accurate either
  // depending on network latency. Therefor we won't apply very small sync adjustment distances.
  //
  const smallSyncThreshold = Math.round(localScriptNodesMeta.lineHeight * 0.1);
  if(syncAdjustmentDistanceAbs < smallSyncThreshold) {
    // This sync adjustment is too small to be relevant. Network jitter can cause slight +/- based
    // on changing latency from leader over time. To avoid appearing too jittery in animation, we
    // will only compensate for materially significant differences in scroll position.
    // console.log(`calculateScrollPositionAdjustment(): skipped applying small sync adjustment: ${syncAdjustmentDistance}px < ${smallSyncThreshold}px (5% LH)`);
    return;
  }

  //
  // If this is a rather large change in position, we are better off "navigating" to the correct
  // target than applying a sync adjustment.
  //
  // Future sync adjustments will be ignored until the navigation animation is completed. This
  // prevents interference from sync adjustments that fire when the navigation animation is passing
  // over script node bounaries generating NodeChanged events during the navigation operation.
  //
  const largeSyncThreshold = Math.round(localScriptNodesMeta.lineHeight * 2);
  if(syncAdjustmentDistanceAbs > largeSyncThreshold) {
    return new NavigateMessage(NavigateMessage.Target.Position, {
      scrollPosition: targetScrollTop,
      scrollBehavior: 'smooth',
    });
  }

  //
  // How far would the prompter scroll naturally in the same period as our proposed sync
  // adjustment time window?
  //
  // Note: when we are scrolling in reverse, it will be 1.5x the current forward scrolling speed.
  // I assume the user is reversing to restart something and not to read during the reverse. So it
  // can reverse a little faster than scrolling forwards.
  //
  const naturalScrollDistanceDuringSyncTime = (localScrollSpeed || 0) * (localScrollReversed ? -1.5: 1) * syncAdjustmentTime / 1000;
  const naturalScrollDistanceIsNegative = naturalScrollDistanceDuringSyncTime < 0;

  //
  // What would be the combined effect of the current scroll speed, plus the proposed sync
  // adjustment distance? We will use this to detect unintentional reverse movement.
  //
  const syncDelta = naturalScrollDistanceDuringSyncTime + syncAdjustmentDistance;
  const syncDeltaIsNegative = syncDelta < 0;

  //
  // We want to smooth out reverse movement that would appear like jitter in the animation,
  // without preventing intentional reverse navigation where two prompters are wildly out of
  // sync.
  //
  // Never apply a sync adjustment that would result in a slight jitter of reverse scrolling.
  // If the adjustment is significant enough, we will allow the reverse scrolling.
  // If the adjustment is minor, cap the adjustment at slightly less than the current scroll
  // speed.
  //
  // Does the proposed sync adjustment result in reversing the scroll direction?
  if(naturalScrollDistanceIsNegative !== syncDeltaIsNegative) {
    //
    // This is a relatively small reverse movement (less than `largeSyncThreshold` defined above).
    // Let's just stretch out the syncAdjustmentTime so that the adjustment doesn't result in
    // reverse movement.
    //
    // Ex: if adjusting -123px in 1000ms would result in reverse movement, perhaps adjusting -123px
    // in 1500ms would not result in reverse movement because it is spread out over more time of
    // the forward scrolling of the prompter animation.
    //
    syncAdjustmentTime = Math.round(syncAdjustmentDistanceAbs * 1000 / naturalScrollDistanceDuringSyncTime);
    // console.warn(`calculateScrollPositionAdjustment(): applying ${syncAdjustmentDistance}px over ${syncAdjustmentTime}ms instead, to avoid reverse movement`);
  } // END IF(sync would result in reverse movement)

  //
  // Now we know how much of a sync correction we want to apply!
  //
  //   const consoleMsg = `calculateScrollPositionAdjustment():
  // sync adjustment: ${syncAdjustmentDistance}px in ${syncAdjustmentTime}ms
  // for node [${leaderScriptPosition.nodePath}]
  // leader ${leaderScriptPosition.position}px / ${leaderScriptPosition.nodeHeight}px
  //   = local ${remoteViewportPositionAsLocal.nodePosition}px / ${remoteViewportPositionAsLocal.nodeHeight}px`;

  // console.warn(`calculateScrollPositionAdjustment(): sync adjustment: ${syncAdjustmentDistance}px in ${syncAdjustmentTime}ms for node [${leaderScriptPosition.nodePath}]`);
  // console.warn(consoleMsg);
  return new ApplySyncAdjustmentMessage(
    syncAdjustmentDistance,
    syncAdjustmentTime,
  );
}

export default calculateScrollPositionAdjustment;