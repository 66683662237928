/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * © Copyright 2023 fluidprompter.com
 */
export var BluetoothProvider;
(function (BluetoothProvider) {
    BluetoothProvider["WEB"] = "WEB";
    BluetoothProvider["IPC"] = "IPC";
})(BluetoothProvider = BluetoothProvider || (BluetoothProvider = {}));
export var BluetoothDeviceState;
(function (BluetoothDeviceState) {
    BluetoothDeviceState["Unknown"] = "Unknown";
    BluetoothDeviceState["Resetting"] = "Resetting";
    BluetoothDeviceState["Unsupported"] = "Unsupported";
    BluetoothDeviceState["Unauthorized"] = "Unauthorized";
    BluetoothDeviceState["PoweredOff"] = "PoweredOff";
    BluetoothDeviceState["PoweredOn"] = "PoweredOn";
})(BluetoothDeviceState = BluetoothDeviceState || (BluetoothDeviceState = {}));
export var BluetoothStatus;
(function (BluetoothStatus) {
    BluetoothStatus["Success"] = "success";
    BluetoothStatus["Failed"] = "failed";
})(BluetoothStatus = BluetoothStatus || (BluetoothStatus = {}));
export var BluetoothEventType;
(function (BluetoothEventType) {
    BluetoothEventType["ServerDisconnectedEvent"] = "serverdisconnected";
    BluetoothEventType["AvailabilityChangedEvent"] = "availabilitychanged";
})(BluetoothEventType = BluetoothEventType || (BluetoothEventType = {}));
export var BluetoothState;
(function (BluetoothState) {
    BluetoothState["Unknown"] = "Unknown";
    BluetoothState["Resetting"] = "Resetting";
    BluetoothState["Unsupported"] = "Unsupported";
    BluetoothState["Unauthorized"] = "Unauthorized";
    BluetoothState["PoweredOff"] = "PoweredOff";
    BluetoothState["PoweredOn"] = "PoweredOn";
})(BluetoothState = BluetoothState || (BluetoothState = {}));
