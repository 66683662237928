import React, { useState, useCallback, createRef } from 'react';
import { RenderElementProps } from 'slate-react';

import useResizeObserver from '@react-hook/resize-observer';
import usePrompterSession from '../../../state/PrompterSessionState';

import './EndElement.scss';

function EndElement(props: RenderElementProps) {
  const { attributes, children } = props;

  const viewportMeta = usePrompterSession(state => state.viewportMeta);
  const {
    scriptMarginEnd,
  } = viewportMeta;

  // We need to keep track of our endElementContainer width so that when we
  // position sticky the end element it doesn't change size.
  const [containerWidth, setContainerWidth] = useState<number>();
  const handleContainerResized = useCallback(async (e: ResizeObserverEntry) => {
    const proposedContainerWidth = e.contentRect.width;
    if(proposedContainerWidth !== containerWidth) {
      setContainerWidth(proposedContainerWidth);
    }
  }, [containerWidth]);
  useResizeObserver(attributes.ref, handleContainerResized);

  // We need to keep track of the endElementContent height so that when we
  // position sticky the end element the script doesn't reflow (change height).
  const endElementRef = createRef<HTMLDivElement>();
  const [endElementHeight, setEndElementHeight] = useState<number>();
  const handleContentResized = useCallback(async (e: ResizeObserverEntry) => {
    const proposedHeight = e.contentRect.height;
    if(proposedHeight !== endElementHeight) {
      setEndElementHeight(proposedHeight);
    }
  }, [endElementHeight]);
  useResizeObserver(endElementRef, handleContentResized);

  const isLastPrompterElement = false;

  return (
    <div
      className={'EndElementContainer'}
      contentEditable={false}
      {...attributes}
      style={{
        minHeight: '50vh',  // This element is now just an empty space where the EndElement will be positioned off script.
        //
        // If "Subscribe to FluidPrompter!" is hidden (End Element is the LAST element in the
        // prompter) then use our calculated `vh` based marginBottom to center the EndElement
        // in the viewport.
        //
        // If there are more elements after EndElement (like the SegmentSubscribe element)
        // then we will use a static marginBottom.
        //
        paddingBottom: isLastPrompterElement ? scriptMarginEnd : '2em'
      }}
    >
      {/* children is necessary for Slate DOM tracking, it will be an empty string */}
      {children}
    </div>
  );
}

export default EndElement;