import promiseRetry from 'promise-retry';
import { v4 as uuidv4 } from 'uuid';

import DeviceHost from './DeviceHost';
import BaseDevice from './BaseDevice';
import { DeviceConnectingEvent } from './events/DeviceConnectingEvent';
import { DeviceConnectedEvent } from './events/DeviceConnectedEvent';
import { DeviceDisconnectedEvent } from './events/DeviceDisconnectedEvent';
import { DeviceRemovedEvent } from './events/DeviceRemovedEvent';
import { DeviceReportEvent } from './events/DeviceReportEvent';
import { ConnectionState } from '@fluidprompter/core';
import {
  BluetoothDevice,
  IBluetoothProvider,
  BluetoothStatus,
  BluetoothCharacteristic,
  BluetoothEventType,
} from '@fluidprompter/ipc-interfaces';


export type StaticThis<T> = { new (): T };

export interface BluetoothRemoteConstructor {
  // device: globalThis.BluetoothDevice, server: BluetoothRemoteGATTServer
  new (deviceHost: DeviceHost): BaseRemote;
}

const BATTERY_SERVICE = '0000180f-0000-1000-8000-00805f9b34fb';         // 0x180F or 0000180f-0000-1000-8000-00805f9b34fb Battery Service
const BATTERY_CHARACTERISTIC = '00002a19-0000-1000-8000-00805f9b34fb';  // 0x2A19 or 00002a19-0000-1000-8000-00805f9b34fb Battery Characteristic

abstract class BaseRemote extends BaseDevice {

  static PRIMARY_SERVICE_UUID = '';

  _bleDevice?: BluetoothDevice | undefined;
  _bleDeviceNotifyTopic: string | undefined;

  reconnectInterval: number | null;

  constructor(deviceHost: DeviceHost) {
    super(deviceHost);

    this._bleDevice = undefined,
    this._bleDeviceNotifyTopic = undefined;

    this.reconnectInterval = null;
  }

  /**
   * Utility function to convert a serializable number array representing a series of uint8s to a DataView
   * @param numArr The number array to convert to a DataView
   * @returns The corresponding DataView
   */
  protected numArrToDataView(numArr: number[]): DataView {
    return new DataView(Uint8Array.from(numArr).buffer);
  }

  abstract getRequestDeviceOptions(): RequestDeviceOptions;


  async connect() {
    const bluetoothState = await this.deviceHost.BluetoothProvider.getState();
    if (bluetoothState !== 'PoweredOn') {
      throw new Error('Bluetooth module is powered off or not supported');
    }

    console.log(`Bluetooth module state: ${bluetoothState}`);
    // Get UUIDs of a remote to for scanning
    const deviceUUIDs = this.getRequestDeviceOptions();

    try {
      // This is a new connection flow
      if(this._connectionState !== ConnectionState.Reconnecting) {
        this._connectionState = ConnectionState.Connecting;

        console.log('New connection');
        const bleDevice: BluetoothDevice = await new Promise((res, rej) => {
          let deviceFound = false;
          this.deviceHost.BluetoothProvider.startScanning(deviceUUIDs, (device, error) => {
            // We are only interested in the first device found, ignore any other event that might follow
            if (deviceFound) {
              return;
            }

            if (error) {
              rej(error);
            }

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            res(device!);
            this.deviceHost.BluetoothProvider.stopScanning();
            deviceFound = true; // Flag to not resolve the promise more than once
          }).catch(err => rej(err)); 
        });
  
        this._bleDevice = bleDevice;
  
        // Register event listener - will be cleaned up in disconnect.
        this.deviceHost.BluetoothProvider.addEventListener(
          this._bleDevice.id,
          {
            eventType: BluetoothEventType.ServerDisconnectedEvent,
            handler: this.onDisconnected.bind(this)
          });
 
        this._connectionState = ConnectionState.Connecting;
        this.emit('connecting', new DeviceConnectingEvent(this));
  
        const resp_connect = await this.deviceHost.BluetoothProvider.connect(this._bleDevice.id);
  
        if (!resp_connect) {
          throw new Error('Connect remote failed');
        }
  
        const resp_stop_scan = await this.deviceHost.BluetoothProvider.stopScanning();
        if (resp_stop_scan === 'failed'){
          console.log('Stop scanning failed');
        }
      } else {
        // This is a reconnection flow
        console.log('Reconnecting');
        if (!this._bleDevice) {
          throw new Error('Can\'t reconnect. Device does\'t exist');
        }
        const resp_connect = await this.deviceHost.BluetoothProvider.connect(this._bleDevice.id);
  
        if (!resp_connect) {
          throw new Error('Connect remote failed');
        }
      }

      // Fire our handler for when a device connects.
      await this.onConnect(this._bleDevice);

    } catch (error) {
      console.log('Error trying to connect to BLE device.', error);
      if(this._connectionState !== ConnectionState.Reconnecting) {
        this._connectionState = ConnectionState.Disconnected;
      }
      throw( error );
    }
  }

  /**
   * Will retry a connection attempt to a bluetooth device 5 times with a backoff interval.
   */
  async promiseConnection() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const instance = this;

    promiseRetry((retry, number) => {
      console.log('promiseConnection() attempt number', number);

      return instance.connect().catch(retry);
    }, {
      retries: 4, // (4 retries + 1 initial attempt = 5 total attempts)
      minTimeout: 100,
      maxTimeout: 3000,
    }).then(function (value) {
      // ..
      console.log('promiseConnection().then');
    }, function (err) {
      // ..
      console.log('promiseConnection().catch');

      // When the device is disconnected, it is possible it was disconnected while
      // a button was actively being pressed. The button will then be stuck in the
      // pressed state and the repeat press timer may continue to fire indefinitely.
      const buffer = new ArrayBuffer(2);
      const view = new DataView(buffer);
      //instance.onNotifyDigital(view);

      // Fire event
      instance._connectionState = ConnectionState.Disconnected;
      instance.emit('disconnected', new DeviceDisconnectedEvent(instance, true)); // TODO: We are claiming this is a user initiated disconnect so that it removes the device entry in the connected devices list.

    });
  }

  /**
   * Fired when a device transitions from disconnected to connected.
   */
  async onConnect(device: BluetoothDevice) {
    this._disconnectRequested = false; // Reset flag.

    await this.deviceHost.BluetoothProvider.discoverServices(device.id);

    this.attachServicesAndCharacteristics(device, this.deviceHost.BluetoothProvider, uuidv4());

    this._connectionState = ConnectionState.Connected;
    this.emit('connected', new DeviceConnectedEvent(this));
  }


  attachServicesAndCharacteristics(device: BluetoothDevice, provider: IBluetoothProvider, subscriptionTopic: string): void {
    this._bleDeviceNotifyTopic = subscriptionTopic;
    console.log('base remote attachNotifyServicesAndCharacteristics is called');
  }

  async disconnect() {
    // If the disconnect method was called on this device instance, we will disconnect
    this._disconnectRequested = true;

    if (this._bleDevice) {
      // IPC ble devices
      await this.deviceHost.BluetoothProvider.disconnect(this._bleDevice.id);

      this.deviceHost.BluetoothProvider.removeEventListener(
        this._bleDevice.id,
        {
          eventType: BluetoothEventType.ServerDisconnectedEvent,
          handler: this.onDisconnected.bind(this)
        });
    }

    this._connectionState = ConnectionState.Disconnected;

    if (this._bleDeviceNotifyTopic){
      this._bleDeviceNotifyTopic = undefined;
    }
  }

  /**
   * Fired when a device transition from connected to disconnected.
   */
  async onDisconnected() {
    console.log('Received onDisconnected event');
    // Should we try to reconnect?
    if (this._disconnectRequested) {
      console.log('Disconnected due to user request');
      // Disconnected due to user request.
      //
      // We only show the notification if this device wasn't already disconnected
      // this prevents duplicate notifications if there was multiple disconnect
      // requests fired (due to React 18 strict mode).
      if (this._connectionState !== ConnectionState.Disconnected) {
        this._connectionState = ConnectionState.Disconnected;
        this.emit('disconnected', new DeviceDisconnectedEvent(this, true));
      }

      this.emit('removed', new DeviceRemovedEvent(this));
    } else {
      console.log('Trying to reconnect a remote back');
      if (this._connectionState !== ConnectionState.Reconnecting) {
        this._connectionState = ConnectionState.Reconnecting;

        // Fire event
        this.emit('disconnected', new DeviceDisconnectedEvent(this, false));

        this.promiseConnection();
      }
    }
  }

  isConnected() {
    return (this._connectionState === ConnectionState.Connected);
  }

  onNotifyBatteryLevel(notifyData: DataView) {
    // Battery level has changed - this will be a number from 0 to 100 that represents the battery
    // charge as a percentage of full capacity.
    this.batteryPercentage = notifyData.getUint8(0);
    this.emit('devicereport', new DeviceReportEvent(this));
  }

  async subscribeToBatteryStatus(device: BluetoothDevice) {
    try {
      const initialValue = await this.deviceHost.BluetoothProvider.read(
        device.id,
        BATTERY_SERVICE,
        BATTERY_CHARACTERISTIC);

      if (!initialValue) {
        console.log('Battery status not found');
        return;
      }

      this.onNotifyBatteryLevel(this.numArrToDataView(initialValue));

      await this.deviceHost.BluetoothProvider.notify(device.id,
        BATTERY_SERVICE,
        BATTERY_CHARACTERISTIC,
        (status: BluetoothStatus, char?: BluetoothCharacteristic, error?: number) => {
          if (status === BluetoothStatus.Success && char?.value) {
            this.onNotifyBatteryLevel(this.numArrToDataView(char.value));
  
          } else {
            console.log(`Received error on button notification. Error code is ${error}`);
          }
        },
        ''
      );

    } catch (err) {
      console.log('Error retrieving bluetooth device battery status.', err);
    }
  }

  static create<TRemote extends BaseRemote>(this: StaticThis<TRemote>) {
    const that = new this();
    return that;
  }
}

export default BaseRemote;