import { useCallback, useEffect, useState } from 'react';
import DeviceHost from '../DeviceHost';
import PrompterPeerInstance from '../prompterpeer/PrompterPeerInstance';
import { DeviceConnectionType } from '../BaseDevice';

import useConfigurationStore, { MediaScalingMode } from '../../state/ConfigurationStore';

import { MessageHandlerEvent, useAppController, useMessageHandler } from '../../controllers/AppController';
import useUserMediaController from './useUserMediaController';
import useDisplayMediaController from './useDisplayMediaController';
import usePrompterSession, { MediaSourceType } from '../../state/PrompterSessionState';
import { GenericMessage } from '@fluidprompter/core';

function useRTCBackgroundHandlers(deviceHost: DeviceHost) {
  const appController = useAppController();

  //
  // This hook handles user media (aka "webcam")
  //
  const {
    activeUserMedia,
    clearUserMedia,
  } = useUserMediaController(deviceHost);

  //
  // This hook handles desktop media (aka "screen share")
  //
  const {
    activeDisplayMedia,
    clearDisplayMedia,
  } = useDisplayMediaController(deviceHost);

  //
  useEffect(() => {
    if(!activeUserMedia) {
      return;
    }

    // If we are transitioning from no-usermedia to a new user media source, then we should
    // clear out any prior display media.
    clearDisplayMedia();
  }, [activeUserMedia, clearDisplayMedia]);

  //
  useEffect(() => {
    if(!activeDisplayMedia) {
      return;
    }

    // If we are transitioning from no-displaymedia to a new display media source, then we should
    // clear out any prior user media.
    clearUserMedia();
  }, [activeDisplayMedia, clearUserMedia]);

  //
  //
  //
  const sendMediaStreamToPeers = useCallback((proposedLocalMediaStream?: MediaStream) => {
    const allPeerDevices = deviceHost
      .getDevicesByType<PrompterPeerInstance>(PrompterPeerInstance.DEVICE_TYPE);
    console.log(`useRTCBackgroundSetHandler(): found ${allPeerDevices.length} peer devices including myself`);

    allPeerDevices.forEach((device) => {
      device.setLocalMediaStreamSourceAndSend(proposedLocalMediaStream);
    });
  }, [deviceHost]);

  //
  //
  //
  const currentMediaStream = activeUserMedia || activeDisplayMedia;
  const clearCurrentMedia = useCallback((e: MessageHandlerEvent<GenericMessage>) => {
    console.log('useRTCBackgroundSetHandler(): Received \'prompter.background.clear\' message', e);

    //
    // Clear out local controllers' references to any current media streams.
    //
    clearUserMedia();
    clearDisplayMedia();

    //
    // Clear out all PeerConnection's references to any current media streams.
    //
    sendMediaStreamToPeers(undefined);

    //
    // Update our app state to be reflected in the UI.
    //
    const localPrompterState = usePrompterSession.getState();
    if(localPrompterState.mediaSourceType !== MediaSourceType.None) {
      localPrompterState.setMediaSourceType(MediaSourceType.None);
    }
  }, []);
  useMessageHandler('prompter.background.clear', clearCurrentMedia);

  //
  // We want to know when the local media source has been ended.
  //
  useEffect(() => {
    if(!currentMediaStream) {
      return;
    }

    //
    // Handler when a local media source
    const onTrackEnded = (e: Event) => {
      console.warn('localMediaStreamSource ENDED');
      appController.dispatchMessage('prompter.background.clear');
    };

    const localMediaStreamTracks = currentMediaStream.getTracks();

    // Attach event listeners to the current backgroundMediaStream in case the browser terminates
    // the local source by revoking permissions or clicking stop in browser UI.
    localMediaStreamTracks.forEach(localMediaStreamTrack => {
      localMediaStreamTrack.addEventListener('ended', onTrackEnded);
    });

    return () => {
      //
      // clean-up previous backgroundMediaStream
      localMediaStreamTracks.forEach(mediaStreamTrack => {
        //
        // remove all eventListeners
        mediaStreamTrack.removeEventListener('ended', onTrackEnded);
        //
        // stop tracks (releases the webcam or other resources - turns of webcam light)
        mediaStreamTrack.stop();
      });
    };
  }, [currentMediaStream, appController]);

  //
  // This hook handles background set app message.
  //
  useMessageHandler('background', async (e) => {
    console.log('useRTCBackgroundSetHandler(): Received \'background\' message', e);

    //
    // We only want to transmit background streams to peers if they are originating from this
    // local instance.
    //
    // If we receive a remote background media stream, let's clear our any local media source by
    // setting it to undefined.
    //
    const proposedLocalMediaStream = e.message.isLocalSource ? e.message.mediaStream : undefined;
    sendMediaStreamToPeers(proposedLocalMediaStream);

    //
    // If we are receiving a remote background track via webrtc, this will adjust our user
    // interface to disable options we can't use with a remote source.
    //
    if(!e.message.isLocalSource) {
      //
      // Clear out local controllers' references to any current media streams.
      clearUserMedia();
      clearDisplayMedia();

      //
      // Update our Menu UI to show a remote video source.
      const localPrompterState = usePrompterSession.getState();
      localPrompterState.setMediaSourceType(MediaSourceType.Remote);
    }
  });

  /**
   * set the mute state for current media track
   */
  const setBackgroundMute = useCallback(async (muted?: boolean) => {
    const localDeviceInstance = deviceHost
      .allDevices<PrompterPeerInstance>(DeviceConnectionType.Network)
      .find((device) => device.representsLocal);

    if(!localDeviceInstance) {
      throw new Error('Missing local peer instance.');
    }

    return localDeviceInstance.muteBackgroundTrack(muted);
  }, []);

  useMessageHandler('prompter.background.togglemute', async (e) => {
    const newMuteState = await setBackgroundMute();

    // Transmit 'prompter.background.setmute' to peers based on local mute state after toggle.
    if(newMuteState) {
      e.dispatchMessage('prompter.background.mute');
    } else {
      e.dispatchMessage('prompter.background.unmute');
    }
  });

  useMessageHandler('prompter.background.mute', async (e) => {
    e.sendToPeers = !e.originatedRemotely;

    await setBackgroundMute(true);
  });

  useMessageHandler('prompter.background.unmute', async (e) => {
    e.sendToPeers = !e.originatedRemotely;

    await setBackgroundMute(false);
  });

  useMessageHandler('prompter.background.contain', async (e) => {
    const { media1ScalingMode, setMedia1ScalingMode } = useConfigurationStore.getState();
    if(media1ScalingMode !== MediaScalingMode.Contain) {
      setMedia1ScalingMode(MediaScalingMode.Contain);
    }
  });

  useMessageHandler('prompter.background.cover', async (e) => {
    const { media1ScalingMode, setMedia1ScalingMode } = useConfigurationStore.getState();
    if(media1ScalingMode !== MediaScalingMode.Cover) {
      setMedia1ScalingMode(MediaScalingMode.Cover);
    }
  });

  //
  return currentMediaStream;
}

export default useRTCBackgroundHandlers;