import React, { useCallback, useEffect } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import debounce from 'lodash/debounce';

import { useAppController } from '../../../controllers/AppController';
import usePrompterSession from '../../../state/PrompterSessionState';

const StartElementSpacer = () => {

  const spacerElementRef = React.createRef<HTMLDivElement>();

  const appController = useAppController();

  const { scriptMarginStart } = usePrompterSession(state => state.viewportMeta);

  const handleResizeEvent = React.useCallback(debounce((e: ResizeObserverEntry) => {
    //
    // Request our prompter elements to be re-measured. The receiving end of the message will
    // debounce duplicate requests for measuring DOM nodes.
    //
    appController.dispatchMessage('measuredom');
  }, 50), []);
  useResizeObserver(spacerElementRef, handleResizeEvent);

  return (
    <div
      ref={spacerElementRef}
      className="StartElementSpacer"
      style={{
        height: scriptMarginStart,
      }}
    ></div>
  );
};

export default React.memo(StartElementSpacer);