/**
 * See: https://stackoverflow.com/a/53601942/1002041
 * @param callBack 
 */
export const domReady = (callBack: () => void) => {
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', callBack);
  }
  else {
    callBack();
  }
};

/**
 * See: https://stackoverflow.com/a/53601942/1002041
 * @param callBack 
 */
export const windowReady = (callBack: () => void) => {
  if (document.readyState === 'complete') {
    callBack();
  }
  else {
    window.addEventListener('load', callBack);
  }
};

/**
 * Detects if the current environment is a React Native WebView.
 */
export function isReactNativeWebView(): boolean {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (window as any).ReactNativeWebView ? true : false;
}
