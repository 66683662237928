var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IPCError } from './IPCError.js';
import { IPC_FUNCTION_TAG_PROPERTY, IPC_FUNCTION_TAG_VALUE, } from './interfaces.js';
export class IPCServer {
    /**
     * Create a new IPCServer instance.
     * @param name The name of the server used to identify the source of messages by the audience
     * @param messageChannel The message channel used to send and receive messages
     * @param implementation The implementation of the shared contract
     * @param FinalizationRegistryImpl The FinalizationRegistry implementation to use
     */
    constructor(name, messageChannel, implementation, FinalizationRegistryImpl = FinalizationRegistry) {
        this.name = name;
        this.implementation = implementation;
        this.send = messageChannel.send.bind(messageChannel);
        this.requestIdToCallbackResultPromiseMap = new Map();
        this.finalizationRegistry = new FinalizationRegistryImpl(this.finalizationCallback.bind(this));
        this.callbackIdToCallbackMap = new Map();
        messageChannel.addOnMessageListener(this.name, this.messageHandler.bind(this));
    }
    /**
     * Handle incoming messages from the IPC client.
     * @param data The incoming message
     */
    messageHandler(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const msg = data;
            if (msg.destination !== this.name)
                return;
            switch (msg.type) {
                case "Request" /* MessageType.Request */:
                    this.handleRequest(msg);
                    break;
                case "CallbackResponse" /* MessageType.CallbackResponse */:
                    this.handleCallbackResponse(msg);
                    break;
                default:
                    throw new IPCError(`Unexpected or unsupported message type: ${msg.type}`);
            }
        });
    }
    /**
     * Utility function to create a callback function that will proxy the call to the client.
     * When a callback with the same ID already exists, it will be used instead of creating a new one.
     * @param requestId The request ID of the original IPC request
     * @param destination The name of the target audience for messages
     * @param callbackId The ID of the callback function
     */
    createCallbackFunction(requestId, destination, callbackId) {
        var _a;
        const existingCallback = (_a = this.callbackIdToCallbackMap.get(callbackId)) === null || _a === void 0 ? void 0 : _a.deref();
        if (existingCallback) {
            return existingCallback;
        }
        const newCallback = (...args) => {
            const msg = {
                type: "CallbackRequest" /* MessageType.CallbackRequest */,
                requestId,
                callbackId,
                destination,
                source: this.name,
                functionArgs: args.map((arg) => ({ type: "Object" /* ArgumentType.Object */, value: arg })),
            };
            this.send(this.name, msg);
            return new Promise((resolve, reject) => {
                this.requestIdToCallbackResultPromiseMap.set(callbackId, { resolve, reject });
            });
        };
        this.callbackIdToCallbackMap.set(callbackId, new WeakRef(newCallback));
        // Register finalization callback to notify the client when
        // the callback function is garbage collected
        this.finalizationRegistry.register(newCallback, {
            callbackId: callbackId,
            requestId,
            destination,
        });
        return newCallback;
    }
    /**
     * Recursively replaces all custom IPC objects injected by the IPC client
     * to handle nested functions in objects.
     * @param input An object received from the client process
     * @param msgId The ID of the current message
     * @param msgSource The source of the current message
     * @returns A new object with all custom IPC objects replaced
     */
    replaceFunctionTags(input, msgId, msgSource) {
        // Replace IPC function tags objects
        if (input[IPC_FUNCTION_TAG_PROPERTY] === IPC_FUNCTION_TAG_VALUE) {
            const callbackId = input.callbackId;
            return this.createCallbackFunction(msgId, msgSource, callbackId);
        }
        // Replace function tags in arrays
        if (Array.isArray(input)) {
            return input.map((item) => this.replaceFunctionTags(item, msgId, msgSource));
        }
        // Replace function tags in objects
        if (input && typeof input === 'object') {
            const outObj = Object.assign({}, input);
            for (const key in outObj) {
                if (outObj[key] && typeof outObj[key] === 'object') {
                    // When an object contains the ipc tag, replace it with a callback handler
                    if (outObj[key][IPC_FUNCTION_TAG_PROPERTY] === IPC_FUNCTION_TAG_VALUE) {
                        const callbackId = outObj[key].callbackId;
                        outObj[key] = this.createCallbackFunction(msgId, msgSource, callbackId);
                        continue;
                    }
                }
                outObj[key] = this.replaceFunctionTags(outObj[key], msgId, msgSource);
            }
            return outObj;
        }
        return input;
    }
    /**
     * Handle function call request and send the response back to the client.
     * @param msg The incoming request message
     */
    handleRequest(msg) {
        return __awaiter(this, void 0, void 0, function* () {
            const args = msg.functionArgs.map((arg) => {
                arg = this.replaceFunctionTags(arg, msg.id, msg.source);
                if (arg.type === 'Callback') {
                    const cb = this.createCallbackFunction(msg.id, msg.source, arg.callbackId);
                    // Register finalization callback to notify the client when
                    // the callback function is garbage collected
                    this.finalizationRegistry.register(cb, {
                        callbackId: arg.callbackId,
                        requestId: msg.id,
                        destination: msg.source,
                    });
                    return cb;
                }
                return arg.value;
            });
            const functionName = msg.functionName;
            let data;
            let success;
            try {
                data = yield this.implementation[functionName](...args);
                success = true;
            }
            catch (err) {
                data = err;
                success = false;
            }
            const response = {
                type: "Response" /* MessageType.Response */,
                id: msg.id,
                source: this.name,
                destination: msg.source,
                success,
                data,
            };
            this.send(this.name, response);
        });
    }
    /**
     * Handle incoming callback response messages from the IPC client.
     * @param msg The incoming callback response message
     */
    handleCallbackResponse(msg) {
        const promise = this.requestIdToCallbackResultPromiseMap.get(msg.callbackId);
        if (!promise)
            return;
        if (msg.success) {
            promise.resolve(msg.data);
        }
        else {
            promise.reject(msg.data);
        }
        // Remove the current promise from the map
        this.requestIdToCallbackResultPromiseMap.delete(msg.callbackId);
    }
    /**
     * Handle finalization of callback functions and notify the client.
     * @param cleanupInfo Information about the callback function to be notified for cleaned up on the client
     */
    finalizationCallback(cleanupInfo) {
        this.callbackIdToCallbackMap.delete(cleanupInfo.callbackId);
        const msg = {
            type: "CallbackGarbageCollection" /* MessageType.CallbackGarbageCollection */,
            requestId: cleanupInfo.requestId,
            callbackId: cleanupInfo.callbackId,
            destination: cleanupInfo.destination,
            source: this.name,
        };
        this.send(this.name, msg);
    }
}
