import React from 'react';
import Collapse from '@mui/material/Collapse';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import HelpIcon from '@mui/icons-material/Help';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import BugReportIcon from '@mui/icons-material/BugReport';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import { useTranslation } from 'react-i18next';
import { useAppController } from '../../../controllers/AppController';

interface HelpMenuProps {
  expanded: boolean;
  onExpand: () => void;
}
const HelpMenu = (props: HelpMenuProps) => {
  const appController = useAppController();
  const { t } = useTranslation('prompter');

  return (
    <>
      <ListItemButton
        selected={props.expanded}
        onClick={() => {
          props.onExpand();
        }}
      >
        <ListItemIcon><HelpIcon /></ListItemIcon>
        <ListItemText
          primary={t('helpsupport', { ns: 'appmenu' })}
        />
        {props.expanded ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse
        sx={{
          pb: 2,
          background: 'rgba(25, 118, 210, 0.08)',
        }}
        in={!!props.expanded} timeout="auto"
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <List component="div" disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => { appController.dispatchMessage('prompter.local.showhelp'); }}
          >
            <ListItemIcon><HelpIcon /></ListItemIcon>
            <ListItemText
              primary={t('helpmenu.showhelp')}
            />
            <Typography variant="body2" color="text.secondary">
              Alt+H
            </Typography>{/* Win: ⎇   Mac: ⌥ */}
          </ListItemButton>
          <Divider />
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => {
              appController.dispatchMessage('prompter.local.contactus');
            }}
          >
            <ListItemIcon><BugReportIcon /></ListItemIcon>
            <ListItemText
              primary={t('helpmenu.reportbug')}
            />
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => {
              appController.dispatchMessage('prompter.local.featurerequest');
            }}
          >
            <ListItemIcon><LightbulbIcon /></ListItemIcon>
            <ListItemText
              primary={t('suggestfeature', { ns: 'appmenu' })}
            />
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => {
              appController.dispatchMessage('prompter.local.contactus');
            }}
          >
            <ListItemIcon><MailOutlineIcon /></ListItemIcon>
            <ListItemText
              primary={t('helpmenu.contactus')}
            />
          </ListItemButton>
        </List>
      </Collapse>
    </>
  );
};

export default HelpMenu;