import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import PrompterHudToc from '../../PrompterHudToc';
import PrompterHudShotLog from '../../PrompterHudShotLog';

enum WidgetTypes {
  TableOfContents = 'TableOfContents',
  ShotLog = 'ShotLog',
  // ElapsedSeconds = "ElapsedSeconds"
}

const WidgetContainer = () => {

  const defaultType = WidgetTypes.TableOfContents;
  /*
  switch(props.initialType) {
    case "TimeOfDay":
      defaultType = WidgetTypes.TimeOfDay;
      break;
    case "ElapsedSeconds":
      defaultType = WidgetTypes.ElapsedSeconds;
      break;
    default:
    case "Empty":
      defaultType = WidgetTypes.EmptyTimer;
      break;
  }
  */

  const [widgetType, setWidgetType] = React.useState<WidgetTypes>(defaultType);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const changeWidgetType = (type: WidgetTypes) => {
    setAnchorEl(null);
    setWidgetType(type);
  };

  let buttonText: string;
  switch(widgetType) {
    case WidgetTypes.TableOfContents:
      buttonText = 'Table of Contents';
      break;
    case WidgetTypes.ShotLog:
      buttonText = 'Shot Log';
      break;
  }
  return (<div style={{
    display: 'flex',
    flexDirection: 'column',
    pointerEvents: 'auto',
    minHeight: 0,
    overflow: 'hidden',
  }}>
    <Button
      size="small"
      endIcon={<ArrowDropDownIcon />}
      onFocus={(e) => { e.target.blur(); }}
      onClick={handleMenu}
    >{buttonText}</Button>
    {widgetType === WidgetTypes.TableOfContents && <PrompterHudToc />}
    {widgetType === WidgetTypes.ShotLog && <PrompterHudShotLog />}
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem onClick={() => { changeWidgetType(WidgetTypes.TableOfContents); }}>Table Of Contents</MenuItem>
      <MenuItem onClick={() => { changeWidgetType(WidgetTypes.ShotLog); }}>Shot Log</MenuItem>
    </Menu>
  </div>);
};

export default WidgetContainer;