import { BaseControlMessage } from './BaseControlMessage';
export const MEDIAWEBCAMREQUEST_KEY = 'media.webcam.request';
/**
 * Represents the MediaWebcamRequest type.
 */
export class MediaWebcamRequest extends BaseControlMessage {
    constructor(deviceId) {
        super();
        this.type = MEDIAWEBCAMREQUEST_KEY;
        this.deviceId = deviceId;
    }
    static fromJson(data) {
        if (data.type !== MEDIAWEBCAMREQUEST_KEY) {
            throw new SyntaxError('Invalid JSON for MediaWebcamRequest.');
        }
        const input = data;
        const instance = new MediaWebcamRequest();
        instance.deserializeSenderInfo(data.sender);
        instance.deviceId = input.deviceId;
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
            deviceId: this.deviceId,
        };
    }
}
MediaWebcamRequest.MESSAGE_NAME = MEDIAWEBCAMREQUEST_KEY;
