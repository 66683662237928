import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { v4 as uuidv4 } from 'uuid';
import createPrompterAppearanceSlice, { IPrompterAppearanceSlice, CONTENT_WIDTH_MINIMUM } from './PrompterAppearanceSlice';
import createPrompterControlsSlice, { IPrompterControlsSlice } from './PrompterControlsSlice';
import createPrompterMomentarySlice, { IPrompterMomentarySlice } from './PrompterMomentarySlice';
import createPrompterMediaConfigSlice, { IPrompterVideoLayoutSlice, MediaPanelPosition, MediaScalingMode } from './PrompterMediaConfigSlice';

export {
  CONTENT_WIDTH_MINIMUM,
  MediaPanelPosition,
  MediaScalingMode,
};

export interface ConfigurationStore
  extends IPrompterAppearanceSlice, IPrompterControlsSlice, IPrompterMomentarySlice, IPrompterVideoLayoutSlice
{
  endpointId: string;
  setEndpointId: (endpointId: string) => void;

  logLevel?: number;
  logLevelTimestamp?: number;
  setLogLevel: (logLevel?: number) => void;

  tourComplete: boolean;
  setTourComplete: (scrollSpeedLocked: boolean) => void;

  scrollReversed: boolean;
  toggleScrollReversed: () => void;
  setScrollReversed: (scrollReversed: boolean) => void;

  scrollSpeedLocked: boolean;
  toggleScrollSpeedLocked: () => void;
  setScrollSpeedLocked: (scrollSpeedLocked: boolean) => void;

  userScrollSpeed: number;
  setUserScrollSpeed: (speed: number, scrollReversed?: boolean) => void;

  scrollSpeed: number;
  increaseSpeed: (by: number) => void;
  decreaseSpeed: (by: number) => void;
  setScrollSpeed: (scrollSpeed: number, userScrollSpeed: number, scrollReversed?: boolean) => void;

  hideScrollSpeed: boolean;
  toggleHideScrollSpeed: () => void;
  setHideScrollSpeed: (scrollReversed: boolean) => void;
}

const useConfigurationStore = create<ConfigurationStore>()(persist<ConfigurationStore>(
  (set, get) => ({
    endpointId: uuidv4(),
    setEndpointId: (endpointId: string) => set(() => ({ endpointId })),

    logLevel: undefined,
    setLogLevel: (logLevel?: number) => set(() => ({
      logLevel,
      logLevelTimestamp: logLevel !== undefined ? Date.now() : undefined
    })),

    tourComplete: false,
    setTourComplete: (tourComplete: boolean) => set(() => ({ tourComplete })),

    scrollReversed: false,
    toggleScrollReversed: () => set((state) => ({ scrollReversed: !state.scrollReversed })),
    setScrollReversed: (scrollReversed: boolean) => set(() => ({ scrollReversed })),

    scrollSpeedLocked: false,
    toggleScrollSpeedLocked: () => set((state) => ({ scrollSpeedLocked: !state.scrollSpeedLocked })),
    setScrollSpeedLocked: (scrollSpeedLocked: boolean) => set(() => ({ scrollSpeedLocked })),

    userScrollSpeed: 120,
    setUserScrollSpeed: (userScrollSpeed: number, scrollReversed?: boolean) => set((state) => {
      return {
        userScrollSpeed,
        scrollSpeed: userScrollSpeed,
        scrollReversed: scrollReversed === undefined ? state.scrollReversed : scrollReversed,
      };
    }),

    scrollSpeed: 120,
    increaseSpeed: (by = 10) => set((state) => {
      if(state.scrollSpeedLocked) {
        return state;
      }

      let newScrollSpeed: number = get().scrollSpeed + by;
      if(newScrollSpeed > 500) { newScrollSpeed = 500; }
      return { scrollSpeed: newScrollSpeed };
    }),
    decreaseSpeed: (by = 10) => set((state) => {
      if(state.scrollSpeedLocked) {
        return state;
      }

      let newScrollSpeed = get().scrollSpeed - by;
      if(newScrollSpeed < 20) { newScrollSpeed = 20; }
      return { scrollSpeed: newScrollSpeed };
    }),
    setScrollSpeed: (scrollSpeed: number, userScrollSpeed: number, scrollReversed?: boolean) => set((state) => {
      if(state.scrollSpeedLocked) {
        return state;
      }

      return {
        scrollSpeed,
        userScrollSpeed,
        scrollReversed: scrollReversed === undefined ? state.scrollReversed : scrollReversed,
      };
    }),

    hideScrollSpeed: false,
    toggleHideScrollSpeed: () => set((state) => ({ hideScrollSpeed: !state.hideScrollSpeed })),
    setHideScrollSpeed: (hideScrollSpeed: boolean) => set(() => ({ hideScrollSpeed })),

    ...createPrompterAppearanceSlice(set, get),

    ...createPrompterControlsSlice(set, get),

    ...createPrompterMomentarySlice(set, get),

    ...createPrompterMediaConfigSlice(set, get),
  }),
  {
    name: 'fp-config-state', // name of item in the storage (must be unique)
  }
));

export default useConfigurationStore;