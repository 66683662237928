import { useCallback } from 'react';
import useConfigurationStore from '../../state/ConfigurationStore';
import usePrompterSession from '../../state/PrompterSessionState';
import { useMessageHandler, MessageHandlerEvent } from '../../controllers/AppController';
import { IViewportRefs } from './usePrompterViewportRefs';
import { IViewportFuncs } from './usePrompterViewportFuncs';

import { EndpointRole, PauseMessage, PrompterMode } from '@fluidprompter/core';

function usePrompterPauseHandler(viewportRefs: IViewportRefs, viewportFuncs: IViewportFuncs) {

  const doPause = useCallback(async function (e: MessageHandlerEvent<PauseMessage>) {
    const { message, originatedRemotely } = e;
    const { sender } = message;
    const senderIsRemote = sender?.role === EndpointRole.Remote;

    const prompterSession = usePrompterSession.getState();

    //
    // If we receive Play/Pause/Edit/Navigate commands from a remote peer, that peer is currently
    // acting as prompter leader.
    //
    // If this message was sent by a prompter (and not a headless remote), then we will re-evaluate
    // whether we are the current leader or not.
    const isLeader = e.checkIAmLeader(prompterSession);

    e.sendToPeers = !originatedRemotely || (senderIsRemote && isLeader);

    if(
      originatedRemotely
      && !isLeader
      && !senderIsRemote
    ) {
      //
      // Apply the remote prompter scroll speed to the local prompter.
      //
      e.syncScrollSpeed();

      //
      // Apply the remote prompter script position to the local prompter.
      //
      // CURRENTLY, this causes us to pause in the wrong spot...
      // TODO: Can we improve calculation of the pause position?
      // if(e.message.mode === PauseMessage.Mode.Immediate) {
      //   e.syncScrollPosition();
      // }
    }

    const localPrompterSession = usePrompterSession.getState();

    if(e.message.mode === PauseMessage.Mode.WhenArriveAtPosition) {
      const currentLedger = viewportRefs.previousLedgerRef.current;
      if(!e || !currentLedger) {
        return;
      }

      if(localPrompterSession.isPlaying) {
        const { scriptPosition } = message;
        // console.log('pauseAtScriptPosition', scriptPosition);

        let pauseAtPosition: number | undefined = originatedRemotely ? undefined : message.position; // message.position is not serialized/deserialized and so will be undefined if this originated remotely.
        if(!pauseAtPosition && scriptPosition) {
          const viewportPosition = localPrompterSession.getScrollPositionByScriptPosition(scriptPosition);
          if(!viewportPosition) {
            console.error('getScrollPositionByScriptPosition(scriptPosition) returned undefined in usePrompterPauseHandler() hook.');
            return;
          }

          const { scrollPosition } = viewportPosition;
          pauseAtPosition = scrollPosition - localPrompterSession.viewportMeta.cuePositionOffset;
          console.log(`pauseAtPosition ${pauseAtPosition}px`, scriptPosition);
        }

        if(pauseAtPosition) {
          currentLedger.pauseAtPosition = pauseAtPosition;
        }
      }
      return;
    } // END if(PauseMessage.Mode.WhenArriveAtPosition)

    const requestReversed = (sender?.scrollReversed === true);

    const configState = useConfigurationStore.getState();
    if(configState.scrollReversed !== requestReversed) {
      configState.setScrollReversed(requestReversed);
    }

    if(localPrompterSession.isPlaying) {
      localPrompterSession.pause();
    }

    //
    // If we are transmitting this message to peers, let's update our sender info.
    //
    if(e.sendToPeers && sender) {
      sender.mode = PrompterMode.Paused;
      sender.scrollReversed = requestReversed;
    }

  }, []);
  useMessageHandler('prompter.state.pause', doPause);

}

export default usePrompterPauseHandler;