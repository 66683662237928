import React, { useRef } from 'react';

import useConfigurationStore from '../../state/ConfigurationStore';
import usePrompterSession from '../../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';
import useStatsCollector from '../../hooks/useStatsCollector';

import FocusGuide from '../../models/FocusGuide';
import PrompterEditor from '../PrompterEditor';
import CuePositionFocusArea from './CuePositionFocusArea/CuePositionFocusArea';

import classNames from 'classnames';
import './PrompterViewport.scss';

import PositionLedgerEntry from './PositionLedgerEntry';

import useScriptFileHandlers from './useScriptFileHandlers';
import usePrompterViewportRefs from './usePrompterViewportRefs';
import usePrompterViewportFuncs from './usePrompterViewportFuncs';
import usePrompterCursorManagement from './usePrompterCursorManagement';
// import usePrompterScrolledHandler from './usePrompterScrolledHandler';
import usePrompterResizedHandler from './usePrompterResizedHandler';

import usePrompterResetHandler from './usePrompterResetHandler';
import usePrompterBlankingHandler from './usePrompterBlankingHandler';
import usePrompterTogglePlayHandler from './usePrompterTogglePlayHandler';
import usePrompterBeginEditHandler from './usePrompterBeginEditHandler';
import usePrompterPauseAtPositionHandler from './usePrompterPauseAtPositionHandler';
import usePrompterPlayHandler from './usePrompterPlayHandler';
import usePrompterSetReverseHandler from './usePrompterSetReverseHandler';
import usePrompterPauseHandler from './usePrompterPauseHandler';
import usePrompterAHDSRScrolling from './usePrompterAHDSRScrolling';
import usePrompterNavigateHandlers from './usePrompterNavigateHandlers';
import usePrompterPresetHandlers from './usePrompterPresetHandlers';

import usePrompterWindowManagement from './usePrompterWindowManagement';

import { useAppController, useMessageHandler } from '../../controllers/AppController';
import {
  PlayMessage, PauseMessage, NavigateMessage, EndpointRole,
} from '@fluidprompter/core';
import { ScrollToArgs } from './usePrompterScrollToPositionFunction';
import { ScrollByArgs } from './usePrompterScrollByDeltaFunction';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PrompterMediaPanel from '../PrompterMediaPanel';
import useViewportDimensions from './useViewportDimensions';

function PrompterViewport() {
  const theme = useTheme();
  const viewportIsMediumOrLarger = useMediaQuery(theme.breakpoints.up('sm'));

  const configStore = useConfigurationStore(state => ({
    textSize: state.textSize,
    lineHeight: state.lineHeight,
    contentWidth: state.contentWidth,
    leftGutter: state.leftGutter,
    rightGutter: state.rightGutter,
    backgroundColor: state.backgroundColor,
    textColor: state.textColor,
    flipHorizontal: state.flipHorizontal,
    flipVertical: state.flipVertical,
    focusGuide: state.focusGuide,
    cuePositionPercentage: state.cuePositionPercentage,

    mediaPanelPosition: state.mediaPanelPosition,
    mediaPanelHeight: state.mediaPanelHeight,
  }), shallow);
  const prompterSession = usePrompterSession(state => ({
    // scriptNodes: state.scriptNodes,
    // setScriptNodes: state.setScriptNodes,
    getScriptNode: state.getScriptNode,
    setCurrentScriptNode: state.setCurrentScriptNode,

    scriptNodesMeta: state.scriptNodesMeta,

    // prompterMode: state.prompterMode,
    isPlaying: state.isPlaying,
    isPaused: state.isPaused,
    isEditing: state.isEditing,
    play: state.play,
    pause: state.pause,
    edit: state.edit,
    cursorHidden: state.cursorHidden,
    // isLeader: state.isLeader,
    isBlanking: state.isBlanking,

    reset: state.reset,
    setElapsedPlayTime: state.setElapsedPlayTime,
    setFramesPerSecond: state.setFramesPerSecond,
    setTextMetrics: state.setTextMetrics,
  }), shallow);
  const collectFrameTiming = useStatsCollector(1000);

  const appController = useAppController();

  //
  // Implements handlers for opening files. Replaces previous FileManager component.
  //
  const {
    getRootProps,
    getInputProps,
    rootRef,
    isDragActive,
  } = useScriptFileHandlers();

  //
  // viewportRefs will contain all our essential Refs for manipulating or measuring the
  // viewport and its children.
  //
  // Note, we must pass on the ref for our PrompterViewport as it must be created by useDropzone()
  // hook above so that we can hook into the drag and drop events.
  //
  const viewportRefs = usePrompterViewportRefs({
    prompterViewportRef: rootRef,
  });

  // viewportFuncs contain reuseable functions that multiple other hooks may use to
  // manipulate the viewport. viewportFuncs may be passed into other hooks as a parameter.
  const viewportFuncs = usePrompterViewportFuncs(viewportRefs, configStore);

  const [prompterMaskClass, setPrompterMaskClass] = React.useState(prompterSession.isPlaying ? 'MaskVisible' : 'MaskHidden');

  //
  // Whenever the component is re-rendered with a new CSS class, add a listener for any CSS
  // animations that complete. When certain animations end, we will change the CSS class.
  //
  React.useEffect(() => {
    if(!viewportRefs.prompterViewportRef.current) {
      return;
    }

    viewportRefs.prompterViewportRef.current.addEventListener('animationend', () => {
      if(prompterMaskClass === 'MaskFadeIn') { setPrompterMaskClass('MaskVisible'); }
      if(prompterMaskClass === 'MaskFadeOut') { setPrompterMaskClass('MaskHidden'); }
    }, { once: true });
  }, [viewportRefs.prompterViewportRef, prompterMaskClass]); // Make sure the effect runs only once

  //
  // Whenever the component is re-rended because of a change in the playing state,
  // we will trigger a change in the CSS class to kickoff the show/hide animations.
  //
  React.useEffect(() => {
    if (prompterSession.isPlaying) {
      // Fade in the PrompterMask div. MaskHidden -> MaskFadeIn -> MaskVisible -> MaskFadeOut -> MaskHidden
      if(prompterMaskClass !== 'MaskVisible') {
        setPrompterMaskClass('MaskFadeIn');
      }
    } else {
      // Fade out the PrompterMask div. MaskHidden -> MaskFadeIn -> MaskVisible -> MaskFadeOut -> MaskHidden
      if(prompterMaskClass !== 'MaskHidden') {
        setPrompterMaskClass('MaskFadeOut');
      }
    }
  }, [prompterSession.isPlaying, prompterMaskClass]); // Make sure the effect runs only once

  // Manage when to hide/show the cursor.
  usePrompterCursorManagement();

  // Callback for when our prompter is scrolled (either programmatically or via user input)
  // const onPrompterScrolled = usePrompterScrolledHandler(viewportRefs, viewportFuncs, configStore);

  // Callback for when our prompter viewport or content has changed size. We will recalculate metrics like lines of text and max scroll position.
  const onPrompterResized = usePrompterResizedHandler(viewportRefs, viewportFuncs, configStore);

  // ****************************************
  // BEGIN `prompter.navigate.X` event handlers
  // ****************************************
  // Hook in our 'prompter.scrollto' event handler
  useMessageHandler('prompter.scrollto', (e) => {
    const args = e.message.payload as ScrollToArgs;
    viewportFuncs.scrollToPosition(args);
  });

  // Hook in our 'prompter.navigate.scrollby' event handler
  useMessageHandler('prompter.scrollby', (e) => {
    const args = e.message.payload as ScrollByArgs;
    viewportFuncs.scrollByDelta(args);
  });

  // Hook in our 'prompter.navigate.start' event handler
  usePrompterNavigateHandlers(viewportRefs, viewportFuncs);

  // ****************************************
  // END `prompter.navigate.X` event handlers
  // ****************************************

  // ****************************************
  // BEGIN `prompter.state.X` event handlers
  // These actions change the prompter state
  // ****************************************
  // Hook in our 'prompter.state.toggleplay' event handler
  usePrompterTogglePlayHandler();

  // Hook in our 'prompter.state.play' event handler
  usePrompterPlayHandler(viewportFuncs);

  // Hook in our 'setrevserse' event handler
  usePrompterSetReverseHandler(viewportFuncs);

  // Hook in our 'prompter.state.pause' event handler
  usePrompterPauseHandler(viewportRefs, viewportFuncs);

  // Momentary scrolling event handler
  usePrompterAHDSRScrolling(viewportRefs, viewportFuncs);

  // Hook in our 'prompter.pauseAtPosition' event handler
  usePrompterPauseAtPositionHandler(viewportRefs);

  // Hook in our 'prompter.state.edit' event handler
  usePrompterBeginEditHandler(viewportFuncs);

  // Hook in our 'prompter.state.reset' event handler
  usePrompterResetHandler(viewportRefs, viewportFuncs);

  // Hook in our 'prompter.content.show/hide' event handlers.
  usePrompterBlankingHandler();
  // ****************************************
  // END `prompter.state.X` event handlers
  // These actions change the prompter state
  // ****************************************

  // Hook handles saving and recalling prompter presets.
  usePrompterPresetHandlers();

  // Hook that will handle launching a 2nd prompter window on a 2nd monitor as a projector.
  usePrompterWindowManagement(viewportFuncs);

  // useMediaSessionApi();

  // ****************************************
  // BEGIN `prompter.script.X` event handlers
  // These actions change the prompter state
  // ****************************************

  // ****************************************
  // END `prompter.script.X` event handlers
  // These actions change the prompter state
  // ****************************************


  /* TODO: DELETE THIS! Using new appController below.
  useListener('prompter.setCurrentScriptNode', (e?: ScriptNodeStateChangedEvent) => {
    if(e && e.nodePath) {
      const currentNode = prompterSession.getScriptNode(e.nodePath);

      // Don't clear the last set node if we are currently in between nodes on the promtper.
      if(currentNode) {
        prompterSession.setCurrentScriptNode(currentNode);
      }
    }
  });
  */

  useMessageHandler('segmentchanged', (e) => {
    const { message, originatedRemotely } = e;
    const { sender, currentSegmentIndex } = message;
    const senderIsRemote = sender?.role === EndpointRole.Remote;

    const localPrompterSession = usePrompterSession.getState();

    e.sendToPeers = localPrompterSession.isLeader && (!originatedRemotely || senderIsRemote);
    if(sender && e.sendToPeers) {
      const { scriptPosition } = sender;
      if(scriptPosition) {
        scriptPosition.nodePath = [currentSegmentIndex];
      }
    }

    const currentNode = localPrompterSession.getScriptNode([currentSegmentIndex]);

    // Don't clear the last set node if we are currently in between nodes on the promtper.
    if(currentNode) {
      localPrompterSession.setCurrentScriptNode(currentNode);
    }
  });

  useMessageHandler('nodechanged', (e) => {
    const { message, originatedRemotely } = e;
    const { sender } = message;
    const senderIsRemote = sender?.role === EndpointRole.Remote;

    const prompterSession = usePrompterSession.getState();

    //
    // If we receive Play/Pause/Edit/Navigate commands from a remote peer, that peer is currently
    // acting as prompter leader.
    //
    // If this message was sent by a prompter (and not a headless remote), then we will re-evaluate
    // whether we are the current leader or not.
    const isLeader = e.checkIAmLeader(prompterSession);

    e.sendToPeers = isLeader && (!originatedRemotely || senderIsRemote);
    if(sender && e.sendToPeers) {
      const { scriptPosition } = sender;
      if(scriptPosition) {
        scriptPosition.nodePath = message.nodePath;
        scriptPosition.nodeHeight = message.nodeHeight;
        scriptPosition.nodeChildrenTop = message.nodeChildrenTop;
        scriptPosition.nodeChildrenHeight = message.nodeChildrenHeight;
        scriptPosition.position = message.position;
      }
    }

    viewportFuncs.queueSequentialTask(async () => {
      const localPrompterSession = usePrompterSession.getState();

      if(e.originatedRemotely && localPrompterSession.isPlaying) {
        //
        // Apply the remote prompter scroll speed to the local prompter.
        //
        e.syncScrollSpeed();

        e.syncScrollPosition();
      }
    });

  });

  //
  // Applies syncronization adjustments on "Follower" prompters to re-align with the current
  // leader position.
  //
  // This will be executed on the follower prompter(s), but not executed on the leader prompter.
  //
  useMessageHandler('prompter.applysyncadjustment', (e) => {
    // console.log('Handle prompter.applysyncadjustment message', e.message);

    const { isPlaying } = usePrompterSession.getState();
    if(isPlaying) {
      const ledger = viewportRefs.previousLedgerRef.current;
      if(!ledger) {
        return;
      }

      const { syncAdjustmentDistance, syncAdjustmentTime } = e.message;
      ledger.setSyncAdjustment(syncAdjustmentDistance, syncAdjustmentTime);
      return;
    }

    // Prompter state is NOT PLAYING - Just skip to the desired position.
    viewportFuncs.scrollByDelta({
      deltaY: e.message.syncAdjustmentDistance,
    });
  });

  //
  // This prompter isntance just sent syncronization to followers, let's keep track of the last
  // send timestamp to help ensure a minimum syncronization interval.
  //
  useMessageHandler('sync.updatelastsend', () => {
    const ledger = viewportRefs.previousLedgerRef.current;
    if(!ledger) {
      // console.log('ledger is null or undefined');
      return;
    }

    ledger.syncMessageWasSent();
  });

  //
  // The 'sync' message only serves to syncronize the prompter
  //
  useMessageHandler('sync', (e) => {
    const { message, originatedRemotely } = e;
    const { sender } = message;
    const senderIsRemote = sender?.role === EndpointRole.Remote;

    const { isLeader } = usePrompterSession.getState();

    e.sendToPeers = !originatedRemotely || (senderIsRemote && isLeader);

    if(!isLeader && originatedRemotely) {
      viewportFuncs.queueSequentialTask(async () => {
        //
        // Apply the remote prompter scroll speed to the local prompter.
        //
        e.syncScrollSpeed();

        //
        // Apply the remote prompter script position to the local prompter.
        //
        e.syncScrollPosition();
      });
    }
  });

  useMessageHandler('prompter.test', (e) => {
    const { message, originatedRemotely } = e;
    const { sender } = message;
    const senderIsRemote = sender?.role === EndpointRole.Remote;

    const prompterSession = usePrompterSession.getState();

    e.sendToPeers = !originatedRemotely || (senderIsRemote && prompterSession.isLeader);

    if(!prompterSession.isLeader && originatedRemotely) {
      viewportFuncs.queueSequentialTask(async () => {
        //
        // Apply the remote prompter scroll speed to the local prompter.
        //
        e.syncScrollSpeed();

        //
        // Apply the remote prompter script position to the local prompter.
        //
        e.syncScrollPosition();
      });
      return;
    }

    const localPrompterSession = usePrompterSession.getState();
    // if(e.originatedRemotely) {
    //   e.syncScrollPosition();

    //   e.syncScrollSpeed();
    // }

    const { focusGuide } = useConfigurationStore.getState();
    // const scriptPosition = localPrompterSession.scriptNodesState?.scriptPosition;

    const scrollPosition = localPrompterSession.scrollPosition;
    const viewportHeight = localPrompterSession.viewportMeta.viewportHeight;
    let cuePosition = scrollPosition;
    switch(focusGuide) {
      case FocusGuide.Top:
        cuePosition = scrollPosition + viewportHeight * 0.2;
        break;
      default:
      case FocusGuide.Middle:
        cuePosition = scrollPosition + viewportHeight * 0.5;
        break;
      case FocusGuide.Bottom:
        cuePosition = scrollPosition + viewportHeight * 0.8;
        break;
    }

    const currentScriptPosition = localPrompterSession.getScriptPositionByScrollPosition(cuePosition);
    console.log(`ScriptPosition at ${cuePosition}px is [${currentScriptPosition?.nodePath}]`, currentScriptPosition);

    if(!currentScriptPosition) {
      return;
    }

    const returnedScrollPosition = localPrompterSession.getScrollPositionByScriptPosition(currentScriptPosition);
    console.log(`returnedScrollPosition=${returnedScrollPosition?.scrollPosition}px, heightRemaining=${returnedScrollPosition?.heightRemaining}px, difference=${(returnedScrollPosition?.scrollPosition || 0) - cuePosition}`);

    // console.log(`scriptPosition[${scriptPosition?.nodePath}] @ ${scriptPosition?.position}%`, scriptPosition);
  });

  const clickHandler = React.useCallback((e: MouseEvent): void => {
    switch(e.button) {
      case 0: // Left click
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.PageUp));
        break;
      case 1: { // Middle click
        e.preventDefault();
        e.stopPropagation();
        const isPlaying = usePrompterSession.getState().isPlaying;
        appController.dispatchMessage(isPlaying ? new PauseMessage() : new PlayMessage());
        break;
      }
      case 2: // Right click
        // If the editor is actively focused, we will allow the browser's native context menu.
        if(usePrompterSession.getState().editorFocused) {
          return;
        }

        e.preventDefault(); // Disable the browser's default context menu - we will implement our own application context menu's
        e.stopPropagation();
        appController.dispatchMessage('prompter.local.contextmenu');
        break;
      default:
        break;
    }
  }, [appController]);

  // Bubble vs Capture Events: https://javascript.info/bubbling-and-capturing
  React.useEffect(() => {
    const maskElement = viewportRefs.prompterViewportRef.current as HTMLDivElement;
    if(!maskElement) {
      return;
    }

    // maskElement.addEventListener('click', clickHandler, { capture: true, passive: false });
    // maskElement.addEventListener('auxclick', clickHandler, { capture: true, passive: false });
    maskElement.addEventListener('contextmenu', clickHandler);

    return () => {
      // cleanup function
      // maskElement.removeEventListener('click', clickHandler, { capture: true });
      // maskElement.removeEventListener('auxclick', clickHandler, { capture: true });
      maskElement.removeEventListener('contextmenu', clickHandler);
    };
  }, [viewportRefs.prompterViewportRef, clickHandler]);

  //
  // requestAnimationFrame callback function to process updating the PrompterSurface scroll position.
  //
  // useWhatChanged([viewportRefs, collectFrameTiming, prompterSession]);
  const animate = React.useCallback(() => {
    const localPrompterState = usePrompterSession.getState();
    const currentScrollReversed = viewportRefs.scrollReversedRef.current;
    const currentScrollSpeed = viewportRefs.scrollSpeedRef.current;
    const scrollPositionMax = viewportRefs.scrollPositionMaxRef.current;
    const ahdsr = viewportRefs.ahdsrRef.current;

    //
    // If we have an ahdsr statemachine instance, then retrieve its current state.
    //
    let ahdsrValue: number | undefined;
    let ahdsrReversed: boolean | undefined;
    if(ahdsr) {
      ({
        ahdsrValue,
        ahdsrReversed,
      } = ahdsr.getCurrentEnvelopeValue(currentScrollSpeed));
    }

    let previousLedger = viewportRefs.previousLedgerRef.current;
    if(!previousLedger) {
      // initialize first ledger entry after the component is rendered.
      previousLedger = new PositionLedgerEntry(
        localPrompterState.isPlaying,
        ahdsrReversed || currentScrollReversed,
        (ahdsrValue !== undefined) ? ahdsrValue : currentScrollSpeed,
        scrollPositionMax
      );

      //
      // Ease in time
      //
      // currentScrollSpeed = pixel/second
      // const easeInTime = 500;
      // const easeInDistance = currentScrollSpeed * easeInTime / 1000;
      // previousLedger.setCurrentSkip(easeInDistance, easeInTime, 'Sinusoidal');
    }

    //
    // Calculate the next frame
    //
    const deltaTime = performance.now() - previousLedger.timestamp;
    const { /*count: fps,*/ avg: frameTime, /*jitter: frameJitter*/ } = collectFrameTiming(deltaTime);  // Record our frame timing and calculate frames per second.
    const fpsCalculated = Math.round(1000 / frameTime);
    localPrompterState.setFramesPerSecond(fpsCalculated);
    /*
    const {
      scriptNodes,
      setScriptNodesMeta,
    } = usePrompterSession.getState();
    */

    // TODO: Finalize Jitter Calculations? console.log(`Current: ${deltaTime}, Average: ${frameTime}, Jitter: ${frameJitter}`);

    /*
    if(deltaTime < 16.0) {
      console.log(`small deltaTime ${deltaTime} transition from ${previousLedger.type}`);
    }
    if(deltaTime > 18.25) {
      console.log(`big deltaTime ${deltaTime} transition from ${previousLedger.type}`);
    }*/

    //
    // Prepare the PositionLedger for the next animation frame.
    //
    // The PositionLedger class will also calculate the next scroll position using either the
    // current scroll speed or any skip animation in progress.
    //
    const nextLedger = previousLedger.clone(
      localPrompterState.isPlaying,
      ahdsrReversed || currentScrollReversed,
      (ahdsrValue !== undefined) ? ahdsrValue : currentScrollSpeed,
      scrollPositionMax
    );

    // If the AHDSR controller is returning 0 scroll speed it is finished an easing function.
    if((ahdsrValue !== undefined) && ahdsrValue < 0.001) {
      nextLedger.type = 'pause';
    }

    //
    // We may be pausing because AHDSR finished its release phase OR
    // We may be pausing because we hit a trigger in the script OR
    // We may be pausing due to other human inputs (controls)
    // In all cases, we want to reset the AHDSR state machine to idle.
    //
    if(
      localPrompterState.isLeader &&
      (ahdsrValue !== undefined)
      && (nextLedger.type === 'pause')
    ) {
      // AHDSR state became idle (we've finished the `release` phase).
      appController.dispatchMessage(
        ahdsrReversed
          ? 'prompter.state.momentaryreverse.idle'
          : 'prompter.state.momentaryplay.idle'
      );
    }

    //
    // nextLedger.type can be 'pause' for multiple reasons. We may have scroll to a pending pause
    // position in the script. We may have scrolled to the end of the script.
    //
    if(
      localPrompterState.isLeader &&
      nextLedger.type === 'pause'
    ) {
      // For whatever reason, the next animation frame is in a paused state. Let's pause our
      // prompter session and stop the requestAnimationFrame loop.
      appController.dispatchMessage(new PauseMessage());  // This will also transmit to cloud remote/peers.
    }

    //
    // If we haven't sent prompter syncronization info in more than 1 second, then lets send sync
    // info now.
    //
    /*
     * Forcing a sync every 1 second using reliable SCTP protocol connection only works well on
     * non-lossy networks. Otherwise lossed packets with exponential back-off end up causing
     * unusual delays in the sync which causes pauses, reverse movement or stutters in the
     * scrolling.
     *
     * In the future we may use non-reliable transport and re-send unacknowledged messages fairly
     * frequently until acknowledged. In that scenario we can also ignore stale sync's if a new
     * sync is sent.
     */
    if(
      localPrompterState.isLeader &&
      nextLedger.syncMessageRequired()
    ) {
      appController.dispatchMessage('sync');
      nextLedger.syncMessageWasSent();
    }

    // console.log(`1.) PrompterContainer.animate() documentScrollPosition = ${nextLedger.scrollPosition}, scrollPositionMax = ${scrollPositionMax}`);

    const segmentsContainerRef = viewportRefs.segmentsContainerRef.current;
    if(segmentsContainerRef) {
      segmentsContainerRef.scroll(nextLedger.scrollPosition);
    } else {
      console.log('viewportRefs.segmentsContainerRef is null');
    }

    localPrompterState.setElapsedPlayTime(nextLedger.elapsed);

    viewportRefs.previousLedgerRef.current = nextLedger;

    if(
      nextLedger.type !== 'pause'
      || nextLedger.skipAnimationInProgress()
    ) { // Will be false if we intend to pause the animation.
      viewportRefs.requestRef.current = requestAnimationFrame(animate);
      return;
    }

    // We only get here if we didn't return after scheduling the next rAF callback.
    // Clean this up in case there was anything left over when we paused...
    nextLedger.clearSyncAdjustment();
    nextLedger.pauseAtPosition = 0;

    // We only get here if we didn't return after scheduling the next rAF callback.
    // This ref is used to prevent duplicate running of useEffect in React strict mode while developing.
    animationIsRunning.current = false;
  }, [viewportRefs, collectFrameTiming, appController]);  // END animate() function

  /**
   * useEffect that will start requestAnimationFrame after render and cleans-up
   * any pending animationFrame as part of cleanup when unmounting.
   */
  // This ref is used to prevent duplicate running of useEffect in React strict mode while developing.
  const animationIsRunning = useRef<boolean | undefined>();
  React.useEffect(() => {
    //
    // Handle React Strict Mode running hook twice.
    //
    if(prompterSession.isPlaying === animationIsRunning.current) {
      return;
    }
    animationIsRunning.current = prompterSession.isPlaying;

    //
    // We just changed the state of prompterState. Let's update the previous ledger entry.
    //
    let previousLedger = viewportRefs.previousLedgerRef.current;
    if(!previousLedger) {
      const currentScrollReversed = viewportRefs.scrollReversedRef.current;
      const currentScrollSpeed = viewportRefs.scrollSpeedRef.current;
      const scrollPositionMax = viewportRefs.scrollPositionMaxRef.current;

      viewportRefs.previousLedgerRef.current = previousLedger = new PositionLedgerEntry(prompterSession.isPlaying, currentScrollReversed, currentScrollSpeed, scrollPositionMax);
    }

    //
    // Are we transitioning from paused state to playing state?
    //
    if(prompterSession.isPlaying && previousLedger.type === 'pause') {
      // console.log('was paused - now unpause!');

      if(document.activeElement) {
        // Make sure no user inputs are currently focused, stealing keypresses.
        const activeElement = document.activeElement as HTMLElement;
        activeElement.blur();  // THIS WAS CAUSING SLATE EDITOR TO LOSE FOCUS
      }

      // Set our starting position - the user may have manually scrolled the prompter content while
      // paused or editing in which case our ledger entry is stale.
      const scrollEl = document.scrollingElement;
      if(scrollEl) {
        const proposedScrollPosition = scrollEl.scrollTop;
        const scrollRange = Math.max(0, scrollEl.scrollHeight - scrollEl.clientHeight);
        previousLedger.scrollPosition = useConfigurationStore.getState().flipVertical
          ? scrollRange - proposedScrollPosition
          : proposedScrollPosition;
      }
    }

    // Now if we just started playing, then kickoff the request animation frame.
    // if(prompterSession.isPlaying && !animationIsRunning.current) {
    //   viewportRefs.requestRef.current = requestAnimationFrame(animate);
    //   animationIsRunning.current = true;
    // }
    if(prompterSession.isPlaying) {
      viewportRefs.requestRef.current = requestAnimationFrame(animate);
    }

    // Return a clean-up function that is used if the component is re-rendered.
    // return () => cancelAnimationFrame(viewportRefs.requestRef.current);
  }, [viewportRefs.previousLedgerRef, viewportRefs.requestRef,  prompterSession.isPlaying, animate]);

  const { flipHorizontal, flipVertical } = configStore;

  //
  // This is the new way to calculate viewport dimensions
  //
  const {
    topOffset,
    height,
    bottomOffset,
    //
    contentWidth,
    leftGutter,
    rightGutter,
    //
    prompterMaskTopHeight,
    prompterMaskTopOffset,
    prompterMaskBottomHeight,
    prompterMaskBottomOffset,
    //
    prompterFocusTop,
    prompterFocusBottom,
  } = useViewportDimensions();

  /**
   * Output the JSX that represent's this components DOM.
   */
  return (<>
    <div
      {...getRootProps()}
      className={classNames('PrompterContainer', {
        DragActive: isDragActive,
        HideCursor: prompterSession.cursorHidden,
        PrompterPlaying: prompterSession.isPlaying,
        PrompterPaused: prompterSession.isPaused,
        PrompterBlanking: prompterSession.isBlanking,
      })}
      style={{
        backgroundColor: configStore.backgroundColor,
      }}
    >{/* `PrompterContainer ${prompterMaskClass} ` */}
      <PrompterMediaPanel />

      <PrompterEditor
        ref={viewportRefs.segmentsContainerRef}
        viewportFuncs={viewportFuncs}

        textSize={configStore.textSize}
        textColor={configStore.textColor}
        lineHeight={configStore.lineHeight}
        contentWidth={contentWidth}
        leftGutter={leftGutter}
        rightGutter={rightGutter}
        flipHorizontal={flipHorizontal}
        flipVertical={flipVertical}
        // onScroll={onPrompterScrolled}
        onResize={onPrompterResized}

        // document={prompterSession.scriptNodes}
        readOnly={!prompterSession.isEditing}
        // onChange={prompterSession.setScriptNodes}
      />
      <div
        className="PrompterMaskTop"
        style={{
          height: `${prompterMaskTopHeight}`,
          transform: `translateY(${prompterMaskTopOffset})`
        }}
      ></div>
      <CuePositionFocusArea
        viewportTopOffset={topOffset}
        viewportHeight={height}
        viewportBottomOffset={bottomOffset}
        isEditing={prompterSession.isEditing}
        isPlaying={prompterSession.isPlaying}
        isPaused={prompterSession.isPaused}
        topPosition={prompterFocusTop}
        bottomPosition={prompterFocusBottom}
        contentWidth={contentWidth}
        leftGutter={leftGutter}
        flipHorizontal={flipHorizontal}
        flipVertical={flipVertical}
        textSize={configStore.textSize}
      />
      <div
        className="PrompterMaskBottom"
        style={{
          height: `${prompterMaskBottomHeight}`,
          transform: `translateY(${prompterMaskBottomOffset})`
        }}
      ></div>
      {/*<LedIndicator />*/}
      {/*<div style={{
        pointerEvents: 'none',
        position: 'fixed',
        top: 0, left: 0,
        zIndex: 2000,
        color: '#ff0000',
        fontSize: '48pt'
      }}>{prompterSession.isLeader ? 'LEADER' : 'FOLLOWER'}</div>*/}
    </div>
    <input {...getInputProps()} />
  </>);
}

export default PrompterViewport;