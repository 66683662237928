import React, { useCallback } from 'react';
import { Editor, Node, Descendant, Transforms } from 'slate';
import { ReactEditor, useSlateStatic, RenderElementProps, useFocused, useSelected } from 'slate-react';
import { IScriptNodeStateComparator } from '@fluidprompter/core';

import { Pause, PausePosition, PrompterElement } from '../../../models/EditorTypes';

import { useAppController } from '../../../controllers/AppController';
import { useConfirm } from 'material-ui-confirm';
import { useTranslation } from 'react-i18next';
import usePrompterSession from '../../../state/PrompterSessionState';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import Button, { buttonClasses } from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import PrompterIconButton from '../../PrompterIconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import SegmentInsertToolbar from '../SegmentInsertToolbar';

import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import './PauseElement.scss';

const PauseTypeButton = styled(Button)({
  fontSize: 'inherit',
  fontWeight: 'bold',
  lineHeight: '1.25',
  paddingLeft: 12,
  color: 'inherit',
  whiteSpace: 'nowrap',
  [`&.${buttonClasses.disabled}`]: {
    color: 'inherit', // The pause type button is disabled when prompting.
  },
  '.MuiButton-endIcon': {
    '& > *:nth-of-type(1)': {
      fontSize: '1.25em',
    }
  },
});

function PauseNode(props: RenderElementProps) {
  const { attributes, children, element } = props;
  const pauseNode = element as Pause;
  const { pausePosition } = pauseNode;

  const appController = useAppController();

  const { t } = useTranslation('prompter');

  const options = [{
    value: 'CUE',
    label: t('pauseelement.pauseatcue')
  }, {
    value: 'TOP',
    label: t('pauseelement.pauseattop')
  }, {
    value: 'BOTTOM',
    label: t('pauseelement.pauseatbottom')
  }];

  const isPaused = usePrompterSession(state => state.isPaused);
  // const nodeState = usePrompterSession(state => state.getNodeState(props.element), IScriptNodeStateComparator);
  //
  // console.log(`Pause Node State Changed`, nodeState);

  const confirm = useConfirm();

  const editor = useSlateStatic();

  const selected = useSelected();  // Get the current selected state of an element.
  const focused = useFocused();    // Get the current focused state of the editor.

  // These are for the drop down menu for pause type.
  const [menuOpen, setMenuOpen] = React.useState(false);
  const menuAnchorRef = React.useRef<HTMLButtonElement>(null);

  //
  // Clicking the main button will toggle the menu.
  //
  const handleToggle = () => {
    setMenuOpen((prevOpen) => !prevOpen);
  };

  //
  // This function will update the Slate editor node with our changed pause position.
  //
  const applyPausePositionChange = useCallback(
    (pausePosition: PausePosition) => {
      const pauseNodeEntry = Editor.above(editor, {
        match: (n: Node) => {
          const node = n as PrompterElement;
          return node.type === 'pause';
        },
      });
      if (pauseNodeEntry == null) {
        return;
      }

      // If the local prompter believes it is the leader, then the following node transform
      // will be sent to all other connected prompters.
      //
      // After a script modification has been sent to all other connected prompters, those
      // other prompters will also recognize this prompter as the current leader.
      appController.setLeaderIsSelf();
      Transforms.setNodes<Pause>(
        editor,
        { pausePosition },
        { at: pauseNodeEntry[1] }
      );
    },
    [editor]
  );

  //
  // Clicking one of the items in the menu will update our pause node parameters.
  //
  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    const selectedItem = options[index];
    applyPausePositionChange(selectedItem.value as PausePosition);
    setMenuOpen(false);
  };

  //
  // If the user clicks somewhere outside the menu, let's close the menu.
  //
  const handleCickAway = (event: Event) => {
    if (
      menuAnchorRef.current &&
      menuAnchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setMenuOpen(false);
  };

  const onInsertNodeHandler = useCallback((node: Descendant) => {
    const freshpath = ReactEditor.findPath(editor, element);

    // Insert the proposedNode after the current node.
    const currentNodeIndex = freshpath.shift();
    if(currentNodeIndex !== undefined) {
      freshpath.unshift(currentNodeIndex + 1);
    }

    // If the local prompter believes it is the leader, then the following node transform
    // will be sent to all other connected prompters.
    //
    // After a script modification has been sent to all other connected prompters, those
    // other prompters will also recognize this prompter as the current leader.
    appController.setLeaderIsSelf();
    Transforms.insertNodes<Descendant>(editor, node, {
      at: freshpath,
    });
    Transforms.select(editor, freshpath);
  }, [editor, element]);

  const selectedItem = options.find((option) => option.value === pausePosition);
  const pauseLabel = selectedItem ? selectedItem.label : t('pauseelement.defaultcaption');

  return (
    <div
      className={classNames('PauseElement', { isSelected: (selected && focused), isPaused })}
      {...attributes}
      contentEditable={false}
    >
      <div className='PauseElementHeader'>
        {/*<DragHandleIcon />*/}
        <div className="Content">
          <PauseTypeButton
            ref={menuAnchorRef}
            endIcon={<ArrowDropDownIcon className="HideWhenPrompting" sx={{ fontSize: 40 }} />}
            // disabled={editorIsReadOnly}
            aria-expanded={menuOpen ? 'true' : undefined}
            aria-label="Select Pause Type"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            {isPaused ? t('pauseelement.pausedcaption') : pauseLabel}{children}
          </PauseTypeButton>
          <Popper
            sx={{
              zIndex: 2,
            }}
            open={menuOpen}
            anchorEl={menuAnchorRef.current}
            role={undefined}
            transition
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleCickAway}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      {options.map((option, index) => (
                        <MenuItem
                          key={option.value}
                          selected={option.value === pausePosition}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
        <PrompterIconButton
          className="HideWhenPrompting"
          size="large"
          edge="start"
          // color="inherit"
          aria-label="Delete Pause Segment"
          sx={{
            mr: 1,
            /*color: editorIsReadOnly ? 'transparent' : 'inherit'*/
          }}
          // disabled={editorIsReadOnly}
          onFocus={(e) => { e.target.blur(); }}
          onMouseDown={(e) => {
            e.preventDefault(); // Prevent focus of this element.
          }}
          onClick={
            async (e) => {
              await confirm({ description: 'Are you sure you want to delete this segment?' });

              // If the local prompter believes it is the leader, then the following node removal
              // will be sent to all other connected prompters.
              //
              // After a script modification has been sent to all other connected prompters, those
              // other prompters will also recognize this prompter as the current leader.
              appController.setLeaderIsSelf();
              const path = ReactEditor.findPath(editor, element);
              Transforms.removeNodes(editor, { at: path });
            }
          }
        >
          <DeleteForeverIcon />
        </PrompterIconButton>
      </div>
      <SegmentInsertToolbar onInsertNode={onInsertNodeHandler} />
    </div>
  );
}

export default PauseNode;