import React, { useRef, useState, useCallback } from 'react';

import { Editor, Transforms, Range, Descendant, BaseOperation, NodeEntry, Text } from 'slate';
import { ReactEditor } from 'slate-react';

import { PrompterEditor, PrompterText, PrompterTextMarks } from '../../models/EditorTypes';
import { IViewportFuncs } from '../PrompterViewport/usePrompterViewportFuncs';

import AppController from '../../controllers/AppController/AppController';
import usePrompterSession from '../../state/PrompterSessionState';
import { useMessageHandler } from '../../controllers/AppController';
import { EditorOperationsMessage, EndpointRole, ScriptStateMessage } from '@fluidprompter/core';
import PrompterPeerInstance from '../../devices/prompterpeer/PrompterPeerInstance';

import isEqual from 'lodash/isEqual';

interface useEditorLoadScriptCommandHandlerProps {
  appController: AppController,
  editor: PrompterEditor,
  viewportFuncs: IViewportFuncs,

  /**
   * This callback is fired after the scriptNodes data has been replaced but before the editor has
   * re-rendered the browser DOM. This is an opportunity to perform work that might impact how the
   * editor is rendered such as calculating text metrics which are displayed in the editor.
   * @param doc
   * @returns void
   */
  onReplaceScriptBeforeRender: (doc: Descendant[]) => void,

  /**
   * This callback is fired after the script has been replaced and the browser DOM has been
   * re-rendered. This is an opportunity to perform work that requires the DOM to be up to date,
   * such as measuring the size of the DOM nodes.
   * @param doc
   * @returns void
   */
  onReplaceScriptAfterRender: (doc: Descendant[]) => void,

  /**
   * This callback is fired after every script change in the editor. This is an opportunity to
   * refresh any editor metrics or meta data: calculate text metrics, measure script nodes, and
   * evaluate node positions.
   * @param doc
   * @returns void
   */
  onEditorChangeCallback: (doc: Descendant[], loadScriptInProgress: boolean) => void,
}

interface useEditorReplaceScriptCommandHandlerExports {
  editableKey: number;
  onEditorChange: (doc: Descendant[]) => void;
}

type PromiseRevolverCallback = () => void;

const useEditorLoadScriptCommandHandler = (
  props: useEditorLoadScriptCommandHandlerProps
): useEditorReplaceScriptCommandHandlerExports => {

  const {
    appController,
    editor,
    viewportFuncs,
    onReplaceScriptBeforeRender,
    onReplaceScriptAfterRender,
    onEditorChangeCallback,
  } = props;

  const promiseRef = useRef<PromiseRevolverCallback | null>(null);
  const [editableKey, setEditableKey] = useState<number>(1);

  //
  // SlateJS fires an change event whenever the editable content has been changed - regardless of
  // whether that change originated locally from human input or originated remotely and was sent
  // to us from a connected peer.
  //
  // We need a method to detect whether changes in the editor are happening due to a remote peer
  // message vs due to local human input.
  //
  // We will queue editor operations we received from connected peers, and then dequeue those
  // operations when the SlateJS editor informs us they are applied.
  //
  const pendingRemoteOperations = useRef<BaseOperation[]>([]);

  const loadScriptInProgress = useRef<boolean>(false);

  // we update selection here because Slate fires an onChange even on pure selection change.
  const onEditorChange = useCallback((doc: Descendant[]) => {
    // console.log('PrompterEditor.onEditorChange()', editor.operations);

    const {
      setScriptNodes,
      hasUnsavedChanges,
      setHasUnsavedChanges,
    } = usePrompterSession.getState();

    //
    // Is this onChange event being triggered by a change in user selection only, or a real change
    // in the script content?
    //
    const isAstChange = editor.operations.some(
      op => 'set_selection' !== op.type
    );
    if(isAstChange) {
      // Persist script node changes.
      setScriptNodes(doc);

      // Mark our state as dirty, will be cleared if you save the script.
      if(!hasUnsavedChanges) {
        setHasUnsavedChanges();
      }
    }

    //
    // Collect the set of marks on the current editor selection range.
    //
    const selectionActiveMarks: PrompterTextMarks = {};
    if(editor.selection) {
      //
      // Enumerate all text nodes within the current editor selection.
      const nodesMatchedWithFormat = Editor.nodes<PrompterText>(editor, {
        at: editor.selection,
        match: (node) => Text.isText(node),
        mode: 'all',
      });

      //
      // Iterate over all text nodes within the current editor selection so that we can evaluate
      // which formatting 'marks' are currently active.
      let nextLeafNode: IteratorResult<NodeEntry<PrompterText>, void>;
      do {
        nextLeafNode = nodesMatchedWithFormat.next();
        if (!nextLeafNode.done) { // Using Type discrimination to make sure we have a value: https://github.com/microsoft/TypeScript/issues/33353
          const [node, nodePath] = nextLeafNode.value;
          // console.log(`Selection Node [${nodePath}]: `, node);

          selectionActiveMarks.bold = selectionActiveMarks.bold || node.bold;
          selectionActiveMarks.italic = selectionActiveMarks.italic || node.italic;
          selectionActiveMarks.underline = selectionActiveMarks.underline || node.underline;
          selectionActiveMarks.strike = selectionActiveMarks.strike || node.strike;
          selectionActiveMarks.highlight = selectionActiveMarks.highlight || node.highlight;
          selectionActiveMarks.code = selectionActiveMarks.code || node.code;
        }
      } while (!nextLeafNode.done);
    }
    usePrompterSession.getState().setEditorSelectionState({
      selectionCollapsed: editor.selection != null && Range.isCollapsed(editor.selection),
      selectionExpanded: editor.selection != null && Range.isExpanded(editor.selection),
      selectionActiveMarks,
    });

    //
    // Filter the list of changes SlateJS is giving us, removing any operation that is in our
    // pendingRemoteOperations list (operations received from remote peers).
    //
    const currentPendingRemoteOperations = pendingRemoteOperations.current;
    const sendOperations = editor.operations.filter(operation => {
      const nextPendingOp = currentPendingRemoteOperations.at(0);

      // If this operation resulted from a remote peer editor
      if(nextPendingOp && isEqual(operation, nextPendingOp)) {
        // Remove the op from queue...
        currentPendingRemoteOperations.shift();
        // And don't include this operation in the list of operations we might want to send to
        // other peers.
        return false;
      }

      // If we get here, the operation originated locally and we do want to send it to other peers.
      return true;
    });

    //
    // After filtering out any operations that were received from remote peers, we can check if
    // we have any locally generated editor operations to dispatch to connected peers.
    //
    // NOTE: Don't send editor operations if the editor is currently normalizing the script.
    //
    if(sendOperations.length && !loadScriptInProgress.current) {
      // Send our local changes to connected peers.
      const editMessages = new EditorOperationsMessage(sendOperations);
      appController.dispatchMessage(editMessages);
    }

    // Whether or not the operations originated locally or remotely, we'll want to recalculate our
    // text metrics after the changes. This calculate is throttled so it doesn't happen too
    // frequently when numerous changes are happening (ie: typing, each character is a change).
    if(isAstChange) {
      onEditorChangeCallback(doc, loadScriptInProgress.current);
    }
  }, [editor, onEditorChangeCallback]);

  useMessageHandler('editor.operations', (e) => {
    const { message, originatedRemotely } = e;
    const { sender } = message;
    const senderIsRemote = sender?.role === EndpointRole.Remote;
    const prompterSession = usePrompterSession.getState();

    //
    // Do the editor operations in this message consist of real content changes?
    // (something in more than only a 'set_selection' operation)
    //
    const receivedOperations = message.operations as BaseOperation[];
    const isAstChange = receivedOperations.some(
      op => 'set_selection' !== op.type
    );

    e.sendToPeers = !originatedRemotely;

    //
    // If we receive slate editor operations from a remote peer, that peer is currently acting as
    // the prompter leader.
    //
    // We will not change the current leader in response to only a change in selection.
    // However, we will change the leader for a change in content.
    //
    const isLeader = isAstChange
      ? e.checkIAmLeader(prompterSession)
      : prompterSession.isLeader;

    if(originatedRemotely) {
      ReactEditor.blur(editor);

      const currentPendingRemoteOperations = pendingRemoteOperations.current;
      Editor.withoutNormalizing(editor, () => {
        receivedOperations.map((operation) => {
          currentPendingRemoteOperations.push(operation);
          editor.apply(operation);
        });
      });
    }

    if(
      originatedRemotely
      && !isLeader
      && !senderIsRemote
    ) {
      //
      // Apply the remote prompter script position to the local prompter.
      //
      e.syncScrollPosition();
    }
  });

  /**
   * Replace the our scriptNodes (our entire script).
   *
   * This may be as a result of starting a new script, loading a script file, or synchronizing
   * with a peer prompter.
   *
   * The promise should not resolve before the DOM has been updated and script elements re-rendered
   * and measured. We use the script element measurements to calculate the correct scroll position
   * and scroll speed when the script is being loaded from a peer prompter.
   */
  const replaceScript = useCallback((slateNodes: Descendant[], lastChangedTimestamp: number) => {
    if(!slateNodes) {
      throw new Error('replaceScript() missing slateNodes');
    }

    // console.log('loadScriptInProgress.current = TRUE');
    loadScriptInProgress.current = true;

    // It's not great to modify the editor contents while we have an active selection/editing.
    Transforms.deselect(editor);

    /*
    * Clear the Undo/Redo history when loading a new document!
    * https://joshtronic.com/2020/04/11/resetting-the-undo-redo-history-of-a-slatejs-editor/
    * Note: This would be necessary if I recreate the entire editor on document change instead of
    * just chaging the children in the editor instance.
    *
    editor.history = {
      redos: [],
      undos: [],
    };
    */

    const { setScriptNodes } = usePrompterSession.getState();
    setScriptNodes(slateNodes, lastChangedTimestamp);
    editor.children = slateNodes;

    //
    // When normalizing is complete, the SlateJS Editor will fire the onChange event with the
    // normalized scriptNodes.
    Editor.normalize(editor, {force: true});

    //
    // Provide an opportunity for work to be done after the editor has been provided the new script
    // but before the browser DOM has been re-rendered. This is an opportunity to update any data
    // that may affect how the editor is rendered, such as recalculating text metrics.
    onReplaceScriptBeforeRender(slateNodes);

    //
    // Changing the editable key will force React to re-render a new instance of Slate's Editable
    // component.
    setEditableKey(Math.ceil(Math.random() * Number.MAX_SAFE_INTEGER));

    //
    // Very short setTimeout() basically means "do this when the main thread is idle" which will
    // happen after the Slate editor has finished document normalization - if there was any.
    window.setTimeout(() => {
      // console.log('loadScriptInProgress.current = FALSE');
      loadScriptInProgress.current = false;
      //
      // Provide an opportunity for work to be done after the editor has been provided a new script
      // and also finished re-rendering the browser DOM. This is an opportunity to update any data
      // that requires the DOM is up to date with the script nodes such as measuring the DOM
      // elements and then recalculating their relative positions in the prompter.
      onReplaceScriptAfterRender(slateNodes);
    }, 0);
  }, [editor, setEditableKey]);

  //
  //
  //
  useMessageHandler('script.request', (e) => {
    const { originatedRemotely, message } = e;
    if(!originatedRemotely) {
      e.sendToPeers = true;
      // console.log('Sending script.request message');
      return;
    }

    const { sender } = message;
    if(!sender) {
      return;
    }

    const senderPrompter = appController.deviceHost.getDevice(sender.id) as PrompterPeerInstance;
    if(!senderPrompter) {
      return;
    }

    // console.log(`Received script.request message from ${e.message.sender?.id}, preparing script.state response...`);

    // Respond to the requestor with our current script.
    const { scriptNodes, lastScriptChangeTimestamp, hasUnsavedChanges } = usePrompterSession.getState();
    const scriptStateMessage = new ScriptStateMessage({
      scriptNodes,
      lastChangedTimestamp: lastScriptChangeTimestamp,
      hasUnsavedChanges,
      applyOnSender: false,
    });

    // appController.dispatchMessage(scriptStateMessage, e.message.sender?.id);
    senderPrompter.sendMessage(scriptStateMessage);
  });

  //
  // New replacement for old 'loadscript' message.
  //
  useMessageHandler('script.state', (e) => {
    const { message, originatedRemotely } = e;
    const { sender, applyOnSender, scriptNodes, lastChangedTimestamp, hasUnsavedChanges } = message;
    const senderIsRemote = sender?.role === EndpointRole.Remote;

    // if(sender) {
    //   const senderPrompter = appController.deviceHost.getDevice(sender.id) as PrompterPeerInstance;
    //   console.log(`Received 'script.state' message from Peer #${senderPrompter?.peerNumber} (originatedRemotely = ${originatedRemotely})`, message);
    // }

    //
    // If this message did not originate remotely and wasn't prepared to be applied locally, then
    // let's skip processing and flag the message to be sent to other connected peers.
    //
    if(!originatedRemotely && !applyOnSender) {
      //
      // We will only ever send the script to peers when the message did not originate remotely (we
      // created or opened a new script on this prompter) and when we have applyOnSender=false.
      //
      // When we open a script locally, we first apply it locally, allow the script to be
      // normalized by SlateJS and only then we will transmit the new script to connected peers.
      //
      e.sendToPeers = true;
      return;
    }

    if(!sender) {
      throw new Error('Handling script.state messages requires sender');
    }

    const slateNodes = scriptNodes as Descendant[];
    if(!slateNodes) {
      throw new Error('script.state message missing scriptNodes');
    }

    //
    // If the remote peer has never changed their script (lastChangedTimestamp = undefined), then
    // we don't want their script. Likewise if we have both changed our scripts, but the remote
    // peer did so before we did, then our script is more recent and we don't want the remote
    // script.
    //
    const localLastChangedTimestamp = usePrompterSession.getState().lastScriptChangeTimestamp;
    if(!lastChangedTimestamp || (localLastChangedTimestamp && lastChangedTimestamp < localLastChangedTimestamp)) {
      const senderPeer = appController.deviceHost.getDevice(sender.id) as PrompterPeerInstance;

      // The incoming script is not fresher than our local script.
      console.warn(`Ignoring STALE 'script.state' message from ${originatedRemotely ? 'REMOTE' : 'LOCAL'} Peer #${senderPeer.peerNumber} - Our lastScriptChangeTimestamp is more recent than the remote peer's lastChangedTimestamp`);
      return;
    }

    // console.log(`Received 'script.state' message from ${originatedRemotely ? 'REMOTE' : 'LOCAL'} Peer #${senderPeer.peerNumber}`, slateNodes);

    viewportFuncs.queueSequentialTask(async () => {
      const prompterState = usePrompterSession.getState();

      //
      // Is the proposed new script the same as our current script?
      //
      const newScriptSamesAsExisting = isEqual(slateNodes, prompterState.scriptNodes);
      // if(newScriptSamesAsExisting) {
      //   // The new script is the same as the old script.
      //   console.warn('replaceScript(): The new script is the same as the old script. Return early.');
      // }
      if(!newScriptSamesAsExisting) {
        //
        // Replace the contents of our script in the editor.
        //
        // When we update our script copy from a remote peer, we will back-date the timestamp a
        // 100ms so that the remote peer providing the script right now is still mathematically the
        // most recent source of the script.
        //
        replaceScript(slateNodes, lastChangedTimestamp - (originatedRemotely ? 100 : 0));
      }

      //
      // Make sure our current save state is accurately reflected.
      //
      if(prompterState.hasUnsavedChanges !== (hasUnsavedChanges === true)) {
        prompterState.setHasUnsavedChanges(hasUnsavedChanges === true);
      }

      // If the new script state did not originate remotely, it means we loaded a new script
      // locally in this prompter and need to send it to any connected peers.
      //
      // We want to wait to send the new script to connected peers until after Slate has normalized
      // the script as the JSON scriptNodes may have been adjusted slightly during normalization.
      if(!originatedRemotely) {
        // Send normalized script to remote peers - but don't reapply the script locally on this
        // sender.
        const { scriptNodes, lastScriptChangeTimestamp, hasUnsavedChanges } = usePrompterSession.getState();
        const scriptStateMessage = new ScriptStateMessage({
          scriptNodes,
          lastChangedTimestamp: lastScriptChangeTimestamp,
          hasUnsavedChanges,
          applyOnSender: false,
        });
        appController.dispatchMessage(scriptStateMessage);
      }

      //
      // Inform our scriptCollector that we received the current script.
      //
      if(originatedRemotely) {
        appController.deviceHost.scriptCollector.receivedScriptFromPeer(sender.id);

        // Our script has been loaded from a connected peer, let's align our scroll speed with that peer.
        e.syncScrollSpeed();

        // Our script has been loaded from a connected peer, let's align our scroll position with that peer.
        e.syncScrollPosition();

        //
        // Make sure we are in the same play/pause/blanking state as the script leader we got the
        // script from.
        //
        const localPrompterSession = usePrompterSession.getState();
        if(sender.mode === 'playing' && !localPrompterSession.isPlaying) {
          if(localPrompterSession.isBlanking) {
            e.dispatchMessage('prompter.content.show');
          }
          localPrompterSession.play();
        }
        if(sender.mode === 'paused' && !localPrompterSession.isPaused) {
          localPrompterSession.pause();
        }
      } // END IF(originatedRemotely)
    });
  });

  const unsubscribeMethodRef = useRef<() => void>();
  React.useEffect(() => {

    unsubscribeMethodRef.current = usePrompterSession.subscribe((state) => {
      const pendingPromiseResolver = promiseRef.current;
      if(!pendingPromiseResolver) {
        return;
      }

      if(state.scriptNodesMeta?.nodesMeta.length !== state.scriptNodes.length)
      {
        // We are still waiting for some asyncronous things to finish recalculating the new script
        // node positions and states.
        return;
      }

      pendingPromiseResolver();
      promiseRef.current = null;

      // Unsubscribe from usePrompterSession updates if we don't need to be monitoring them anymore!
      const unsubscribeMethod = unsubscribeMethodRef.current;
      if(unsubscribeMethod) {
        unsubscribeMethod();
        unsubscribeMethodRef.current = undefined;
      }

      // Mark the session state as clean.
      usePrompterSession.getState().unsetHasUnsavedChanges();
    });

    // Unsubscribe will be executed if this component unmounts.
    return () => {
      const unsubscribeMethod = unsubscribeMethodRef.current;
      if(unsubscribeMethod) {
        unsubscribeMethod();
        unsubscribeMethodRef.current = undefined;
      }
    };
  }, [editableKey]);

  return {
    editableKey,
    onEditorChange,
  };
};

export default useEditorLoadScriptCommandHandler;