interface ISampleData {
  time: number,
  value: number,
}

interface IJitterAccumulator {
  previousSample: number;
  totalVariance: number;
  sampleCount: number;
}

interface CalculatedStatistics {
  count: number;
  sum: number;
  avg: number;
  jitter: number;
}

class StatsCollector {
  private readonly configMs: number;

  private sampleData: ISampleData[] = [];

  constructor(collectionPeriod?: number) {
    this.configMs = collectionPeriod || 1000;

    this.sample = this._sample.bind(this);
  }

  private _sample(value: number): CalculatedStatistics {
    const time = performance.now();
    const cutoff = time - this.configMs;

    // Filter out old stat samples.
    const newSamples = this.sampleData.filter((entry) => { return entry.time > cutoff; });
    // Push new stat sample.
    newSamples.push({ time, value });
    this.sampleData = newSamples;

    // Sum all values collected in the last second.
    const count = newSamples.length;
    const sum = newSamples.reduce((partialSum, a) => partialSum + a.value, 0);
    const avg = (sum / newSamples.length) || 0;


    const jitterAccumulator = newSamples.reduce<IJitterAccumulator>((prev, sample) => {
      const {totalVariance, sampleCount} = prev;
      const sampleDelta = Math.abs(sample.value - prev.previousSample);
      return {
        previousSample: sample.value,
        totalVariance: totalVariance + sampleDelta,
        sampleCount: sampleCount + 1,
      };
    }, {
      previousSample: newSamples[0].value,
      totalVariance: 0,
      sampleCount: 0,
    });

    const { totalVariance, sampleCount } = jitterAccumulator;
    const jitter = totalVariance / sampleCount;

    return { count, sum, avg, jitter };
  }
  public sample: (value: number) => CalculatedStatistics;
}

export default StatsCollector;