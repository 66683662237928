import React, { useCallback, useState } from 'react';
import PlayArrow from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

import useConfigurationStore from '../../../state/ConfigurationStore';

import throttle from 'lodash/throttle';
import classNames from 'classnames';

interface CuePositionFocusAreaProps {
  viewportTopOffset: number;
  viewportHeight: number;
  viewportBottomOffset: number;
  isPlaying: boolean;
  isPaused: boolean;
  isEditing: boolean;
  topPosition: string;
  bottomPosition: string;
  contentWidth: number;
  leftGutter: number;
  flipHorizontal: boolean;
  flipVertical: boolean;
  textSize: number;
}

const CuePositionFocusArea = (props: CuePositionFocusAreaProps) => {
  const {
    viewportTopOffset,
    viewportHeight,
    viewportBottomOffset,
    isPlaying,
    isPaused,
    isEditing,
    topPosition,
    bottomPosition,
    contentWidth,
    leftGutter,
    flipHorizontal,
    flipVertical,
    textSize,
  } = props;

  const [dragCueActive, setDragCueActive] = useState(false);

  const onCuePointerDown = useCallback((e: React.PointerEvent<HTMLDivElement>) => {
    e.preventDefault();

    //
    // We don't want the cursor hide timeout to elapse as long as we are actively dragging the
    // resize handle.
    //
    // appController.dispatchMessage('prompter.local.HideCursorTimer.Restart');

    e.currentTarget.setPointerCapture(e.pointerId);
    setDragCueActive(true);
  }, []);

  const onCuePointerUp = useCallback((e: React.PointerEvent<HTMLDivElement>) => {
    if(!dragCueActive) {
      return;
    }

    e.preventDefault();
    e.currentTarget.releasePointerCapture(e.pointerId);
    setDragCueActive(false);
  }, [dragCueActive]);

  const onCuePointerMove = React.useCallback(throttle((e: React.PointerEvent<HTMLDivElement>) => {
    if(!dragCueActive) {
      return;
    }

    const viewportTopOffsetPx = viewportTopOffset * window.innerHeight / 100;
    const viewportHeightPx = viewportHeight * window.innerHeight / 100;

    let relativeTop = e.clientY - viewportTopOffsetPx;
    if(relativeTop > viewportHeightPx) { relativeTop = viewportHeightPx; }

    useConfigurationStore.getState().setCuePositionPercentage(
      Math.round(
        (flipVertical ? (viewportHeightPx - relativeTop) : relativeTop)
          * 100 / viewportHeightPx
      )
    );
  }, 1000/60, {
    leading: true,
    trailing: true,
  }), [dragCueActive, flipVertical]);

  return (<div
    className={classNames('PrompterMaskFocus', { dragCueActive })}
    style={{
      top: `${topPosition}`,
      bottom: `${bottomPosition}`,
      width: contentWidth + '%',
      marginLeft: leftGutter + '%',
      /*border: isPlaying ? '1px solid rgba(66, 151, 215, .4)' : '0 none'*/
      // border: '2px solid rgba(66, 151, 215, .6)',
      /*opacity: isPlaying ? '1' : '0',*/
    }}
  >
    {dragCueActive && <div style={{
      position: 'absolute',
      top: '50%', left: 0, right: 0,
      transform: 'translateY(-50%)',
      borderBottom: '2px solid #4297d7',
    }}></div>}

    {!flipHorizontal && <div
      className="PrompterMaskArrowLeft"
      onPointerDown={onCuePointerDown}
      onPointerUp={onCuePointerUp}
      onPointerMove={onCuePointerMove}
    >
      {!isPaused &&
      <PlayArrow
        viewBox={'9 5 13 14'}
        sx={{
          fontSize: `${textSize}pt`,
          // color: isPlaying ? '#4297d7' : '#4A4A4A',
        }}
      />}
      {isPaused &&
      <PauseIcon
        viewBox={'6 7 16 10'}
        sx={{
          fontSize: `${textSize}pt`,
        }}
      />}
    </div>}

    {flipHorizontal && <div
      className="PrompterMaskArrowRight"
      onPointerDown={onCuePointerDown}
      onPointerUp={onCuePointerUp}
      onPointerMove={onCuePointerMove}
    >
      {!isPaused &&
      <PlayArrow
        viewBox={'9 5 13 14'}
        sx={{
          fontSize: `${textSize}pt`,
          // color: isPlaying ? '#4297d7' : '#4A4A4A',
        }}
      />}
      {isPaused &&
      <PauseIcon
        viewBox={'6 7 16 10'}
        sx={{
          fontSize: `${textSize}pt`,
        }}
      />}
    </div>}
  </div>);
};

export default CuePositionFocusArea;