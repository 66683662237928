import React, { useState, useEffect, useRef, useCallback } from 'react';
import Menu, { MenuProps } from '@mui/material/Menu';

/**
 * How long (in milliseconds) does the user need to touch and hold to show the context menu.
 */
const LONG_TOUCH_THRESHOLD = 800;

interface useContextMenuStateExports {
  contextMenuProps: MenuProps;
  showContextMenuAtAnchor: (anchorElement: HTMLElement) => void;
  closeContextMenu: () => void;
  cancelTouchGestures: () => void;
}

// interface useContextMenuStateProps {
//   mediaPanelRef: React.RefObject<HTMLDivElement>
// }

const useContextMenuState = (eventTarget: React.RefObject<HTMLDivElement>): useContextMenuStateExports => {

  const [contextMenuPosition, setContextMenuPosition] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const [positionMenuAnchorEl, setPositionMenuAnchorEl] = useState<HTMLElement | null>(null);

  const handleContextMenuEvent = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    //
    // Clear out any prior element anchor based menu position
    setPositionMenuAnchorEl(null);

    //
    // Save the location on the page we want our context menu to show
    setContextMenuPosition(
      contextMenuPosition === null
        ? {
          mouseX: e.clientX,
          mouseY: e.clientY,
        }
        // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
        // Other native context menus might behave different.
        // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
        : null
    );
  };
  const showContextMenuAtAnchor = (anchorElement: HTMLElement) => {
    // Clear out any prior co-ordinate based menu position
    setContextMenuPosition(null);

    // Set an HTML element next to which we want to show the context menu
    setPositionMenuAnchorEl(anchorElement);
  };
  const doContextMenuClose = () => {
    setContextMenuPosition(null);
    setPositionMenuAnchorEl(null);
  };

  const contextMenuProps: MenuProps = {
    open: contextMenuPosition !== null || positionMenuAnchorEl !== null,
    onClose: doContextMenuClose,
  };
  if(contextMenuPosition) {
    contextMenuProps.anchorReference = 'anchorPosition';
    contextMenuProps.anchorPosition = { top: contextMenuPosition.mouseY, left: contextMenuPosition.mouseX };
  }
  if(positionMenuAnchorEl) {
    contextMenuProps.anchorEl = positionMenuAnchorEl;
    contextMenuProps.anchorOrigin = {
      vertical: 'top',
      horizontal: 'left',
    };
    contextMenuProps.transformOrigin = {
      vertical: 'top',
      horizontal: 'right',
    };
  }

  const longTouchTimeout = useRef<number>(0);
  const handleTouchStart = (e: TouchEvent) => {
    // Cancel long-press detection if we receive multiple touches - the user may be starting a
    // pinch-zoom gesture or two-finger-drag gesture, etc.
    if(e.touches.length > 1) {
      longTouchTimeout.current && clearTimeout(longTouchTimeout.current);
      return;
    }
    if(e.defaultPrevented) {
      // If some other event handler has already handled touch start, then we don't want to detect
      // a potential context menu. ex: MediaPanel drag handle for resizing
      return;
    }

    //
    // Save the location on the page we want our context menu to show
    const touchData = e.touches[0];
    const touchStartPosition = {
      mouseX: touchData.clientX,
      mouseY: touchData.clientY,
    };

    // If we get here, we are starting a new single point touch. If the user does not release the
    // touch within the timeout period, we will trigger the context menu.
    longTouchTimeout.current = window.setTimeout(() => {
      setContextMenuPosition(touchStartPosition);
    }, LONG_TOUCH_THRESHOLD);
  };
  const handleTouchEnd = (e: TouchEvent) => {
    longTouchTimeout.current && clearTimeout(longTouchTimeout.current);
  };
  const cancelTouchGestures = () => {
    longTouchTimeout.current && clearTimeout(longTouchTimeout.current);
  };

  //
  // Let's add a context menu to the Media Panel
  //
  useEffect(() => {
    const eventTargetEl = eventTarget.current;
    if(!eventTargetEl) {
      return;
    }

    // eventTargetEl.addEventListener('click', handleAuxClick);
    eventTargetEl.addEventListener('contextmenu', handleContextMenuEvent);

    eventTargetEl.addEventListener('touchstart', handleTouchStart);
    eventTargetEl.addEventListener('touchend', handleTouchEnd);

    // Clean-up
    return () => {
      // eventTargetEl.removeEventListener('click', handleAuxClick);
      eventTargetEl.removeEventListener('contextmenu', handleContextMenuEvent);

      eventTargetEl.removeEventListener('touchstart', handleTouchStart);
      eventTargetEl.removeEventListener('touchend', handleTouchEnd);
    };
  }, [eventTarget, handleContextMenuEvent]);

  return {
    contextMenuProps,
    showContextMenuAtAnchor,
    closeContextMenu: doContextMenuClose,
    cancelTouchGestures,
  };
};

export default useContextMenuState;