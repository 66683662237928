import React, { useState, useEffect, useRef, useCallback } from 'react';
import VideoLabelBar from './VideoLabelBar';
import VideoActionsToolbar from './VideoActionsToolbar';

import { useConfirm } from 'material-ui-confirm';

interface Dimensions {
  width: number;
  height: number;
}

interface VideoPanelProps {
  mediaStream: MediaStream;
  hideToolbar: boolean;
}
const VideoPanel = (props: VideoPanelProps) => {
  const { mediaStream, hideToolbar } = props;

  const confirm = useConfirm();

  const videoRef = React.createRef<HTMLVideoElement>();
  const [mediaDimensions, setMediaDimensions] = useState<Dimensions>({
    width: 16,
    height: 9,
  });

  const [isRecording, setIsRecording] = useState(false);

  //
  // When our props.mediaStream changes, we want to assign the stream to our HTML5 Video element
  // and attach event listeners for events like 'loadedmetadata' which fires after receiving the
  // first video frame (and we know the video dimensions).
  //
  useEffect(() => {
    const videoEl = videoRef.current;
    if(!videoEl) {
      // console.warn('setVideoTrack() videoEl was undefined');
      return;
    }

    if(mediaStream === undefined) {
      videoEl.srcObject = null;
      // console.warn('setVideoTrack() mediaStream was undefined');
      return;
    }

    //
    // Shen you share a desktop window, and stop sharing via a native browser control, the
    // video track is "ended".
    //
    /*
    const videoTracks = mediaStream.getVideoTracks();
    if(videoTracks && videoTracks.length) {
      const videoTrack = videoTracks[0];

      //
      // Discover native media dimensions (is this a 720p, 1080p or 4k webcam?)
      //
      // const capabilities = videoTrack.getCapabilities();
      const videoTrackSettings = videoTrack.getSettings();
      setMediaTrackSettings(videoTrackSettings);
      //videoTrackSettings.deviceId

      // This is only available when the video track is originating locally.
      if(videoTrackSettings.width && videoTrackSettings.height) {
        console.log(`VideoTrack Capabilities - Width: ${videoTrackSettings.width}, Height: ${videoTrackSettings.height}`, videoTrackSettings);

        setBackgroundDimensions({
          width: videoTrackSettings.width,
          height: videoTrackSettings.height,
        });
      }
    }
    */

    //
    // Can we attach an audio analyser so we can display audio levels on screen and detect a muted
    // or failed microphone?
    //
    // Docs: https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Using_Web_Audio_API
    //
    /*
    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    analyser.minDecibels = -90;
    analyser.maxDecibels = -10;
    analyser.smoothingTimeConstant = 0.85;

    // Connect our audio nodes in a chain from source input to destination.
    const audioInput = audioContext.createMediaStreamSource(mediaStream);
    audioInput.connect(analyser);
    analyser.connect(audioContext.destination);
    */

    // Changing the source of video to current stream.
    videoEl.srcObject = mediaStream;
    const handleloadedmetadata = async () => {
      // console.log(`MediaStream loadedmetadata w: ${videoEl.videoWidth}, h: ${videoEl.videoHeight}`);

      // The videoWidth/Height is not known until after we begin receiving the remote stream.
      if(videoEl.videoWidth && videoEl.videoHeight) {
        setMediaDimensions({
          width: videoEl.videoWidth,
          height: videoEl.videoHeight,
        });
      }

      //
      // We will retry the play method twice. If the first attempt to play fails, we will try to
      // get user consent to play with a click.
      //
      for(let i = 0; i < 2; i++) {
        if(i > 0) {
          // This is a retry. Do we need the user to interact with the page?
          // If the user has not yet interacted with the document, let's get them to now.
          await confirm({
            // title: <><WarningIcon sx={{ mr: 1, verticalAlign: 'middle', color: '#eed202' }} />Another Prompter is sharing a background.</>,
            title: 'Another prompter is sharing media.',
            titleProps: {
              //
            },
            description: 'Do you want to accept the incoming media stream?',
            confirmationText: 'Yes',
            confirmationButtonProps: {
              variant: 'contained',
              size: 'small',
              color: 'success',
            },
            cancellationText: 'No',
            cancellationButtonProps: {
              variant: 'contained',
              size: 'small',
              color: 'error',
            }
          });
        }

        try {
          await videoEl.play();

          break;
        } catch (err) {
          // This may fail because the user has never interacted with the page.
          //
          // DOMException: play() failed because the user didn't interact with the document first.
          // https://goo.gl/xX8pDD
          //
          // Let's ask the user to interact now!
          console.log('video play error', err);
        }
      } // END for() play attempts
    };

    const videoElAbort = () => {
      // The UserAgent terminated our access to the media.
      // The user may have stopped video via a browser native control.
      //
      // This event appears to be sent from Firefox for Webcam sharing.
      // Chrome gets this when we clear background on Chrome previously sending webcam from Chrome.
      console.log('MediaStream abort');
    };

    const videoElEnded = () => {
      // The UserAgent terminated our access to the media.
      // The user may have stopped video via a browser native control.
      //
      // This event appears to be sent from Firefox for desktop/screen sharing.
      console.log('MediaStream ended');
    };

    videoEl.addEventListener('loadedmetadata', handleloadedmetadata);
    videoEl.addEventListener('abort', videoElAbort);
    videoEl.addEventListener('ended', videoElEnded);

    return () => {
      // clean-up, remove event listeners on videoEl
      videoEl.removeEventListener('loadedmetadata', handleloadedmetadata);
      videoEl.removeEventListener('abort', videoElAbort);
      videoEl.removeEventListener('ended', videoElEnded);
    };
  }, [mediaStream]);

  return (<div
    className='VideoPanel'
    style={{
      aspectRatio: `${mediaDimensions.width} / ${mediaDimensions.height}`,
    }}
  >
    <video
      disablePictureInPicture={true}
      playsInline={true}
      muted={true}
      ref={videoRef}
      // style={{
      //   width: fitWidth ? '100%' : 'auto',
      //   height: fitWidth ? 'auto' : '100%',
      //   transform: contentTransform,
      // }}
    ></video>
    {isRecording && <div className='recording-badge'>
      <div className='recording-indicator'></div>
      REC
    </div>}

    {/* Lower Left Video Label */}
    <VideoLabelBar mediaStream={mediaStream} hideToolbar={hideToolbar} />

    {/* Bottom Center Toolbar - start/top video recording */}
    {/* <VideoActionsToolbar mediaStream={mediaStream} hideToolbar={hideToolbar} /> */}
  </div>);
};

export default VideoPanel;