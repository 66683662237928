import React, { useLayoutEffect } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import { ITrayItem } from './TrayContainer';
import TrayItemIconMenuItem from './TrayItemIconMenuItem';
import TrayItemIconButton from './TrayItemIconButton';

import Check from '@mui/icons-material/Check';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import LockIcon from '@mui/icons-material/Lock';

import { useConfirm } from 'material-ui-confirm';
import { Trans, useTranslation } from 'react-i18next';
import { useAppController } from '../../controllers/AppController';

import useApplicationContext from '../../hooks/useApplicationContext';
import useConfigurationStore, { MediaScalingMode, MediaPanelPosition } from '../../state/ConfigurationStore';
import usePrompterSession, { MediaSourceType } from '../../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';

interface MediaTrayItemProps {
  renderAsMenuItem?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement> | undefined;
}

const MediaTrayItem = (props: MediaTrayItemProps) => {

  const { renderAsMenuItem } = props;

  const {
    appContext,
  } = useApplicationContext();
  const { isAuthenticated, userProfile } = appContext;

  //
  // Feature detection. Sharing a display is not supported on iPad for example.
  //
  const webcamSharingAvailable = ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices);
  const displaySharingAvailable = ('mediaDevices' in navigator && 'getDisplayMedia' in navigator.mediaDevices);

  const { t } = useTranslation('prompter');

  const appController = useAppController();

  const confirm = useConfirm();

  const configStore = useConfigurationStore(state => ({
    flipHorizontal: state.flipHorizontal,
    flipVertical: state.flipVertical,

    mediaPanelPosition: state.mediaPanelPosition,
    setMediaPanelPosition: state.setMediaPanelPosition,
    media1ScalingMode: state.media1ScalingMode,
  }), shallow);

  const mediaSourceType = usePrompterSession(state => state.mediaSourceType);

  // useState will hold reference to Websocket Menu anchor element.
  const [trayMenuAnchorEl, setTrayMenuAnchorEl] = React.useState<HTMLElement | null>(null);
  const onTrayMenuClick = (e: React.MouseEvent<HTMLElement>) => {
    if(props.onClick) { props.onClick(e); }
    setTrayMenuAnchorEl(e.currentTarget);
  };
  const onTrayMenuClose = () => {
    setTrayMenuAnchorEl(null);
  };

  useLayoutEffect(() => {
    // When renderAsMenuItem changes, we need to close any open menus.
    onTrayMenuClose();
  }, [renderAsMenuItem]);

  const trayItemProps: ITrayItem = {
    title: t('trayicons.mediasharing.title'),
    icon: (<WallpaperIcon />),
    onClick: onTrayMenuClick,
  };

  const isLocalBackgroundSource = (mediaSourceType === MediaSourceType.Webcam) || (mediaSourceType === MediaSourceType.Desktop);
  const isRemoteBackgroundSource = (mediaSourceType === MediaSourceType.Remote);
  return (<>
    {renderAsMenuItem ? (<TrayItemIconMenuItem {...trayItemProps} />) : (<TrayItemIconButton {...trayItemProps} />)}
    <Menu
      anchorEl={trayMenuAnchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(trayMenuAnchorEl)}
      onClose={onTrayMenuClose}
    >
      <MenuItem
        onClick={
          async () => {
            // alert(t('trayicons.mediasharing.beta_moreinfo'));

            await confirm({
              title: <><AutoAwesomeIcon
                color='warning'
                sx={{ mr: 1, verticalAlign: 'middle' }}
              />{t('trayicons.mediasharing.beta_primary')}</>,
              description: (<Trans>{t('trayicons.mediasharing.beta_moreinfo')}</Trans>),
              confirmationText: t('trayicons.mediasharing.beta_moreinfo_confirm'),
              confirmationButtonProps: {
                variant: 'contained',
                size: 'small',
                color: 'success',
                autoFocus: true,
              },
              hideCancelButton: true,
            });
          }
        }
        sx={{
          alignItems: 'flex-start',
          maxWidth: '360px',
          whiteSpace: 'normal',
        }}
      >
        <ListItemText
          primary={<><AutoAwesomeIcon
            color='warning'
          />{t('trayicons.mediasharing.beta_primary')}
          </>}
          primaryTypographyProps={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: 1,
          }}
          secondary={t('trayicons.mediasharing.beta_secondary')}
          // secondary={<><p>{t('trayicons.mediasharing.beta_secondary')}</p><LockIcon sx={{ fontSize: '1em', verticalAlign: 'middle' }} />&nbsp;<i>{t('plans.requiresPlanLevel0', { ns: 'common' })}</i></>}
        />
      </MenuItem>
      <Divider />
      <ListItem
        sx={{
          minWidth: 240,
          // textTransform: 'uppercase',
        }}
      >{t('trayicons.mediasharing.sources_title')}</ListItem>
      <MenuItem
        onClick={
          () => {
            setTrayMenuAnchorEl(null);
            appController.dispatchMessage('prompter.background.clear');
          }
        }
      >
        {(mediaSourceType === MediaSourceType.None) && <ListItemIcon>
          <Check />
        </ListItemIcon>}
        <ListItemText inset={mediaSourceType !== MediaSourceType.None}>{t('trayicons.mediasharing.sources_none')}</ListItemText>
      </MenuItem>
      {webcamSharingAvailable && <MenuItem
        onClick={
          () => {
            setTrayMenuAnchorEl(null);

            //
            // You need to be logged in to open a script file - even a free account login.
            //
            appController.dispatchMessage('prompter.local.accountrequired', {
              plan: 'free',
              featureName: t('trayicons.mediasharing.video_sharing_feature_name'),
              callback: async () => {
                appController.dispatchMessage('media.webcam.request');
              },
            });
          }
        }
      >
        {(mediaSourceType === MediaSourceType.Webcam) && <ListItemIcon>
          <Check />
        </ListItemIcon>}
        <ListItemText
          inset={mediaSourceType !== MediaSourceType.Webcam}
          primary={t('trayicons.mediasharing.sources_webcam')}
          secondary={
            isAuthenticated
              ? undefined
              : <><LockIcon sx={{ fontSize: '1em', verticalAlign: 'middle' }} />&nbsp;{t('plans.requiresPlanLevel0', { ns: 'common' })}</>
          }
          secondaryTypographyProps={{
            fontStyle: 'italic',
          }}
        />
      </MenuItem>}
      {displaySharingAvailable && <MenuItem
        onClick={
          () => {
            setTrayMenuAnchorEl(null);

            //
            // You need to be logged in to open a script file - even a free account login.
            //
            appController.dispatchMessage('prompter.local.accountrequired', {
              plan: 'free',
              featureName: t('trayicons.mediasharing.screen_sharing_feature_name'),
              callback: async () => {
                appController.dispatchMessage('prompter.background.desktop');
              },
            });
          }
        }
      >
        {(mediaSourceType === MediaSourceType.Desktop) && <ListItemIcon>
          <Check />
        </ListItemIcon>}
        <ListItemText
          inset={mediaSourceType !== MediaSourceType.Desktop}
          primary={t('trayicons.mediasharing.sources_screen')}
          secondary={
            isAuthenticated
              ? undefined
              : <><LockIcon sx={{ fontSize: '1em', verticalAlign: 'middle' }} />&nbsp;{t('plans.requiresPlanLevel0', { ns: 'common' })}</>
          }
          secondaryTypographyProps={{
            fontStyle: 'italic',
          }}
        />
      </MenuItem>}
      <MenuItem
        disabled={!isRemoteBackgroundSource}
        onClick={
          () => {
            setTrayMenuAnchorEl(null);
          }
        }
      >
        {isRemoteBackgroundSource && <ListItemIcon>
          <Check />
        </ListItemIcon>}
        <ListItemText inset={!isRemoteBackgroundSource}>{t('trayicons.mediasharing.sources_remote')}</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem
        disabled={!isLocalBackgroundSource}
        onClick={
          () => {
            setTrayMenuAnchorEl(null);
            appController.dispatchMessage('prompter.background.togglemute');
          }
        }
      >{t('trayicons.mediasharing.toggle_mute')}</MenuItem>
      <Divider />
      <ListItem
        sx={{
          minWidth: 240,
          // textTransform: 'uppercase',
        }}
      >{t('trayicons.mediasharing.position_title')}</ListItem>
      <MenuItem onClick={
        () => {
          setTrayMenuAnchorEl(null);
          // appController.dispatchMessage('prompter.background.cover');
          configStore.setMediaPanelPosition(MediaPanelPosition.Top);
        }
      }>
        {(configStore.mediaPanelPosition === MediaPanelPosition.Top) && <ListItemIcon>
          <Check />
        </ListItemIcon>}
        <ListItemText
          primary={t('trayicons.mediasharing.position_top')}
          // secondary={'Secondary text'}
          inset={configStore.mediaPanelPosition !== MediaPanelPosition.Top}
        />
      </MenuItem>
      <MenuItem onClick={
        () => {
          setTrayMenuAnchorEl(null);
          // appController.dispatchMessage('prompter.background.cover');
          configStore.setMediaPanelPosition(MediaPanelPosition.Bottom);
        }
      }>
        {(configStore.mediaPanelPosition === MediaPanelPosition.Bottom) && <ListItemIcon>
          <Check />
        </ListItemIcon>}
        <ListItemText
          primary={t('trayicons.mediasharing.position_bottom')}
          // secondary={'Secondary text'}
          inset={configStore.mediaPanelPosition !== MediaPanelPosition.Bottom}
        />
      </MenuItem>
      <MenuItem onClick={
        () => {
          setTrayMenuAnchorEl(null);
          // appController.dispatchMessage('prompter.background.cover');
          configStore.setMediaPanelPosition(MediaPanelPosition.Left);
        }
      }>
        {(configStore.mediaPanelPosition === MediaPanelPosition.Left) && <ListItemIcon>
          <Check />
        </ListItemIcon>}
        <ListItemText
          primary={t('trayicons.mediasharing.position_left')}
          // secondary={'Secondary text'}
          inset={configStore.mediaPanelPosition !== MediaPanelPosition.Left}
        />
      </MenuItem>
      <MenuItem onClick={
        () => {
          setTrayMenuAnchorEl(null);
          // appController.dispatchMessage('prompter.background.cover');
          configStore.setMediaPanelPosition(MediaPanelPosition.Right);
        }
      }>
        {(configStore.mediaPanelPosition === MediaPanelPosition.Right) && <ListItemIcon>
          <Check />
        </ListItemIcon>}
        <ListItemText
          primary={t('trayicons.mediasharing.position_right')}
          // secondary={'Secondary text'}
          inset={configStore.mediaPanelPosition !== MediaPanelPosition.Right}
        />
      </MenuItem>
      <MenuItem onClick={
        () => {
          setTrayMenuAnchorEl(null);
          // appController.dispatchMessage('prompter.background.cover');
          configStore.setMediaPanelPosition(MediaPanelPosition.Background);
        }
      }>
        {(configStore.mediaPanelPosition === MediaPanelPosition.Background) && <ListItemIcon>
          <Check />
        </ListItemIcon>}
        <ListItemText
          primary={t('trayicons.mediasharing.position_background')}
          // secondary={'Secondary text'}
          inset={configStore.mediaPanelPosition !== MediaPanelPosition.Background}
        />
      </MenuItem>
      <Divider />
      <ListItem
        sx={{
          minWidth: 240,
          // textTransform: 'uppercase',
        }}
      >{t('trayicons.mediasharing.mediafit_title')}</ListItem>
      <MenuItem onClick={
        () => {
          setTrayMenuAnchorEl(null);
          appController.dispatchMessage('prompter.background.contain');
        }
      }>
        {(configStore.media1ScalingMode === MediaScalingMode.Contain) && <ListItemIcon>
          <Check />
        </ListItemIcon>}
        <ListItemText inset={configStore.media1ScalingMode !== MediaScalingMode.Contain}>{t('trayicons.mediasharing.mediafit_contain')}</ListItemText>
      </MenuItem>
      <MenuItem onClick={
        () => {
          setTrayMenuAnchorEl(null);
          appController.dispatchMessage('prompter.background.cover');
        }
      }>
        {(configStore.media1ScalingMode === MediaScalingMode.Cover) && <ListItemIcon>
          <Check />
        </ListItemIcon>}
        <ListItemText inset={configStore.media1ScalingMode !== MediaScalingMode.Cover}>{t('trayicons.mediasharing.mediafit_cover')}</ListItemText>
      </MenuItem>
    </Menu>
  </>);
};

export default MediaTrayItem;