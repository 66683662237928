import React, { useCallback, useEffect } from 'react';
import { NavigateMessage, PauseMessage, PlayMessage, SetScrollSpeedMessage } from '@fluidprompter/core';

import { useAppController, useMessageHandler } from '../../controllers/AppController';
import useConfigurationStore from '../../state/ConfigurationStore';
import usePrompterSession from '../../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';
import useStatsCollector from '../../hooks/useStatsCollector';
import _ from 'lodash';

const CURSOR_HIDE_TIMEOUT = 2000;
const MOUSE_TRAVEL_REQUIRED_TO_SHOW_CURSOR = 400;
const MOUSE_TRAVEL_REQUIRED_TO_PREVENT_HIDE_CURSOR = 50;

function usePrompterCursorManagement() {
  const prompter = usePrompterSession(state => ({
    isPlaying: state.isPlaying,
    isEditing: state.isEditing,
  }), shallow);

  const cursor = usePrompterSession(state => ({
    cursorHidden: state.cursorHidden,
    setCursorHidden: state.setCursorHidden,
  }), shallow);

  const collectMouseMove = useStatsCollector();

  const appController = useAppController();

  // Reference to hold accumulated scroll amount between animation frames.
  const amountRef = React.useRef<number>(0);

  // Reference to hold setTimeout handle for hide cursor timer.
  const timeoutRef = React.useRef<number>(0);

  const dispatchUpdatesThrottled = React.useCallback(_.throttle(() => {
    const scrollAmount = amountRef.current;

    if(usePrompterSession.getState().isPlaying) {
      const scrollSpeed = useConfigurationStore.getState().scrollSpeed;
      appController.dispatchMessage(new SetScrollSpeedMessage(scrollSpeed + scrollAmount));
    } else {
      appController.dispatchMessage('prompter.scrollby', {
        deltaY: scrollAmount
      });
    }

    amountRef.current = 0;
  }, 1000/60), []);

  /**
   * Function that will set our cursor hidden if it is not already hidden and the prompter is not
   * in edit mode (cursor hiding is disabled in edit mode)
   */
  const hideCursor = useCallback(() => {
    const currentState = usePrompterSession.getState();
    if(!currentState.cursorHidden && !currentState.isEditing) {
      currentState.setCursorHidden(true);

      if(document.activeElement) {
        // Make sure no user inputs are currently focused, stealing keypresses.
        const activeElement = document.activeElement as HTMLElement;
        activeElement.blur();  // THIS WAS CAUSING SLATE EDITOR TO LOSE FOCUS
      }
    }
  }, []);

  /**
   * Function that will clear any current hide cursor timeout and request we hide the cursor
   * immediately.
   */
  const expireHideCursorTimer = useCallback(() => {
    if(timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Immediately hide the cursor.
    hideCursor();
  }, [hideCursor]);
  useMessageHandler('prompter.local.HideCursorTimer.Expire', expireHideCursorTimer);

  /**
   * Function used to request we show the cursor. This function is throttled as it may be called
   * many times within a mouse move event.
   */
  const showCursorThrottled = useCallback(_.throttle(() => {
    if(cursor.cursorHidden) { cursor.setCursorHidden(false); }
    clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(hideCursor, CURSOR_HIDE_TIMEOUT);
  }, 1000), [cursor]);
  useMessageHandler('prompter.local.HideCursorTimer.Restart', showCursorThrottled);

  // After initial rendering of the MouseInputMask, start our 3 second timer
  // to hide the mouse cursor.
  useEffect(() => {
    if(prompter.isEditing) {
      // If we are transition from play/pause -> editing, we want to show the cursor.
      showCursorThrottled();
      return;
    }

    //
    // If we get here we are transition away from editing -> play/pause and want to hide the cursor
    // in the configured hide timeout interval.
    //
    timeoutRef.current = window.setTimeout(hideCursor, CURSOR_HIDE_TIMEOUT);

    return () => { clearTimeout(timeoutRef.current); };
  }, [prompter.isEditing, hideCursor]);

  useEffect(() => {
    //
    // Reset the wheel accumulator when we toggle play/pause.
    //
    amountRef.current = 0;

    //
    // Is we are transitioning from editing/paused to playing, expire the hide cursor timer.
    //
    if(prompter.isPlaying) {
      expireHideCursorTimer();
    }
  }, [prompter.isPlaying, expireHideCursorTimer]);

  const handleMouseMove = useCallback((e: MouseEvent) => {
    const { cursorHidden, isEditing } = usePrompterSession.getState();
    if(!cursorHidden || isEditing) {
      // We only want to intercept the mouse move event when the prompter is not in edit mode.
      return;
    }

    // Calculate the line of sight distance the cursor moved.
    // https://stackoverflow.com/a/20916980
    const distanceMoved = Math.hypot(Math.abs(e.movementX), Math.abs(e.movementY));

    // Use our stats collector hook to calculate mouse movement stats over
    // the last second.
    const distanceOverLastSecond = collectMouseMove(distanceMoved);

    // If the user is moving the mouse around enough we think its intentional,
    // then show the mouse cursor and hidden UI elements again.
    // If the cursor is already visible, the threshhold of movement required
    // to keep it visible is lower than the threshhold required to initial
    // show the cursor.
    if(distanceOverLastSecond.sum > (usePrompterSession.getState().cursorHidden ? MOUSE_TRAVEL_REQUIRED_TO_SHOW_CURSOR : MOUSE_TRAVEL_REQUIRED_TO_PREVENT_HIDE_CURSOR)) {
      showCursorThrottled();
    }
  }, [collectMouseMove, showCursorThrottled]);

  const handleMouseWheel = useCallback((e: WheelEvent) => {
    const { cursorHidden, isPlaying } = usePrompterSession.getState();
    if(!cursorHidden || !isPlaying) {
      // We only want to intercept the mouse wheel when the prompter is actively scrolling
      // (playing).
      return;
    }

    const { mouseControlsEnabled } = useConfigurationStore.getState();
    if(!mouseControlsEnabled) {
      // If Mouse based prompter controls are disabled, then we don't want to interfere with
      // mouse wheel events.
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    // Do something
    // deltaMode: number;
    // deltaX: number;
    // deltaY: number;
    // deltaZ: number;

    // wheel { target: div.MouseInputMask, buttons: 0, clientX: 700, clientY: 220, layerX: 700, layerY: 220 }

    // altKey
    // ctrlKey
    // deltaMode: 0
    // deltaX: 0
    // deltaY: 1
    // deltaZ: 0
    // shiftKey
    // wheelDelta: -3
    // wheelDeltaX: 0
    // wheelDeltaY: -3
    // which: 1
    //

    //
    // If we get here the prompter is playing, and mouse controls are enabled.
    //
    amountRef.current += Math.round(e.deltaY / 2);
    dispatchUpdatesThrottled();
  }, [amountRef]);

  //
  // "PointerEvent" will fire for any type of pointing device whether a tradition mouse or a touch
  // screen. PointerEvent will allow us to differentiate between mouse and touch and allow us to
  // prevent the traditional 'click' event if desired.
  //
  const handlePointerEvent = useCallback((e: PointerEvent) => {
    const { isEditing, isPlaying } = usePrompterSession.getState();

    //
    // We only want to intercept the mouse click event when the prompter is not in edit mode.
    //
    if(isEditing) {
      return;
    }

    // If this "Mouse click" is a result of a touch screen, ignore it. We want to have touch
    // specific behavior defined elsewhere.
    if(e.pointerType === 'touch') { // 'mouse' | 'touch' | 'pen'
      return;
    }

    //
    // We aren't interested in mouse clicks that are not on the prompter content (ie: clicks on
    // a menu or modal dialog should be not be intercepted). We also don't want to intercept clicks
    // on a button or anchor element such as Word Limit Notice CTAs or End Element buttons to
    // restart script.
    //
    if(e.button === 0) {
      //
      // We should never have a null event target for a real (user generated) click event.
      // Synthetic click events could have no event target but won't be considered for prompter
      // control.
      //
      if(!e.target) {
        return;
      }
      const targetHTMLElement = e.target as HTMLElement;

      //
      // Clicks inside of a button or anchor element should not be intercepted.
      // Any buttons or anchors that should not be clicked while prompting should have a css
      // `pointer-events: none` rule added to them while the prompter is not in edit mode.
      //
      const closestButton = targetHTMLElement.closest('button,a,.allow-click-while-prompting');
      if (closestButton !== null) {
        // We clicked a button or anchor element... don't intercept this event.
        return;
      }

      //
      // Mouse clicks outside of the PrompterContent don't need to be intercepted.
      //
      const closestPrompterContent = targetHTMLElement.closest('.PrompterContainer');
      if (
        closestPrompterContent === null
      ) {
        // We clicked outside of .PrompterContent - perhaps a menu or modal dialog.
        // Do not intercept this event.
        return;
      }
    }

    //
    // If we get here, we want to intercept this click event and will interpret it as potential
    // prompter control command if mouse controls are enabled.
    //
    e.preventDefault();
    e.stopPropagation();

    const { mouseControlsEnabled } = useConfigurationStore.getState();
    if(!mouseControlsEnabled) {
      // Mouse based prompter controls are not currently enabled.
      return;
    }

    // For left click on MacBook
    // button: 0
    // buttons: 0
    // which: 1

    // For right click on MacBook
    // button: 2
    // buttons: 2
    // which: 3
    switch(e.button) {
      case 0: { // Left click
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.PageUp));
        break;
      }
      case 1: { // Middle click
        appController.dispatchMessage(isPlaying ? new PauseMessage() : new PlayMessage());
        break;
      }
      case 2: { // Right click
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.PageDown));
        break;
      }
      default:
        break;
    }
  }, [appController]);

  const handleTouchStart = useCallback((e: TouchEvent) => {
    const { isEditing, isPlaying } = usePrompterSession.getState();
    if(isEditing) {
      return;
    }

    //
    // We should never have a null event target for a real (user generated) click event.
    // Synthetic click events could have no event target but won't be considered for prompter
    // control.
    //
    if(!e.target) {
      return;
    }
    const targetHTMLElement = e.target as HTMLElement;

    //
    // Touches inside of a button or anchor element should not be intercepted.
    // Any buttons or anchors that should not be clicked while prompting should have a css
    // `pointer-events: none` rule added to them while the prompter is not in edit mode.
    //
    const closestButton = targetHTMLElement.closest('button,a');
    if (closestButton !== null) {
      // We touched a button or anchor element... don't intercept this event.
      return;
    }

    //
    // Touches outside of the PrompterContent don't need to be intercepted.
    //
    const closestPrompterContent = targetHTMLElement.closest('.PrompterContainer');
    if (
      closestPrompterContent === null
    ) {
      // We touched outside of .PrompterContent - perhaps a menu or modal dialog.
      // Do not intercept this event.
      return;
    }

    //
    // In either play or pause mode, we want to ensure that any touch reshows the hidden UI.
    //
    // The only exception is that we don't want to show the UI whe touching the speed control
    // slider or other basic UI while prompting. This is why we do this here after the above checks
    // for touches on clickable things insie the prompter content or if we are touching outside the
    // promtper content such as on the navbar/controlbar.
    //
    showCursorThrottled();

    //
    // When we are in play mode (not paused or editing) we want to prevent touch based scrolling
    // or accidental UI interaction.
    //
    if(isPlaying) {
      e.preventDefault();
      e.stopPropagation();
    }
  }, [showCursorThrottled]);

  useEffect(() => {
    const captureOptions: AddEventListenerOptions = { capture: true, passive: false };

    // Subscribe to mouse events at the page level
    document.addEventListener('mousemove', handleMouseMove, captureOptions);
    document.addEventListener('wheel', handleMouseWheel, captureOptions);
    document.addEventListener('touchstart', handleTouchStart, captureOptions);

    document.addEventListener('pointerup', handlePointerEvent);

    // Clean-up
    return () => {
      // Unsubscribe to mouse events.
      document.removeEventListener('mousemove', handleMouseMove, captureOptions);
      document.removeEventListener('wheel', handleMouseWheel, captureOptions);
      document.removeEventListener('touchstart', handleTouchStart, captureOptions);

      document.addEventListener('pointerup', handlePointerEvent);
    };
  }, [handleMouseMove, handleMouseWheel, handlePointerEvent, handleTouchStart]);
}

export default usePrompterCursorManagement;