import { useCallback, useState } from 'react';
import DeviceHost from '../DeviceHost';
import { MessageHandlerEvent, useMessageHandler } from '../../controllers/AppController';
import { BackgroundMessage, MediaWebcamRequest } from '@fluidprompter/core';
import usePrompterSession, { MediaSourceType } from '../../state/PrompterSessionState';

interface UserMediaControllerExports {
  activeUserMedia: MediaStream | undefined,
  clearUserMedia: () => void;
}

function useUserMediaController(deviceHost: DeviceHost,): UserMediaControllerExports {
  //
  // If a local webcam is currently active, we will have a reference to it here.
  // We must clean-up any prior webcam usage prior to using a new webcam in the case of camera
  // switching.
  //
  const [activeUserMedia, setActiveUserMedia] = useState<MediaStream>();

  //
  //
  //
  const clearUserMedia = useCallback(() => {
    //
    // Stop the current user media stream if we had one.
    //
    activeUserMedia?.getTracks()
      .forEach(track => track.stop());

    //
    // Clear our reference to
    setActiveUserMedia(undefined);
  }, [activeUserMedia, setActiveUserMedia]);

  //
  //
  //
  const connectUserMedia = useCallback(async (e: MessageHandlerEvent<MediaWebcamRequest>) => {

    //
    // Stop the current media stream if we already had one.
    //
    if(activeUserMedia) {
      const existingMediaStreamTracks = activeUserMedia.getTracks();
      existingMediaStreamTracks.forEach(track => track.stop());
    }

    //
    // Request a media source (webcam) from the User Agent. If we were provided with a deviceId
    // then we will include it in the video constraints.
    //
    const mediaDevices = navigator.mediaDevices;
    const videoConstraints: MediaTrackConstraints = {
      width: 1280,
      height: 720,
      deviceId: e.message.deviceId,
    };
    let userMediaStream: MediaStream;
    try {
      userMediaStream = await mediaDevices.getUserMedia({
        video: videoConstraints,
        audio: false,
      });
    } catch(err) {
      // Webcam in use by another app throws: DOMException: Starting videoinput failed
      alert('Error starting webcam. Is another application already using your webcam?');
      return;
    }

    //
    // Change our UI state to reflect that we have an active webcam source.
    //
    const localPrompterState = usePrompterSession.getState();
    if(localPrompterState.mediaSourceType !== MediaSourceType.Webcam) {
      localPrompterState.setMediaSourceType(MediaSourceType.Webcam);
    }

    setActiveUserMedia(userMediaStream);

    //
    // Let all our peer connections know that we have a video background to share.
    // PrompterPeer instances will begin sharing this media track via WebRTC.
    //
    deviceHost.appController.dispatchMessage(new BackgroundMessage(userMediaStream, true));
  }, [activeUserMedia]);
  useMessageHandler('media.webcam.request', connectUserMedia);

  //
  //
  //
  return {
    activeUserMedia,
    clearUserMedia,
  };
}

export default useUserMediaController;