import { useCallback } from 'react';
import { useAppController, useMessageHandler } from '../../../controllers/AppController';
import usePrompterSession, { PrompterSessionState } from '../../../state/PrompterSessionState';

import { ScriptNodeTypes, ScriptNodeStateChangeTypes, ScriptNodeStateChangedEvent, PrompterMode, PauseMessage } from '@fluidprompter/core';
import { Pause } from '../../../models/EditorTypes';

function usePauseElementLogic() {

  const appController = useAppController();

  const handleEnteringViewport = useCallback((e: ScriptNodeStateChangedEvent, prompterSession: PrompterSessionState) => {
    // console.log(`handleEnteringViewport(${e?.type}, ${e?.changeType})`);
    if(!e || !e.nodeMeta || !e.previousState || !e.viewportInfo) {
      throw new Error('`prompter.node.pause.enteringviewport` event requires ScriptNodeStateChangedEvent args.');
    }

    const pauseNode = e.node as Pause;
    const pausePosition = pauseNode.pausePosition || 'CUE'; // Default to CUE position if undefined.

    const reverse = e.previousState.aboveViewport || e.previousState.topClipped;  // We don't want to pause if we are just skipping backwards in the script.
    if(pausePosition === 'BOTTOM' && e.prompterMode === PrompterMode.Playing && !reverse) {
      // console.log(`handleEnteringViewport - isLeader:${prompterSession.isLeader} - appController.dispatchMessage(new PauseMessage());`);

      //
      // Calculate the scroll position at which the current `PauseElement` component would be
      // aligned visually with the bottom of the available prompter viewport.
      //
      // The PauseElement component is composed of two primary rectangles. The top rectangle is the
      // visible pause bar on the script, and the second rectangle is the toolbar below the pause
      // bar which contains the "Insert" button - the insert button is hidden while prompter
      // playing. The two rectangles are approximately 2/3 and 1/3 the height of the overall pause
      // element. If we want the PauseElement to be aligned with the bottom of the viewport, we do
      // not want to consider the 1/3 which is the hidden "Insert" button or space between the
      // PauseElement and the next prompter script node.
      //
      // This pixel based scroll position is only used with the local prompter context (and not on
      // network peers).
      //
      const pauseAtPosition = e.nodeMeta.top
        - e.viewportInfo.availableViewportHeight
        - e.viewportInfo.viewportMarginTop
        + (e.nodeMeta.height * 2 / 3);

      //
      // Retrieve a script position for the target pause position which expresses the scroll
      // distance relative to a script node path.
      //
      // This is more accurate across network peers where each script node may be a different size
      // on each device.
      //
      // This script position based measurement is sent to connected prompter peers to express the
      // pause position accurately even with the network peers have a different screen size, font
      // size or margins.
      //
      const pauseAtScriptPosition = prompterSession.getScriptPositionByScrollPosition(pauseAtPosition + e.viewportInfo.cuePositionOffset);

      //
      // Dispatch our pause message with both pause position expressions
      //
      appController.dispatchMessage(new PauseMessage(
        PauseMessage.Mode.WhenArriveAtPosition,
        pauseAtPosition,
        pauseAtScriptPosition,
      ));
    }
  }, []);

  const handleEnteringCue = useCallback((e: ScriptNodeStateChangedEvent, prompterSession: PrompterSessionState) => {
    // console.log(`handleEnteringCue(${e?.type}, ${e?.changeType})`);
    if(!e || !e.nodeMeta || !e.viewportInfo) {
      throw new Error('`prompter.node.pause.enteringviewport` event requires ScriptNodeStateChangedEvent args.');
    }

    const pauseNode = e.node as Pause;
    const pausePosition = pauseNode.pausePosition || 'CUE'; // Default to CUE position if undefined.

    if(pausePosition === 'CUE' && e.prompterMode === PrompterMode.Playing) {
      // console.log(`handleEnteringCue - isLeader:${prompterSession.isLeader} - appController.dispatchMessage(new PauseMessage());`);

      //
      // Calculate the scroll position at which the current `PauseElement` component would be
      // aligned visually with the center of the cue position.
      //
      // The `PauseElement` component is composed of two primary rectangles. The top rectangle is
      // the visible pause bar on the script, and the second rectangle is the toolbar below the
      // pause bar which contains the "Insert" button - the insert button is hidden while prompter
      // playing. The two rectangles are approximately 2/3 and 1/3 the height of the overall pause
      // element. So adding 1/3 of the pause element height to the cue position offset will
      // approximately center the pause element on the cue position when paused.
      //
      // This pixel based scroll position is only used with the local prompter context (and not on
      // network peers).
      //
      const pauseAtPosition = e.nodeMeta.top
        - e.viewportInfo.cuePositionOffset
        + (e.nodeMeta.height / 3);

      //
      // Retrieve a script position for the target pause position which expresses the scroll
      // distance relative to a script node path.
      //
      // This is more accurate across network peers where each script node may be a different size
      // on each device.
      //
      // This script position based measurement is sent to connected prompter peers to express the
      // pause position accurately even with the network peers have a different screen size, font
      // size or margins.
      //
      const pauseAtScriptPosition = prompterSession.getScriptPositionByScrollPosition(pauseAtPosition + e.viewportInfo.cuePositionOffset);

      //
      // Dispatch our pause message with both pause position expressions
      //
      appController.dispatchMessage(new PauseMessage(
        PauseMessage.Mode.WhenArriveAtPosition,
        pauseAtPosition,
        pauseAtScriptPosition,
      ));
    }
  }, []);

  const handleLeavingViewport = useCallback((e: ScriptNodeStateChangedEvent, prompterSession: PrompterSessionState) => {
    // console.log(`handleLeavingViewport(${e?.type}, ${e?.changeType})`);
    if(!e || !e.nodeMeta || !e.previousState) {
      throw new Error('`prompter.node.pause.enteringviewport` event requires ScriptNodeStateChangedEvent args.');
    }

    const pauseNode = e.node as Pause;
    const pausePosition = pauseNode.pausePosition || 'CUE'; // Default to CUE position if undefined.

    const reverse = e.previousState.bottomClipped;  // We don't want to pause if we are just skipping backwards in the script.
    if(pausePosition === 'TOP' && e.prompterMode === PrompterMode.Playing && !reverse) {
      // console.log(`handleLeavingViewport - isLeader:${prompterSession.isLeader} - appController.dispatchMessage(new PauseMessage());`);

      //
      // Calculate the scroll position at which the current `PauseElement` component would be
      // aligned visually with the top of the available viewport.
      //
      // This pixel based scroll position is only used with the local prompter context (and not on
      // network peers).
      //
      // Add +1px to the pause position so that this PauseElement doesn't "begin leaving the
      // viewport again" after the user continues prompting.
      //
      const pauseAtPosition = e.nodeMeta.top
        - (e.viewportInfo?.viewportMarginTop || 0)
        + 1;

      //
      // Retrieve a script position for the target pause position which expresses the scroll
      // distance relative to a script node path.
      //
      // This is more accurate across network peers where each script node may be a different size
      // on each device.
      //
      // This script position based measurement is sent to connected prompter peers to express the
      // pause position accurately even with the network peers have a different screen size, font
      // size or margins.
      //
      const pauseAtScriptPosition = prompterSession.getScriptPositionByScrollPosition(pauseAtPosition + prompterSession.viewportMeta.cuePositionOffset);

      //
      // Dispatch our pause message with both pause position expressions
      //
      appController.dispatchMessage(new PauseMessage(
        PauseMessage.Mode.WhenArriveAtPosition,
        pauseAtPosition,
        pauseAtScriptPosition,
      ));
    }
  }, []);

  useMessageHandler('prompter.node.statechanged', (e) => {
    const { message } = e;
    if(message.node.type !== ScriptNodeTypes.PAUSE) {
      return;
    }

    const prompterSession = usePrompterSession.getState();
    if(!prompterSession.isLeader) {
      return;
    }

    switch(message.changeType) {
      case ScriptNodeStateChangeTypes.ENTERING_VIEWPORT:
        handleEnteringViewport(message, prompterSession);
        break;
      case ScriptNodeStateChangeTypes.ENTERING_CUE:
        handleEnteringCue(message, prompterSession);
        break;
      case ScriptNodeStateChangeTypes.LEAVING_VIEWPORT:
        handleLeavingViewport(message, prompterSession);
        break;
    }
  });
}

export default usePauseElementLogic;