import React, { useLayoutEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

import NoSleepTrayItem from './NoSleepTrayItem';
import LanguageTrayItem from './LanguageTrayItem';
import MediaTrayItem from './MediaTrayItem';
import BluetoothTrayItem from './BluetoothTrayItem';
import CloudConnectionTrayItem from './CloudConnectionTrayItem';
import SharePrompterTrayItem from './SharePrompterTrayItem';

import useFeatureFlagsStore from '../../state/FeatureFlagsStore';
import AppUpdateTrayItem from './AppUpdateTrayItem';

export interface ITrayItem {
  renderAsMenuItem?: boolean;
  title: string;
  icon?: React.ReactNode;
  selected?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

interface TrayChildrenProps {
  viewportIsLarge?: boolean;
  onRootClick?: React.MouseEventHandler<HTMLElement> | undefined;
  requestCloseTray: () => void;
}
const TrayChildren = (props: TrayChildrenProps) => {
  const { viewportIsLarge, onRootClick, requestCloseTray } = props;

  const featureFlags = useFeatureFlagsStore();

  return (<>
    <AppUpdateTrayItem renderAsMenuItem={!viewportIsLarge} requestCloseTray={requestCloseTray} />
    <NoSleepTrayItem renderAsMenuItem={!viewportIsLarge} requestCloseTray={requestCloseTray} />
    {featureFlags.mediaSharing && <MediaTrayItem renderAsMenuItem={!viewportIsLarge} onClick={onRootClick} />}
    <LanguageTrayItem renderAsMenuItem={!viewportIsLarge} onClick={onRootClick} requestCloseTray={requestCloseTray} />
    <BluetoothTrayItem renderAsMenuItem={!viewportIsLarge} onClick={onRootClick} requestCloseTray={requestCloseTray} />
    {/*<CloudConnectionTrayItem renderAsMenuItem={!viewportIsLarge} onClick={onRootClick} requestCloseTray={requestCloseTray} />*/}
    <SharePrompterTrayItem renderAsMenuItem={!viewportIsLarge} onClick={onRootClick} requestCloseTray={requestCloseTray} />
  </>);
};

interface TrayContainerProps {
  viewportIsLarge?: boolean;
  onTrayItemClick?: React.MouseEventHandler<HTMLElement> | undefined;
  // children?: React.ReactNode | React.ReactNode[];
}
const TrayContainer = (props: TrayContainerProps) => {
  const { viewportIsLarge, onTrayItemClick } = props;
  const renderAsMenuItem = !viewportIsLarge;

  const [trayMenuAnchorEl, setTrayMenuAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const onTrayMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    // setNoSleepEnabled(true);
    setTrayMenuAnchorEl(e.currentTarget);
  };
  const onTrayMenuClose = () => {
    setTrayMenuAnchorEl(null);
  };

  useLayoutEffect(() => {
    // When renderAsMenuItem changes, we need to close any open menus.
    onTrayMenuClose();
  }, [renderAsMenuItem]);

  return renderAsMenuItem ? (
    <>
      <Tooltip title="Open Quick Access Tray" arrow>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="Open Quick Access Tray"
          onFocus={(e) => { e.target.blur(); }}
          onClick={onTrayMenuClick}
        >
          <MoreHorizIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="tray-menu"
        anchorEl={trayMenuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(trayMenuAnchorEl)}
        onClose={onTrayMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <TrayChildren viewportIsLarge={viewportIsLarge} onRootClick={onTrayItemClick} requestCloseTray={onTrayMenuClose} />
      </Menu>
    </>
  ) : (<>
    <TrayChildren viewportIsLarge={viewportIsLarge} onRootClick={onTrayItemClick} requestCloseTray={onTrayMenuClose} />
  </>);
};

export default TrayContainer;