import { useCallback } from 'react';
import usePrompterSession from '../../state/PrompterSessionState';
import { useMessageHandler, MessageHandlerEvent } from '../../controllers/AppController';
import { IViewportFuncs } from './usePrompterViewportFuncs';
import { EndpointRole, PlayMessage, PrompterMode } from '@fluidprompter/core';

function usePrompterPlayHandler(viewportFuncs: IViewportFuncs) {

  const doPlay = useCallback(async function (e: MessageHandlerEvent<PlayMessage>) {
    const { message, originatedRemotely } = e;
    const { sender } = message;
    const senderIsRemote = sender?.role === EndpointRole.Remote;

    // if(originatedRemotely) {
    //   console.log(`Received prompter.state.play message - senderIsRemote=${senderIsRemote}, senderMode=${sender?.mode}`, e);
    // }

    const prompterSession = usePrompterSession.getState();

    //
    // If we receive Play/Pause/Edit/Navigate commands from a remote peer, that peer is currently
    // acting as prompter leader.
    //
    // If this message was sent by a prompter (and not a headless remote), then we will re-evaluate
    // whether we are the current leader or not.
    const isLeader = e.checkIAmLeader(prompterSession);

    e.sendToPeers = !originatedRemotely || (senderIsRemote && isLeader);

    //
    // Make sure the prompter is in the playing mode.
    // Note: we want this to happen before `syncScrollPosition()` so that any large sync
    // adjustments that will be made via `usePrompterScrollToPositionFunction()` will be executed
    // in the correct fashion using `setCurrentSkip()` on position ledger.
    //
    const localPrompterSession = usePrompterSession.getState();
    if(localPrompterSession.isBlanking) {
      e.dispatchMessage('prompter.content.show');
    }
    if(!localPrompterSession.isPlaying) {
      localPrompterSession.play();
    }

    //
    // If this play message originated remotely, we want to sync our scroll speed and position.
    //
    if(
      originatedRemotely
      && !isLeader
      && !senderIsRemote
    ) {
      //
      // Apply the remote prompter scroll speed to the local prompter.
      //
      e.syncScrollSpeed();

      //
      // Apply the remote prompter script position to the local prompter.
      //
      e.syncScrollPosition();
    }

    //
    // If we are transmitting this message to peers, let's update our sender info.
    //
    if(e.sendToPeers && sender) {
      sender.mode = PrompterMode.Playing;
    }

  }, []);
  useMessageHandler('prompter.state.play', doPlay);
}

export default usePrompterPlayHandler;