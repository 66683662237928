import React from 'react';

import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import CheckIcon from '@mui/icons-material/Check';

import { useTranslation } from 'react-i18next';
import useConfigurationStore, { MediaPanelPosition } from '../../state/ConfigurationStore';
import { shallow } from 'zustand/shallow';

interface MediaPanelContextMenuProps extends MenuProps {
  closeContextMenu: () => void;
}

function MediaPanelContextMenu(props: MediaPanelContextMenuProps) {
  const { closeContextMenu, ...menuProps } = props;

  const { t } = useTranslation('prompter');

  const configStore = useConfigurationStore(state => ({
    mediaPanelPosition: state.mediaPanelPosition,
    setMediaPanelPosition: state.setMediaPanelPosition,
    media1ScalingMode: state.media1ScalingMode,
  }), shallow);

  return (
    <Menu
      {...menuProps}
      BackdropProps={{
        onPointerDown: (e) => {
          e.preventDefault();
          e.stopPropagation();
          closeContextMenu();
        },
        onContextMenu: (e) => {
          e.preventDefault();
        }
      }}
    >
      <ListItem
        sx={{
          minWidth: 240,
        }}
      >{t('trayicons.mediasharing.position_title')}</ListItem>
      <MenuItem onClick={
        () => {
          closeContextMenu();
          configStore.setMediaPanelPosition(MediaPanelPosition.Top);
        }
      }>
        {(configStore.mediaPanelPosition === MediaPanelPosition.Top) && <ListItemIcon>
          <CheckIcon />
        </ListItemIcon>}
        <ListItemText
          primary={t('trayicons.mediasharing.position_top')}
          inset={configStore.mediaPanelPosition !== MediaPanelPosition.Top}
        />
      </MenuItem>
      <MenuItem onClick={
        () => {
          closeContextMenu();
          configStore.setMediaPanelPosition(MediaPanelPosition.Bottom);
        }
      }>
        {(configStore.mediaPanelPosition === MediaPanelPosition.Bottom) && <ListItemIcon>
          <CheckIcon />
        </ListItemIcon>}
        <ListItemText
          primary={t('trayicons.mediasharing.position_bottom')}
          inset={configStore.mediaPanelPosition !== MediaPanelPosition.Bottom}
        />
      </MenuItem>
      <MenuItem onClick={
        () => {
          closeContextMenu();
          configStore.setMediaPanelPosition(MediaPanelPosition.Left);
        }
      }>
        {(configStore.mediaPanelPosition === MediaPanelPosition.Left) && <ListItemIcon>
          <CheckIcon />
        </ListItemIcon>}
        <ListItemText
          primary={t('trayicons.mediasharing.position_left')}
          inset={configStore.mediaPanelPosition !== MediaPanelPosition.Left}
        />
      </MenuItem>
      <MenuItem onClick={
        () => {
          closeContextMenu();
          configStore.setMediaPanelPosition(MediaPanelPosition.Right);
        }
      }>
        {(configStore.mediaPanelPosition === MediaPanelPosition.Right) && <ListItemIcon>
          <CheckIcon />
        </ListItemIcon>}
        <ListItemText
          primary={t('trayicons.mediasharing.position_right')}
          inset={configStore.mediaPanelPosition !== MediaPanelPosition.Right}
        />
      </MenuItem>
      <MenuItem onClick={
        () => {
          closeContextMenu();
          configStore.setMediaPanelPosition(MediaPanelPosition.Background);
        }
      }>
        {(configStore.mediaPanelPosition === MediaPanelPosition.Background) && <ListItemIcon>
          <CheckIcon />
        </ListItemIcon>}
        <ListItemText
          primary={t('trayicons.mediasharing.position_background')}
          inset={configStore.mediaPanelPosition !== MediaPanelPosition.Background}
        />
      </MenuItem>
    </Menu>
  );
}

export default MediaPanelContextMenu;