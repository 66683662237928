import { MessageChannel } from '@fluidprompter/ipc';

/**
 * Message channel for relaying IPC messages between IPC server and client when
 * they are running within the same process
 */
export class LocalRelayChannel implements MessageChannel {
  receivers: Array<{host: string, messageProcessor: CallableFunction}>;

  constructor() {
    this.receivers = [];
  }

  send(instanceName: string, data: unknown): Promise<void> {
    for (const receiver of this.receivers) {
      // Don't send messages to itself
      if (receiver.host === instanceName) {
        continue;
      }

      receiver.messageProcessor(data);
    }
    return Promise.resolve();
  }

  addOnMessageListener(instanceName:string, listenerCallback: (data: unknown) => void) {
    this.receivers.push({
      host: instanceName,
      messageProcessor: listenerCallback.bind(this)
    });
  }
}
