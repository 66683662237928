import { useCallback } from 'react';
import { Descendant } from 'slate';
import { ScrollToArgs } from './usePrompterScrollToPositionFunction';
import { PushLastScrollTargetArgs, ScrollPositionQueueEntry } from './useSegmentNavigationFunctions';

import usePrompterSession from '../../state/PrompterSessionState';
import useConfigurationStore from '../../state/ConfigurationStore';
import IPrompterPosition from '../../models/segments/IPrompterPosition';

const usePrompterScrollToSegmentFunction = function(
  getPrompterPosition: () => IPrompterPosition | undefined,
  scrollToPosition: (args?: ScrollToArgs | undefined) => Promise<void>,
  pushLastScrollTarget: (args: PushLastScrollTargetArgs) => ScrollPositionQueueEntry,
  shiftLastScrollTarget: () => ScrollPositionQueueEntry | undefined,
) {
  const handleScrollToSegment = useCallback(async function (targetSegment: Descendant, targetPosition?: string) {
    const prompterPosition = getPrompterPosition();
    if(!prompterPosition) {
      // getPrompterPosition() will return undefined if we don't have refs to DOM elements.
      // This can happen during mounting/unmounting/rerendering of components.
      return;
    }

    const {
      scriptNodes,
      scriptNodesMeta,
      viewportMeta,
    } = usePrompterSession.getState();

    // Get segmentMeta data
    const targetSegmentIndex = scriptNodes.indexOf(targetSegment);
    if(targetSegmentIndex < 0) {
      // The slate node provided is not in the current script root elements array. This shouldn't happen unless we have a stale closure or something.
      console.log(`targetSegmentIndex not found for targetSegment ${JSON.stringify(targetSegment)}`);
      return;
    }

    const targetSegmentMeta = scriptNodesMeta?.nodesMeta[targetSegmentIndex];
    if(!targetSegmentMeta) {
      console.log(`scriptNodesMeta not found for index ${targetSegmentIndex}`);
      return;
    }

    const lineHeightFactor = useConfigurationStore.getState().lineHeight;
    const cueHeight = prompterPosition.lineHeight / lineHeightFactor; // The Cue position indicator SVG is just the height of the text.

    //
    // targetSegmentMeta.top is the logical top of the element expressed a distance from the
    // logical top of the prompter content (whether or not it is currently being displayed flipped)
    //
    // cuePositionOffset is the distance from the logical top of the prompter viewport and the cue
    // position within that viewport. This should take into account any viewport margins as a
    // result of an open UI element such as the Media Panel.
    //
    let scrollPosition = ('top' === targetPosition)
      ? targetSegmentMeta.top - viewportMeta.viewportMarginTop - 66  // top bar is 64px, then focus outline is 2px
      : targetSegmentMeta.top - viewportMeta.cuePositionOffset + (cueHeight / 2); // Factors in configured lineHeight
    if(scrollPosition < 0) { scrollPosition = 0; }

    //
    // If this is the Start Script Element, we have a special case and will scroll to the 0
    // position (keep in mind the StartScript element uses a CSS `transform: translateY(-50%)` to
    // center the start indicator on the cue position)
    //
    if(targetSegmentIndex === 0) {
      scrollPosition = 0;
    }

    //
    // Push an entry onto our queue of scroll to segment requests.
    //
    const queueEntry = pushLastScrollTarget({ scrollPosition, segment: targetSegment });

    //
    // Get our promise to scroll to the target segment.
    //
    try {
      //
      // When the promise is settled (whether reject or resolved) we need to shift out our queue entry.
      //
      await scrollToPosition({ scrollPosition, abortController: queueEntry.abortController });

      // the onScroll updates are throttled. Let's just set the active segment now.
      usePrompterSession.getState().setCurrentScriptNode(targetSegment);
    } catch(err) {
      // We don't care if we failed to reach the target position - we may have received further
      // user input to navigate elsewhere.
    } finally {
      shiftLastScrollTarget();
      // console.log(`END scrolltoSegment(${queueEntry.segmentIndex})`)
    }
  }, [getPrompterPosition, pushLastScrollTarget, shiftLastScrollTarget, scrollToPosition]);

  return handleScrollToSegment;
};

export default usePrompterScrollToSegmentFunction;