import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Button, { buttonClasses } from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckIcon from '@mui/icons-material/Check';

import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import SwitchVideoIcon from '@mui/icons-material/SwitchVideo';
//
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import MicNoneIcon from '@mui/icons-material/MicNone';
//
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import SettingsIcon from '@mui/icons-material/Settings';

import { useAppController, useMessageHandler } from '../../../controllers/AppController';
import usePrompterSession, { MediaSourceType } from '../../../state/PrompterSessionState';
import { MediaWebcamRequest } from '@fluidprompter/core';

import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';

const ShotLogButtonBase = styled(Button)({
  flexDirection: 'column',
  fontSize: 'revert',
  lineHeight: '1.25',
  color: 'rgba(255,255,255,0.7)',
  whiteSpace: 'nowrap',
  '&:hover': {
    color: '#fff',
  },
  ['&.Highlighted']: {
    color: '#fff',
  },
  [`&.${buttonClasses.disabled}`]: {
    color: 'rgba(255, 255, 255, 0.3)',    // Should this come from material-ui theme?
    '.MuiButton-startIcon': {
      color: 'rgba(255, 255, 255, 0.3)',  // Should this come from material-ui theme?
    },
  },
  [`.${buttonClasses.startIcon}`]: {
    marginLeft: 0,
    marginRight: 0,
    color: '#fff',
    '& > *:nth-of-type(1)': {
      fontSize: '1em',
    }
  },
  [`.${buttonClasses.endIcon}`]: {
    '& > *:nth-of-type(1)': {
      fontSize: '1em',
    }
  },
});

interface VideoDeviceInfo {
  deviceId: string;
  groupId: string;
  kind: MediaDeviceKind;
  label: string;
  active: boolean;
}
type AvailableVideoSources = VideoDeviceInfo[];

interface VideoLabelBarProps {
  mediaStream: MediaStream;
  hideToolbar: boolean;
}

function VideoLabelBar(props: VideoLabelBarProps) {
  const {
    mediaStream,
    hideToolbar,
  } = props;

  const { t } = useTranslation('prompter');

  const appController = useAppController();

  const [availableVideoSources, setAvailableVideoSources] = useState<AvailableVideoSources>([]);

  const [videoSourceMenuAnchorEl, setVideoSourceMenuAnchorEl] = React.useState<HTMLElement | null>(null);
  const showVideoSourceMenu = async (e: React.MouseEvent<HTMLElement>) => {
    // The event currentTarget reference can get lost (out of scope) after any asyncronous code,
    // including `await`. So let's hold a local reference as a closure.
    const menuAnchorEl = e.currentTarget;

    //
    // Try to enumerate available cameras and microphones.
    //
    let availableVideoSources: AvailableVideoSources = [];
    try {
      //
      // Retrieve the deviceId for the current video track which may have been selected via a browser
      // native UI that we have no knowledge of.
      const mediaTracks = mediaStream.getTracks();
      const videoTrack = mediaTracks.find(track => track && track.kind === 'video');
      const currentDeviceId = videoTrack?.getSettings().deviceId;

      //
      // Enumerate all video/audio devices available
      const mediaDevices = await navigator.mediaDevices.enumerateDevices();

      //
      // If none of the enumerated devices have a label, the user has not yet granted permission to
      // FluidPrompter. We will need to pop-up the browser native camera permission dialog before
      // we can enumerate available devices.
      const hasAnyLabel = mediaDevices.some(device => device.label);

      //
      // If we have human readable labels for the available devices, then it makes sense to show
      // the user our camera selection menu!
      if(hasAnyLabel) {
        //
        // Filter available audio/video devices so only show video inputs and map result to include
        // whether this source is currently active or not.
        availableVideoSources = mediaDevices
          .filter(device => device.kind === 'videoinput')
          .map<VideoDeviceInfo>(device => ({
            deviceId: device.deviceId,
            groupId: device.groupId,
            kind: device.kind,
            label: device.label,
            active: currentDeviceId === device.deviceId,
          }));

        // availableVideoSources.forEach((device) => {
        //   console.log(`${device.kind}: ${device.label} id = ${device.deviceId} (hasAnyLabel: ${hasAnyLabel})`);
        // });
      }
    } catch(err) {
      const error = err as Error;
      console.error(`${error.name}: ${error.message}`);

      setAvailableVideoSources([]);
    }

    //
    // Save our list of currently available devices to be shown in a popup menu for the user to
    // choose from.
    setAvailableVideoSources(availableVideoSources);

    //
    // Show the pop-up menu now anchored to the button we just clicked on.
    setVideoSourceMenuAnchorEl(menuAnchorEl);
  };
  const doVideoSourceMenuClose = () => {
    setVideoSourceMenuAnchorEl(null);
  };

  const mediaSourceType = usePrompterSession(state => state.mediaSourceType);
  const isRemoteBackgroundSource = (mediaSourceType === MediaSourceType.Remote);

  return (
    <Stack
      className={classNames('video-bottom-container', { 'hideToolbar': hideToolbar })}
      direction="column"
      alignItems={'flex-start'}
    >
      {isRemoteBackgroundSource && <div
        className='video-label-bar'
      >Remote Source</div>}

      <Toolbar
        className='video-bottom-toolbar'
        disableGutters
      >
        <Tooltip title={'Change Video'} placement="top" arrow>
          <ShotLogButtonBase
            startIcon={<VideocamIcon sx={{ width: '24px', height: '24px' }} />}
            onFocus={(e) => { e.target.blur(); }}
            onClick={showVideoSourceMenu}
          >Video</ShotLogButtonBase>
        </Tooltip>
        <Menu
          anchorEl={videoSourceMenuAnchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={Boolean(videoSourceMenuAnchorEl)}
          onClose={doVideoSourceMenuClose}
        >
          <ListItem
            sx={{
              minWidth: 240,
              // textTransform: 'uppercase',
            }}
          >{t('trayicons.mediasharing.sources_title')}</ListItem>
          {availableVideoSources.map((videoDevice) => (<MenuItem
            key={videoDevice.deviceId}
            onClick={(e) => {
              doVideoSourceMenuClose();

              if(!videoDevice.active) {
                appController.dispatchMessage(new MediaWebcamRequest(videoDevice.deviceId));
              }
            }}
          >
            <ListItemIcon>
              {videoDevice.active ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
            </ListItemIcon>
            <ListItemText>{videoDevice.label}</ListItemText>
          </MenuItem>))}

          {!availableVideoSources.length && <MenuItem
            onClick={(e) => {
              doVideoSourceMenuClose();
              appController.dispatchMessage(new MediaWebcamRequest());
            }}
          >
            <ListItemIcon>
              <RadioButtonUncheckedIcon />
            </ListItemIcon>
            <ListItemText>{t('trayicons.mediasharing.sources_webcam')}</ListItemText>
          </MenuItem>}

          <MenuItem
            disabled={!isRemoteBackgroundSource}
            onClick={doVideoSourceMenuClose}
          >
            <ListItemIcon>
              {isRemoteBackgroundSource ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
            </ListItemIcon>
            <ListItemText>{t('trayicons.mediasharing.sources_remote')}</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={(e) => {
              doVideoSourceMenuClose();
              appController.dispatchMessage('prompter.background.clear');
            }}
          >
            <ListItemIcon>
              <VideocamOffIcon />
            </ListItemIcon>
            <ListItemText>Stop Video</ListItemText>
          </MenuItem>
        </Menu>

        <Tooltip title={'Audio feature not available yet!'} placement="top" arrow>
          <span>
            <ShotLogButtonBase disabled
              startIcon={<MicOffIcon sx={{ width: '24px', height: '24px' }} />}
              onFocus={(e) => { e.target.blur(); }}
              onClick={showVideoSourceMenu}
            >Audio</ShotLogButtonBase>
          </span>
        </Tooltip>

        <Tooltip title={'Record feature not available yet!'} placement="top" arrow>
          <span>
            <ShotLogButtonBase disabled
              startIcon={<FiberManualRecordIcon sx={{ width: '24px', height: '24px' }} />}
              onFocus={(e) => { e.target.blur(); }}
              onClick={showVideoSourceMenu}
            >Record</ShotLogButtonBase>
          </span>
        </Tooltip>

        <Box sx={{ flexGrow: 1 }} />
        <Tooltip title={'Settings'} placement="top" arrow>
          <span>
            <ShotLogButtonBase disabled
              startIcon={<SettingsIcon sx={{ width: '24px', height: '24px' }} />}
              onFocus={(e) => { e.target.blur(); }}
              onClick={showVideoSourceMenu}
            >Settings</ShotLogButtonBase>
          </span>
        </Tooltip>
      </Toolbar>
    </Stack>
  );
}

export default VideoLabelBar;