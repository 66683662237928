import { create } from 'zustand';

interface FeatureFlagsStore {
  prompterDrawerLogonMenu: boolean;
  setPrompterDrawerLogonMenu: (prompterDrawerLogonMenu: boolean) => void;

  prompterDrawerViewMenu: boolean;
  setPrompterDrawerViewMenu: (prompterDrawerViewMenu: boolean) => void;

  voiceDictation: boolean;
  setVoiceDictation: (voiceDictation: boolean) => void;

  mediaSharing: boolean;
  setMediaSharing: (mediaSharing: boolean) => void;

  encodeFeatureFlagParams: (searchParams: URLSearchParams) => void;
}

const useFeatureFlagsStore = create<FeatureFlagsStore>()(
  (set, get) => ({
    prompterDrawerLogonMenu: true,
    setPrompterDrawerLogonMenu: (prompterDrawerLogonMenu: boolean) => set(() => ({ prompterDrawerLogonMenu })),

    prompterDrawerViewMenu: false,
    setPrompterDrawerViewMenu: (prompterDrawerViewMenu: boolean) => set(() => ({ prompterDrawerViewMenu })),

    voiceDictation: false,
    setVoiceDictation: (voiceDictation: boolean) => set(() => ({ voiceDictation })),

    mediaSharing: true,
    setMediaSharing: (mediaSharing: boolean) => set(() => ({ mediaSharing })),

    encodeFeatureFlagParams: (searchParams: URLSearchParams) => {
      const featureFlagsState = get();

      // if(featureFlagsState.prompterDrawerLogonMenu) {
      //   searchParams.set('enable_account', '1');
      // }

      if(featureFlagsState.prompterDrawerViewMenu) {
        searchParams.set('enable_viewmenu', '1');
      }

      if(featureFlagsState.voiceDictation) {
        searchParams.set('enable_voice', '1');
      }

      if(featureFlagsState.mediaSharing) {
        searchParams.set('enable_media', '1');
      }
    },
  })
);

export default useFeatureFlagsStore;