import { MessageChannel, prepareForSerialization } from '@fluidprompter/ipc';

/**
 * Message channel for relaying IPC messages between IPC server (react native process)
 * and client (web view process)
 */
export class ReactWebViewChannel implements MessageChannel {
  receivers: Array<{host: string, messageProcessor: CallableFunction}>;

  constructor() {
    this.receivers = [];
  }

  private onMessageHandler(data: string) {
    for (const receiver of this.receivers) {
      console.log(`Received message from React Native - ${receiver.host}`, data);
      receiver.messageProcessor(JSON.parse(data));
    }
  }

  send(instanceName: string, data: unknown): Promise<void> {
    if(!window.ReactNativeWebView) { // This will be undefined if not running inside our react-native expo app.
      throw new Error('window.ReactNativeWebView not defined');
    }
    console.log(`Send to React Native - ${instanceName}`, data);
    window.ReactNativeWebView.postMessage(JSON.stringify(prepareForSerialization(data)));
    return Promise.resolve();
  }

  addOnMessageListener(instanceName:string, listenerCallback: (data: unknown) => void) {
    this.receivers.push({
      host: instanceName,
      messageProcessor: listenerCallback.bind(this)
    });
  }

  public getMessageHandler(): (data: string) => void {
    return this.onMessageHandler.bind(this);
  }
}
