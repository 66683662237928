import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import usePrompterSession from '../../../state/PrompterSessionState';

interface TocEntryRendererProps {
  segmentIndex: number;
  segmentTitle: string;
  handleListItemClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>,index: number) => void;
}

const TocEntryRenderer = React.memo(function TocEntryRenderer(props: TocEntryRendererProps) {

  const initiallyActive = (props.segmentIndex === usePrompterSession.getState().currentScriptNodeIndex);
  const [isActive, setIsActive] = React.useState<boolean>(initiallyActive);

  //
  // Minimize React DOM changes, instead subscribe to PrompterSession state changes,
  // and update the icon visibility via a DOM ref.
  //
  React.useEffect(() => {
    const unsubscribe = usePrompterSession.subscribe(
      (config, previousConfig) => {
        if(config.currentScriptNodeIndex !== previousConfig.currentScriptNodeIndex) {
          setIsActive(config.currentScriptNodeIndex === props.segmentIndex);
        }
      }
    );

    return unsubscribe;
  }, [props.segmentIndex]);

  return (<>
    <ListItemButton
      component={'button'}
      selected={isActive}
      onFocus={(e) => { e.target.blur(); }}
      onClick={(event) => props.handleListItemClick(event, props.segmentIndex)}
      disableRipple
      disableTouchRipple
      disableGutters
      sx={{
        width: '100%',
        paddingRight: 1,
      }}
    >
      <ListItemIcon>
        <PlayArrowIcon style={{ color: (isActive ? '#ff0000' : 'transparent') }} />
      </ListItemIcon>
      <ListItemText primary={props.segmentTitle} />
    </ListItemButton>
  </>);
});

export default TocEntryRenderer;