import React, { useCallback, useState } from 'react';
import { Descendant, Transforms } from 'slate';
import { ReactEditor, useSlateStatic, RenderElementProps, useFocused, useSelected, useReadOnly } from 'slate-react';
import { PrompterMode, ScriptNodeStateChangedEvent, ScriptNodeStateChangeTypes, ScriptNodeTypes, SetLeaderMessage } from '@fluidprompter/core';
import { PrompterSegment } from '../../../models/EditorTypes';
import ISegmentMeasurements from '../../../models/segments/ISegmentMeasurements';

import useApplicationContext from '../../../hooks/useApplicationContext';
import { useAppController, useMessageHandler } from '../../../controllers/AppController';
import { useConfirm } from 'material-ui-confirm';

import { useTranslation } from 'react-i18next';
import useConfigurationStore from '../../../state/ConfigurationStore';
import usePrompterSession from '../../../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';

import { Typography } from '@mui/material';
import SegmentElementHeader from './SegmentElementHeader';

import SegmentShotLogActions from './SegmentShotLogActions';
import SegmentInsertToolbar from '../SegmentInsertToolbar';

import classNames from 'classnames';
import './SegmentElement.scss';


const elapsedTimeStringFromSeconds = function(totalSeconds?: number): string {

  const totalElapsedMs = totalSeconds || 0;

  const elapsedHours = Math.floor((totalElapsedMs / (60 * 60)) % 24);
  const elapsedMinutes = Math.floor((totalElapsedMs / 60) % 60);
  const elapsedSeconds = Math.floor((totalElapsedMs) % 60);

  const elapsedHoursString = ('0' + elapsedHours).slice(-2);
  const elapsedMinutesString = ('0' + elapsedMinutes).slice(-2);
  const elapsedSecondsString = ('0' + elapsedSeconds).slice(-2);

  return (elapsedHours > 0) ? `${elapsedHoursString}:${elapsedMinutesString}:${elapsedSecondsString}` : `${elapsedMinutesString}:${elapsedSecondsString}`;
};

const SegmentElement = React.memo(function SegmentElement(props: RenderElementProps) {
  const { attributes, children, element } = props;
  const prompterSegmentElement = element as PrompterSegment;

  const appController = useAppController();

  const { t } = useTranslation(['prompter', 'common']);

  const {
    appContext,
  } = useApplicationContext();
  const { internetOnline, isLoading, isAuthenticated, userProfile } = appContext;
  const wordLimitEnabled = !isAuthenticated || !userProfile?.fluidprompter_plan || userProfile.fluidprompter_plan === 'free';

  const configStore = useConfigurationStore(state => ({
    contentJustification: state.contentJustification,
  }), shallow);

  const getNodeTextMetricsByPath = usePrompterSession(state => state.getNodeTextMetricsByPath);
  const getNodeMetaByPath = usePrompterSession(state => state.getNodeMetaByPath);

  const segmentTextMetrics = usePrompterSession(state => state.getNodeTextMetrics(props.element), shallow) || { characters: 0, words: 0, lines: 0 } as ISegmentMeasurements;

  const isPlaying = usePrompterSession(state => state.isPlaying);
  const isPaused = usePrompterSession(state => state.isPaused);
  const shotloggingEnabled = usePrompterSession(state => state.shotloggingEnabled);

  // const segmentNodeMeta = usePrompterSession(state => state.getNodeMeta(props.element), shallow);
  // const segmentNodeState = usePrompterSession(state => state.getNodeState(props.element), shallow);

  const confirm = useConfirm();

  const editor = useSlateStatic();

  const selected = useSelected();   // Get the current selected state of an element.
  const focused = useFocused();     // Get the current focused state of the editor.
  const readOnly = useReadOnly();   // Get the current readonly state of the editor.

  const [shotlogActive, setShotlogActive] = useState<boolean>(false);
  /*
  const handleLeavingCue = useCallback((e?: ScriptNodeStateChangedEvent) => {
    if(!e || e.prompterMode !== PrompterMode.Playing || e.node !== prompterSegmentElement) {
      return;
    }

    setShotlogActive(true);
    /-*
    bus.emit('prompter.state.pause');
    bus.emit('prompter.delay.actions');

    setAutoContinueMilliseconds(5000);
    *-/
  }, [prompterSegmentElement]);
  */

  const handleEnteringCue = useCallback((e?: ScriptNodeStateChangedEvent) => {
    if(!e || e.prompterMode !== PrompterMode.Playing || e.node !== prompterSegmentElement) {
      return;
    }

    setShotlogActive(true);
  }, [prompterSegmentElement]);

  const handleLeftViewport = useCallback((e?: ScriptNodeStateChangedEvent) => {
    if(!e || e.prompterMode !== PrompterMode.Playing || e.node !== prompterSegmentElement) {
      return;
    }

    setShotlogActive(false);
  }, [prompterSegmentElement]);

  useMessageHandler('prompter.node.statechanged', (e) => {
    e.sendToPeers = false;

    const { message } = e;

    if(message.node.type !== ScriptNodeTypes.SCRIPT_SEGMENT) {
      return;
    }
    switch(message.changeType) {
      case ScriptNodeStateChangeTypes.ENTERING_CUE:
        handleEnteringCue(message);
        break;
      // case ScriptNodeStateChangeTypes.LEAVING_CUE:
      //   handleLeavingCue(message);
      //   break;
      case ScriptNodeStateChangeTypes.LEFT_VIEWPORT:
        handleLeftViewport(message);
        break;
    }
  });

  const onTitleChangedHandler = useCallback((title: string) => {
    const path = ReactEditor.findPath(editor, prompterSegmentElement);

    Transforms.setNodes<PrompterSegment>(
      editor,
      { title },
      { at: path }
    );
  }, [editor, prompterSegmentElement]);

  const onRequestDeleteHandler = useCallback(async () => {
    await confirm({ description: 'Are you sure you want to delete this segment?' });

    // If the local prompter believes it is the leader, then the following node removal
    // will be sent to all other connected prompters.
    //
    // After a script modification has been sent to all other connected prompters, those
    // other prompters will also recognize this prompter as the current leader.
    appController.setLeaderIsSelf();
    const freshpath = ReactEditor.findPath(editor as ReactEditor, prompterSegmentElement);
    Transforms.removeNodes(editor, { at: freshpath });
  }, [confirm, editor, prompterSegmentElement]);

  const onInsertNodeHandler = useCallback((node: Descendant) => {
    const freshpath = ReactEditor.findPath(editor, element);

    // Insert the proposedNode after the current node.
    const currentNodeIndex = freshpath.shift();
    if(currentNodeIndex !== undefined) {
      freshpath.unshift(currentNodeIndex + 1);
    }

    // If the local prompter believes it is the leader, then the following node transform
    // will be sent to all other connected prompters.
    //
    // After a script modification has been sent to all other connected prompters, those
    // other prompters will also recognize this prompter as the current leader.
    appController.setLeaderIsSelf();
    Transforms.insertNodes<Descendant>(editor, node, {
      at: freshpath,
    });
    Transforms.select(editor, freshpath);
  }, [editor, element]);

  // If segmentTextMetrics.words_end is > 500, then we need to mask part or all of this script
  // segment for unpaid accounts.
  const wordThreshold = 110;
  const exceeds500Words = segmentTextMetrics.document_words >= wordThreshold;

  let wordThresholdMaskTop = 0;
  if(wordLimitEnabled && exceeds500Words) {
    const segmentNodePath = ReactEditor.findPath(editor, prompterSegmentElement);
    const segmentNodeMeta = getNodeMetaByPath(segmentNodePath);

    for(let i = 0; i < prompterSegmentElement.children.length; i++) {
      const childPath = [...segmentNodePath, i];
      const childTextMetrics = getNodeTextMetricsByPath(childPath);

      if(childTextMetrics && childTextMetrics.document_words >= wordThreshold) {
        const childMeta = segmentNodeMeta?.children[i];
        if(childMeta) {
          wordThresholdMaskTop = childMeta.top - segmentNodeMeta.childrenTop;
          // console.log(`Found first child to exceed word threshold: ${childPath} with top ${wordThresholdMaskTop}px`, segmentNodeMeta);
          break;
        }
      }
    }
  }

  return (
    <div
      className={'SegmentElement'}
      {...attributes}
    >
      <div className={classNames('SegmentElementBorder', { isSelected: (selected && focused) })}>
        <SegmentElementHeader
          number={prompterSegmentElement.number || -1}
          title={prompterSegmentElement.title || t('segmentelement.defaultcaption')}
          onTitleChanged={onTitleChangedHandler}
          onRequestDelete={onRequestDeleteHandler}
          readOnly={readOnly}
        />
        <div
          className={classNames('SegmentElementBody', { MaskWordLimit: wordLimitEnabled })}
          style={{
            textAlign: configStore.contentJustification,
          }}
          //onKeyDown={onContentKeydown}
          //onInput={onContentInput}
        >
          {children}
        </div>
        <div className="SegmentElementFooter" contentEditable={false}>
          <Typography
            className="HideWhenPrompting"
            variant="body1"
            color="rgb(78, 78, 78)"
            fontWeight="bold"
            sx={{
              paddingLeft: '0.25em',
            }}
          >{segmentTextMetrics.characters} {t('segmentelement.stats.characters')}, {segmentTextMetrics.words} {t('segmentelement.stats.words')}, ~{elapsedTimeStringFromSeconds(segmentTextMetrics.estimatedLength)} {t('segmentelement.stats.estimatedreadingtime')}</Typography>
          {/*segmentNodeMeta && (
            <Typography variant="body1" color="rgb(78, 78, 78)" fontWeight="bold">Top: {segmentNodeMeta.segmentTop}, Height: {segmentNodeMeta.segmentHeight}, Bottom: {segmentNodeMeta.segmentBottom}</Typography>
          )*/}
          {shotloggingEnabled && <SegmentShotLogActions
            node={prompterSegmentElement}
            isPlaying={isPlaying}
            isPaused={isPaused}
            shotlogActive={isPaused && shotlogActive}
          />}
        </div>
      </div>
      {/*<div className="SegmentElementFooter" contentEditable={false}>
        <Typography variant="body1" color="rgb(78, 78, 78)" fontWeight="bold" sx={{
          paddingLeft: '0.25em',
          visibility: editorIsReadOnly ? 'hidden' : 'inherit',
        }}>{segmentTextMetrics.characters} Characters, {segmentTextMetrics.words} Words, ~{elapsedTimeStringFromSeconds(segmentTextMetrics.estimatedLength)} Natural Reading Time</Typography>
        {/-*segmentNodeMeta && (
          <Typography variant="body1" color="rgb(78, 78, 78)" fontWeight="bold">Top: {segmentNodeMeta.segmentTop}, Height: {segmentNodeMeta.segmentHeight}, Bottom: {segmentNodeMeta.segmentBottom}</Typography>
        )*-/}
      </div>*/}
      <SegmentInsertToolbar onInsertNode={onInsertNodeHandler} />
    </div>
  );
});

export default SegmentElement;