
import {
  BaseControlMessage, SenderInfo, EndpointRole, // PrompterMode,
  // AppMessageTypes, AppMessageMap,
  GenericMessage, AppMessageMap,
  BinarySender,
  AppLifecycleState,
  LifecycleMessage,
  GetSessionMessage,
  PeerPingMessage,
  PeerPongMessage,
  PeerMissingMessage,
  PrompterSessionEndpoint,
  ConnectionState,
} from '@fluidprompter/core';
import useConfigurationStore from '../../state/ConfigurationStore';
import usePrompterSession from '../../state/PrompterSessionState';

import { MessageContext } from './MessageContext';
import WebsocketChannel from './WebsocketChannel';
import MessageDeduplicator from './MessageDeduplicator';
import MessageHandlerEvent from './MessageHandlerEvent';
import DeviceHost from '../../devices/DeviceHost';

import WindowTracker from '../WindowTracker';

import PrompterPeerInstance from '../../devices/prompterpeer/PrompterPeerInstance';
import { DeviceConnectionType } from '../../devices/BaseDevice';

import {
  osName, osVersion, OsTypes,
  browserName, browserVersion,
  isTablet, isMobile, isDesktop,
} from 'react-device-detect';

import { logDecorator } from '../../utils/Logger';

import { Logger, TRACE } from 'browser-bunyan';
import logger from '../../utils/Logger';
import { MessageChannel } from '@fluidprompter/ipc';
import { LocalRelayChannel } from '../../utils/IPCChannels/LocalRelayChannel';
import { BluetoothProviderWebIPCServer } from '../../devices/BluetoothWeb';
import { ReactWebViewChannel } from '../../utils/IPCChannels/ReactWebViewChannel';
import { isReactNativeWebView } from '../../utils/BrowserUtils';

export type EventType = string | symbol;

export type IPCEventHandler = (msg: string) => void;

// An event handler can take an optional event argument
// and should not return a value
export type Handler<T extends BaseControlMessage = BaseControlMessage> = (e: MessageHandlerEvent<T>) => void;
export type WildcardHandler<T = Record<string, unknown>> = (
	type: keyof T,
	event: T[keyof T]
) => void;

// An array of all currently registered event handlers for a type
export type EventHandlerList<T extends BaseControlMessage = BaseControlMessage> = Array<Handler<T>>;
export type WildCardEventHandlerList<T = Record<string, unknown>> = Array<
	WildcardHandler<T>
>;

// A map of event types and their corresponding event handlers.
// export type EventHandlerMap<Events extends Record<EventType, unknown>> = Map<
// 	keyof Events | '*',
// 	EventHandlerList<Events[keyof Events]> | WildCardEventHandlerList<Events>
// >;

/*
export interface Emitter<Events extends Record<EventType, unknown>> {
	all: EventHandlerMap<Events>;

	on<Key extends keyof Events>(type: Key, handler: Handler<Events[Key]>): void;
	on(type: '*', handler: WildcardHandler<Events>): void;

	off<Key extends keyof Events>(
		type: Key,
		handler?: Handler<Events[Key]>
	): void;
	off(type: '*', handler: WildcardHandler<Events>): void;

	emit<Key extends keyof Events>(type: Key, event: Events[Key]): void;
	emit<Key extends keyof Events>(
		type: undefined extends Events[Key] ? Key : never
	): void;
}
*/



// type EventHandlersCollection = Set<(...args: any[]) => void>;
type EventsMap = Map<EventType, EventHandlerList>;  // Record<string, EventHandlersCollection>;

type IPCEventsMap = Map<EventType, Array<IPCEventHandler>>;

type PromiseBooleanResolver = (value: boolean | PromiseLike<boolean>) => void;
type PromiseStringResolver = (value: string | PromiseLike<string>) => void;

interface PromiseCollectionEntry {
  resolve: PromiseBooleanResolver,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reject: (reason?: any) => void,
}

export default class AppController {

  /**
   * Static singleton instance of AppController
   */
  public static instance: AppController = new AppController();

  private log: Logger;

  get localEndpointId() {
    return this._windowId;
  }
  set localEndpointId(uniqueId: string | undefined) {
    if(!uniqueId) {
      throw new Error('localEndpointId should be set with a non empty string');
    }
    this._windowId = uniqueId;

    // Resolve any outstanding promises for a unique windowId.
    this._windowIdResolvers.forEach(promiseResolver => promiseResolver(uniqueId));
    this._windowIdResolvers = [];
  }
  private _windowId?: string;
  private _windowIdResolvers: PromiseStringResolver[] = [];

  private async promiseLocalEndpointId(){
    if(this.localEndpointId) {
      return Promise.resolve(this.localEndpointId);
    }

    return new Promise<string>((resolve, reject) => {
      this._windowIdResolvers.push(resolve);
    });
  }

  private _handlers: EventsMap;
  private _ipcHandlers: IPCEventsMap;
  private _ipcChannel: MessageChannel;
  private _broadcastChannel?: BroadcastChannel;
  private _messageDeduplicator: MessageDeduplicator;

  private constructor() {

    this.log = logger.child({
      childName: 'AppController',
    });

    this._messageDeduplicator = new MessageDeduplicator();

    //
    // WindowTracker will generate our unique browser window id.
    //
    this._windowId = WindowTracker.instance.windowId;
    if(this.localEndpointId) {
      logDecorator.set('senderEndpointId', this.localEndpointId);
      this.log.trace(`AppController._uniqueId = ${this.localEndpointId}`);
    }

    usePrompterSession.subscribe((state, prevState) => {
      if(state.instanceId === prevState.instanceId) {
        return;
      }

      this.localEndpointId = state.instanceId;
      logDecorator.set('senderEndpointId', this.localEndpointId);
    });

    this._endpointRole = EndpointRole.Unknown;

    this._handlers = new Map<EventType, EventHandlerList>();

    this._ipcHandlers = new Map();

    if('BroadcastChannel' in window) {  // BroadcastChannel is not defined in unit tests
      this._broadcastChannel = new BroadcastChannel('fluidprompter-controller');
      // this._broadcastChannel.addEventListener('message', this.handleBroadcastChannelEvent.bind(this));
    }

    this._websocketChannel = new WebsocketChannel(this);
    this._websocketChannel.addListener('connecting', this.handleWebsocketConnecting.bind(this));
    this._websocketChannel.addListener('connected', this.handleWebsocketConnected.bind(this));
    this._websocketChannel.addListener('disconnecting', this.handleWebsocketDisconnecting.bind(this));
    this._websocketChannel.addListener('disconnected', this.handleWebsocketDisconnected.bind(this));

    // Initialize the IPC Channel
    if(isReactNativeWebView()) {
      logger.debug('Using ReactWebViewChannel IPC channel (react native communication)');
      this._ipcChannel = new ReactWebViewChannel();

    } else {
      logger.debug('Using LocalRelay IPC channel (intra web thread communication)');
      this._ipcChannel = new LocalRelayChannel();
    }

    // When running as webapp, load the WebBluetoothProvider in the same process as the app
    if(!window.fluidprompterHost) {
      this._bluetoothWebIPCServerHandle = new BluetoothProviderWebIPCServer(this._ipcChannel);
    }

    // Initialize DeviceHost
    this._deviceHost = new DeviceHost(this, this._ipcChannel);

    //
    // Restore our previously configured logLevel, on page load, if saved.
    //
    const { logLevel, logLevelTimestamp, setLogLevel } = useConfigurationStore.getState();
    // Do we need to clear an aged out log level?
    // If our log level was last set more than 24 hours ago, let's clear it.
    if(
      logLevelTimestamp
        && (Date.now() - logLevelTimestamp) > (1000 * 60 * 60 * 24)
    ) {
      setLogLevel(undefined);
    } else if (logLevel) {
      this.logLevel = logLevel;
    }


    // osName = Windows, osVersion = 10, browserName = Chrome, browserVersion = 126, deviceType = browser
    // osName = Windows, osVersion = 10, browserName = Firefox, browserVersion = 113, deviceType = browser
    // Ben's iPad Pro reports: osName = Mac OS, osVersion = 10.15.7, browserName = Safari, browserVersion = 17, deviceType = browser
    // console.log(`osName = ${osName}, osVersion = ${osVersion}, browserName = ${browserName}, browserVersion = ${browserVersion}, deviceType = ${deviceType}`);

    // Safari on iPad reports itself as Mac OS, but the iPads will have a touchscreeen where a Mac desktop/laptop will not.
    this.osName = (osName === OsTypes.MAC_OS && navigator.maxTouchPoints > 1) ? 'iPadOS' : osName;
    this.osVersion = osVersion;
    this.browserName = browserName;
    this.browserVersion = browserVersion;

    //
    // Brave browser reports the exact same userAgent as Chrome (for compatibility and to avoid
    // fingerprinting by HTTP servers). We need to use JS API to detect Brave browser.
    //
    const isBraveBrowser = typeof navigator?.brave?.isBrave === 'function';
    if(isBraveBrowser) {
      this.browserName = 'Brave';
    }

    //
    // iPad reports the same userAgent as a desktop Mac (for compatibility and to avoid
    // fingerprinting by HTTP servers). `navigator.maxTouchPoints` will only evaluate with a
    // positive number on a touchscreen device - no Apple desktops have touchscreens today.
    //
    this.deviceType = isMobile ? (isTablet ? 'tablet' : 'phone') : 'desktop';
    if(osName === OsTypes.MAC_OS && navigator.maxTouchPoints > 1) {
      // iPad
      this.deviceType = 'tablet';
    }
  } // END constructor()

  public osName: string;
  public osVersion: string;
  public browserName: string;
  public browserVersion: string;
  public deviceType: string;

  get logLevel() {
    return logger.level();
  }
  set logLevel(levelNumber: number) {
    logger.level(levelNumber);

    // Pass logLevel changes down to other singleton objects.
    WindowTracker.instance.logLevel = levelNumber;
    this._websocketChannel.logLevel = levelNumber;
    this._deviceHost.logLevel = levelNumber;

    useConfigurationStore.getState().setLogLevel(levelNumber);
  }

  get deviceHost() {
    return this._deviceHost;
  }
  private _deviceHost: DeviceHost;

  private _bluetoothWebIPCServerHandle: BluetoothProviderWebIPCServer | undefined;

  get websocketConnectionState() {
    return this._websocketChannel.connectionState;
  }
  private _websocketChannel: WebsocketChannel;

  get windowFocused() {
    return this._windowFocused;
  }
  set windowFocused(value: boolean) {
    if(this._windowFocused === value) {
      // No change in value
      return;
    }

    this._windowFocused = value;
    this.checkDocumentVisibleAndFocused();
  }
  private _windowFocused = true;

  get documentVisible() {
    return this._documentVisible;
  }
  set documentVisible(value: boolean) {
    if(this._documentVisible === value) {
      // No change in value
      return;
    }
    this._documentVisible = value;
    this.checkDocumentVisibleAndFocused();
  }
  private _documentVisible = true;

  private checkDocumentVisibleAndFocused() {
    const proposedValue = this.documentVisible && this.windowFocused;
    if(this._documentVisibleAndFocused === proposedValue) {
      // No change in value
      return;
    }
    this._documentVisibleAndFocused = proposedValue;

    // _documentVisibleAndFocused changed!
    // this.log.trace(`documentVisibleAndFocused CHANGED to ${proposedValue})`);

    // if(this._documentVisibleAndFocused) {
    //   localStorage.setItem('lastEndpointId', this._uniqueId);
    // }
  }
  private _documentVisibleAndFocused = true;

  async handleWindowFocus(e: FocusEvent) {
    this.windowFocused = true;
    // this.log.trace(`handleWindowFocus(window.focused = ${this._windowFocused})`);
  }

  async handleWindowBlur(e: FocusEvent) {
    this.windowFocused = false;
    // this.log.trace(`handleWindowBlur(window.focused = ${this._windowFocused})`);
  }

  async handleWindowPageShow(e: PageTransitionEvent) {
    this.windowFocused = document.hasFocus();
    this.documentVisible = (document.visibilityState === 'visible');
    // this.log.trace(`handleWindowPageShow(window.focused = ${this.windowFocused}, document.visible = ${this.documentVisible})`);
  }

  async handleDocumentVisibilityChange(e: Event) {
    // "hidden" | "visible"
    this.documentVisible = (document.visibilityState === 'visible');
    // this.log.trace(`handleDocumentVisibilityChange(document.visible = ${this._documentVisible})`);

    //
    // If `visibilityState` is becoming `hidden`, then let's tell our backend. The backend will
    // ping this endpoint on its websocket to see if the websocket is already dead or not.
    //
    // In a page refresh or browser navigation, the webSocket will be destroyed.
    // If we just "minimized" the browser but still have FP open, then the webSocket is likely
    // still alive.
    //
    /*
    if(!this.documentVisible) {
      const prompterId = usePrompterSession.getState().prompterId;
      const beaconUrl = new URL(process.env.REACT_APP_API_URL || window.location.origin);
      if(
        'localhost' === window.location.hostname
        || '127.0.0.1' === window.location.hostname
        || window.location.hostname.startsWith('192.168')
      ) {
        beaconUrl.hostname = window.location.hostname;
      }
      beaconUrl.pathname = `/api/prompter/${prompterId}/endpoint/${this.localEndpointId}/hidden`;

      navigator.sendBeacon(beaconUrl);
    }
    */
  }

  set endpointRole(role: EndpointRole) {
    if(this._endpointRole === role) {
      // Don't do anything if set to the existing value.
      return;
    }

    this._endpointRole = role;
  }
  get endpointRole() {
    return this._endpointRole;
  }
  private _endpointRole: EndpointRole;

  set peerNumber(peerNumber: number) {
    this._peerNumber = peerNumber;
  }
  get peerNumber() {
    return this._peerNumber;
  }
  private _peerNumber = 0;

  set rtcConfig(value: RTCConfiguration) {
    this._rtcConfig = value;
  }
  get rtcConfig() {
    return this._rtcConfig;
  }
  private _rtcConfig: RTCConfiguration = {};

  set appLifecycleState(lifecycleState: AppLifecycleState) {
    // This is just a debounce so we don't update state to the same value due to multiple events
    // firing that result in the same proposed AppLifecycleState.
    if(this._appLifecycleState === lifecycleState) {
      return;
    }

    // console.log(`AppLifecycleState changed from ${this._appLifecycleState} to ${lifecycleState}`);
    this._appLifecycleState = lifecycleState;

    // TODO: fire an event? How does WebsocketChannel get updated?
    // Currently WebsocketChannel duplicates this mechanism.
    // this._websocketChannel.

    if(!this.localEndpointId) {
      return;
    }

    // Dispatch an app message to update connected peers on our current AppLifecycleState.
    this.dispatchMessage(new LifecycleMessage(this.localEndpointId, this._appLifecycleState));
  }
  get appLifecycleState() {
    return this._appLifecycleState;
  }
  private _appLifecycleState = AppLifecycleState.Active;

  /**
   * We can save our last known cloud connection latency here to report to other peer devices.
   */
  public localCloudLatency: number | undefined;

  /**
   * Setter to inform the AppController when we become offline or online.
   * We will use this information to trigger WebSocket reconnect logic.
   * @param isOnline
   */
  setIsOnline(isOnline: boolean) {
    this._websocketChannel.setOnline(isOnline);
  }

  private propogateLocalCloudConnectionState(newConnectionState: ConnectionState) {
    if(newConnectionState !== ConnectionState.Connected) {
      this.localCloudLatency = undefined;
    }

    this.deviceHost
      .allDevices<PrompterPeerInstance>(DeviceConnectionType.Network)
      .forEach((peerDevice) => {
        peerDevice.setLocalCloudLatency(this.localCloudLatency || 0);
        peerDevice.setLocalCloudConnectionState(newConnectionState);
      });
  }

  private handleWebsocketConnecting(e: Event) {
    this.log.trace('Websocket Connecting');

    this.propogateLocalCloudConnectionState(ConnectionState.Connecting);
  }

  private handleWebsocketConnected(e: Event) {
    this.log.trace('Websocket Connected');

    this.propogateLocalCloudConnectionState(ConnectionState.Connected);
  }

  private handleWebsocketDisconnecting(e: Event) {
    this.log.trace('Websocket Disconnecting');

    this.propogateLocalCloudConnectionState(ConnectionState.Disconnecting);
  }

  private handleWebsocketDisconnected(e: Event) {
    this.log.trace('Websocket Disconnected');

    this.propogateLocalCloudConnectionState(ConnectionState.Disconnected);
  }

  // MessageType extends keyof AppMessageMap
  // on<T extends BaseControlMessage>(type: EventType, handler: Handler<T>): void {
  on<MessageType extends keyof AppMessageMap>(type: MessageType, handler: Handler<AppMessageMap[MessageType]>): void {
    const handlers: EventHandlerList<AppMessageMap[MessageType]> | undefined = this._handlers.get(type);
    if (handlers) {
      handlers.push(handler);
    } else {
      this._handlers.set(type, [handler] as EventHandlerList);
    }
  }

  //off<T extends BaseControlMessage>(type: EventType, handler: Handler<T>): void {
  off<MessageType extends keyof AppMessageMap>(type: MessageType, handler: Handler<AppMessageMap[MessageType]>): void {
    const handlers: EventHandlerList<AppMessageMap[MessageType]> | undefined = this._handlers.get(type);
    if (handlers) {
      if (handler) {
        handlers.splice(handlers.indexOf(handler) >>> 0, 1);
      } else {
        this._handlers.set(type, []);
      }
    }
  }

  /*
  emit<T = IControlMessage>(type: EventType, event?: T): void {
    const handlers = this._handlers.get(type);
    if (handlers) {
      (handlers as EventHandlerList<T>)
        .slice()
        .map((handler) => {
          handler(event!);
        });
    }

    // handlers = this._handlers.get('*');
    // if (handlers) {
    //   (handlers as WildCardEventHandlerList<Events>)
    //     .slice()
    //     .map((handler) => {
    //       handler(type, evt!);
    //     });
    // }
  }
  */

  private getSequenceNumber() {
    this._lastSequenceNumber = (this._lastSequenceNumber < Number.MAX_SAFE_INTEGER)
      ? this._lastSequenceNumber + 1
      : 1;

    return this._lastSequenceNumber;
  }
  private _lastSequenceNumber = 0;

  /**
   * Gather the SenderInfo for this prompter's current state.
   * TODO: This can be moved to a utility function outside AppController
   * @returns
   */
  public async getSenderInfo(): Promise<SenderInfo> {
    const localEndpointId = await this.promiseLocalEndpointId();

    const { userScrollSpeed, scrollReversed } = useConfigurationStore.getState();
    const { prompterMode, viewportMeta, scriptNodesMeta, scrollPosition, scriptNodesState } = usePrompterSession.getState();

    const senderInfo = new SenderInfo(
      localEndpointId,
      this.getSequenceNumber(),  // sq - a sequence number for messages dispatched from this sender.
      this.endpointRole,                // r
      prompterMode,     // m
    );
    senderInfo.scriptPosition = scriptNodesState?.scriptPosition;
    senderInfo.scrollPosition = scrollPosition;         // sp
    senderInfo.scrollSpeed = userScrollSpeed;                                             // s
    senderInfo.scrollReversed = scrollReversed;                                           // sr
    senderInfo.viewportHeight = viewportMeta.viewportHeight;          // vh
    senderInfo.contentHeight = scriptNodesMeta?.contentHeight;  // ch
    return senderInfo;
  }

  /**
   * Get
   * @returns
   */
  public getLocalEndpointDescription() {
    const thisEndpoint = new PrompterSessionEndpoint();
    thisEndpoint.endpointId = this.localEndpointId;
    thisEndpoint.role = this.endpointRole;
    thisEndpoint.peerNumber = this.peerNumber;
    // TODO: thisEndpoint.endpointName = '';
    thisEndpoint.cloudLatency = this.localCloudLatency;
    thisEndpoint.logLevel = logger.level();

    thisEndpoint.osName = this.osName;
    thisEndpoint.osVersion = this.osVersion;
    thisEndpoint.browserName = this.browserName;
    thisEndpoint.browserVersion = this.browserVersion;
    thisEndpoint.deviceType = this.deviceType;

    //
    // Include information about this local prompter, such as screen size, viewport size or
    // other device info. Maybe also send content layout info such as content height/width which
    // can be used to detect prompters that have radically different number of words per line.
    //
    const prompterState = usePrompterSession.getState();
    const { viewportMeta, scriptNodesMeta } = prompterState;
    thisEndpoint.viewportWidth = viewportMeta.viewportWidth;
    thisEndpoint.viewportHeight = viewportMeta.availableViewportHeight; // This is the viewable prompter content height which may be smaller than the full browser viewport height if another UI element such as MediaPanel is open.
    if(scriptNodesMeta) {
      thisEndpoint.contentWidth = scriptNodesMeta.contentWidth;
      thisEndpoint.contentHeight = scriptNodesMeta.contentHeight;
      thisEndpoint.lineHeight = scriptNodesMeta.lineHeight;
    }

    return thisEndpoint;
  }

  sendToServer(input: BaseControlMessage) {
    this._websocketChannel.sendMessage(input);
  }

  // Overload signatures
  async dispatchMessage<T extends BaseControlMessage>(message: T, targetEndpointId?: string): Promise<void>;
  async dispatchMessage(eventType: string, payload?: unknown): Promise<void>;
  /**
   * dispatch is called when this local instance of FluidPrompter generates an control message due
   * to human input or a state change while prompting.
   * @param message Any instance of BaseAppMessage
   */
  async dispatchMessage(input: BaseControlMessage | string, payload?: unknown) {
    const message = (typeof input === 'string') ? new GenericMessage(input, payload) : input;
    const targetEndpointId = (typeof input === 'string') ? undefined : payload as string;
    // console.log(`dispatchMessage(${message.type})`);

    return this.dispatchMessages([message], targetEndpointId);
  }

  /**
   * dispath a collection of messages at one time.
   * @param messageBatch An array of app messages
   */
  async dispatchMessages(messageBatch: BaseControlMessage[], targetEndpointId?: string) {
    if(!messageBatch.length) {
      return;
    }

    // console.log('++++++++++');

    //
    // Augment Message with additional meta data.
    // Meta data may include current prompter state information including current script position.
    //
    // message.sender = {
    //   id: this._uniqueId,
    //   sequence: this.getSequenceNumber(),  // sq - a sequence number for messages dispatched from this sender.
    //   role: this.endpointRole,                // r
    //   mode: prompterSession.prompterMode,     // m
    //   scriptPosition,                         // px
    //   scrollPosition: prompterSession.scriptNodesState?.scrollPosition, // sp
    //   scrollSpeed: userScrollSpeed,           // ss
    //   viewportHeight: prompterSession.scriptNodesMeta?.viewportHeight,  // vh
    //   contentHeight: prompterSession.scriptNodesMeta?.contentHeight // ch
    // };
    // const senderInfo = new SenderInfo(
    //   this._uniqueId,
    //   this.getSequenceNumber(),  // sq - a sequence number for messages dispatched from this sender.
    //   this.endpointRole,                // r
    //   prompterMode,     // m
    // );
    // senderInfo.scriptPosition = scriptNodesState?.scriptPosition;
    // senderInfo.scrollPosition = scrollPosition;         // sp
    // senderInfo.scrollSpeed = userScrollSpeed;                                             // s
    // senderInfo.scrollReversed = scrollReversed;                                           // sr
    // senderInfo.viewportHeight = scriptNodesMeta?.viewportHeight;          // vh
    // senderInfo.contentHeight = scriptNodesMeta?.contentHeight;  // ch

    // TEMP: TODO: We should be able to transmit a group of messages as a single batch and avoid sending duplicate SenderInfo.


    //
    // 1. Handle each message locally first. Each local handler will have the opportunity to change
    // whether that message is sent to peers or not.
    //
    const senderInfo = await this.getSenderInfo();
    const batchContext = new MessageContext(this, messageBatch);
    for (const appMessage of batchContext) {
      // console.log(`Process batch message '${appMessage?.type}' in batch of ${messageBatch.length}`, appMessage);
      if(!appMessage.sender) {
        appMessage.sender = senderInfo;
      }

      this.handleMessage(appMessage, batchContext);
    }

    //
    // 2. Transmit any messages in the current batch that are marked with sendToPeers = true
    //
    const messagesToTransmit = batchContext.messagesToTransmit();
    if(messagesToTransmit.length) {
      //
      // We just sent this message to peers (follower prompters). We need to remember the last time
      // we sent syncronization information to ensure a minimum frequency of syncronization events.
      //
      // TODO: Save timestamp of the last time we sent prompter syncronization information to followers. This will be used to ensure we send sync information at least every 1 second or so.
      //
      this.handleMessage(new GenericMessage('sync.updatelastsend'), batchContext);

      //
      // Serialize our batch to JSON, then chunk it up into 16kb binary payloads for transmission.
      //
      // console.log(`Transmit ${messagesToTransmit.length}/${messageBatch.length} message in batch.`, messagesToTransmit);
      const messageCollection = new BinarySender(senderInfo.id, messagesToTransmit, targetEndpointId);
      const binaryChunks = messageCollection.toBinaryChunks();
      for(const binaryChunk of binaryChunks) {
        // Send to peers via WebSocket!
        this._websocketChannel.sendBinary(binaryChunk);

        // Send to peers via WebRTC!
        // TODO:
      }
    }

    //
    // OLD WAY - will be removed when binary chunk transmission is done.
    // 2. Transmit any messages that are marked with sendToPeers = true
    //
    /*
    for(let i = 0; i < messagesToTransmit.length; i++) {
      const message = messagesToTransmit[i];

      //
      // Transmit message to other browser tabs/windows on this device.
      // const msgJson = JSON.stringify(message);
      // this._broadcastChannel.postMessage(msgJson);
      // const randomLatency = Math.floor(Math.random() * (250 - 10 + 1) + 10);
      // const randomLatency = 0;
      // setTimeout(() => {
      //   this._broadcastChannel.postMessage(msgJson);
      // }, randomLatency);

      // Send to peers via WebSocket!
      this._websocketChannel.sendMessage(message);
    }
    */
    // console.log('----------');

  }

  /**
   * Handle message is called when this local instance of FluidPrompter needs to process an
   * AppMessage whether that message originated locally or remotely.
   */
  private handleMessage<T extends BaseControlMessage>(message: T, batchContext: MessageContext) {
    // const contextMessage = new MessageContext(this, [message], originatedRemotely);

    //
    // If this is a duplicate message, we will return the context which defaults to having
    // `sendToPeers = false` and no further action will be taken with this message.
    //
    if(batchContext.originatedRemotely && this._messageDeduplicator.isDuplicateMessage(message, batchContext.remoteSource)) {
      return;
    }

    //
    // First fire the regular event handlers.
    //
    let handlers = this._handlers.get(message.type);
    if (handlers) {
      // console.log(`handleMessage(${message.messageType}), ${handlers?.length} handlers`);
      // Process the current message
      (handlers as EventHandlerList<T>)
        .slice()
        .map((handler) => {
          handler(new MessageHandlerEvent(message, batchContext, this));
        });
    }

    //
    // Now fire the wildcard event handlers (mostly for websockets/webrtc to sync with other
    // prompters).
    //
    handlers = this._handlers.get('*');
    if (handlers) {
      // console.log(`handleMessage(${message.type}), ${handlers?.length} wildcard handlers`);
      // Process the current message
      (handlers as EventHandlerList<T>)
        .slice()
        .map((handler) => {
          handler(new MessageHandlerEvent(message, batchContext, this));
        });
    }

    return;
  }

  handleLocalMessage<T extends BaseControlMessage>(message: T, batchContext?: MessageContext) {
    // , originatedRemotely?: boolean, remoteSource?: string
    const context = batchContext || new MessageContext(this, [message], false, 'local');
    this.handleMessage(message, context);
  }

  handleWebsocketMessage<T extends BaseControlMessage>(message: T, batchContext?: MessageContext) {
    const context = batchContext || new MessageContext(this, [message], true, 'websocket');
    this.handleMessage(message, context);
  }

  handlePeerMessage<T extends BaseControlMessage>(message: T, batchContext?: MessageContext) {
    const context = batchContext || new MessageContext(this, [message], true, 'datachannel');
    this.handleMessage(message, context);
  }

  verifyEndpointIdIsConnectedToCloud(targetEndpointId: string): Promise<boolean> {

    return new Promise<boolean>((_resolve, _reject) => {
      const sendPeerPing = async () => {
        this.log.trace(`AppController.verifyEndpointIdIsConnectedToCloud(${targetEndpointId}) sendPeerPing()`);

        // We are sending directly on the websocket which also avoid the binary serialization
        // of generic messages - for now. Perhaps in future we can enahnce API to handle peer ping
        // with binary serialization.
        const peerPingMsg = new PeerPingMessage(targetEndpointId);
        peerPingMsg.sender = await this.getSenderInfo();
        this.sendToServer(peerPingMsg);

        // TODO: In future, this would use binary serialization
        // this.dispatchMessage(new PeerPingMessage(targetEndpointId), targetEndpointId);
      };

      const sendPeerPingInterval = setInterval(sendPeerPing, 3000);

      //
      // The wrapped resolver/rejecter will take care of clean-up in addition to resolving the
      // outstanding promise.
      //
      const wrappedResolver: PromiseBooleanResolver = (value: boolean | PromiseLike<boolean>) => {
        clearInterval(sendPeerPingInterval);
        clearTimeout(promiseTimeout);
        removeResolver();
        _resolve(value);
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const wrappedRejecter = (reason?: any) => {
        clearInterval(sendPeerPingInterval);
        clearTimeout(promiseTimeout);
        removeResolver();
        _reject(reason);
      };
      const promiseEntry: PromiseCollectionEntry = {
        resolve: wrappedResolver,
        reject: wrappedRejecter,
      };

      //
      // Add the resolver to our Map of resolvers for a given endpointId
      //
      let resolverCollection = this._endpointConnectedPromises.get(targetEndpointId);
      if(!resolverCollection) {
        resolverCollection = [];
        this._endpointConnectedPromises.set(targetEndpointId, resolverCollection);
      }
      resolverCollection.push(promiseEntry);
      this.log.trace(`AppController.verifyEndpointIdIsConnectedToCloud(${targetEndpointId}) added promise, new count=${resolverCollection.length}`);

      //
      //
      //
      const removeResolver = () => {
        const existingResolverCollection = this._endpointConnectedPromises.get(targetEndpointId);
        if(existingResolverCollection) {
          const newResolverCollection = existingResolverCollection.filter((e, i) => e !== promiseEntry);
          if(newResolverCollection.length < existingResolverCollection.length) {
            this._endpointConnectedPromises.set(targetEndpointId, newResolverCollection);
            this.log.trace(`AppController.verifyEndpointIdIsConnectedToCloud(${targetEndpointId}) removed promise, new count=${newResolverCollection.length}`);
          }
        }
      };

      //
      // Safety, just in case we never get any definitive response...
      //
      const promiseTimeout = setTimeout(() => {
        wrappedRejecter(new Error(`AppController.verifyEndpointIdIsConnectedToCloud(${targetEndpointId}) Timeout`));
      }, 6000);

      //
      // If the websocket is not currently connected, we cannot send or receiving signaling messages.
      //
      if(this.websocketConnectionState !== ConnectionState.Connected) {
        wrappedRejecter(new Error(`AppController.verifyEndpointIdIsConnectedToCloud(${targetEndpointId}) websocketConnectionState('${this.websocketConnectionState}') is not Connected`));
        return;
      }

      //
      // If the browser is offline we have no network connectivity
      //
      if(!window.navigator.onLine) {
        wrappedRejecter(new Error(`AppController.verifyEndpointIdIsConnectedToCloud(${targetEndpointId}) Navigator is Offline`));
        return;
      }

      //
      // TODO: We want to ping the targetEndpointId via WebSocket through backend. If we get a response from that endpoint it validates both peers are connected with WebSocket to this session.
      //
      // Send to Server: `peer.ping`
      // If Server has no peer: `peer.notfound`
      // If Server has peer, forward to peer
      // Peer responds: `peer.pong`
      //
      sendPeerPing();
    });
  }
  resolveEndpointIdIsConnectedToCloud(targetEndpointId: string, isConnectedToCloud: boolean) {
    const existingResolverCollection = this._endpointConnectedPromises.get(targetEndpointId);
    if(existingResolverCollection) {
      existingResolverCollection.forEach(m => m.resolve(isConnectedToCloud));

      this.log.info(`AppController.resolveEndpointIdIsConnectedToCloud(${targetEndpointId}) - all ${existingResolverCollection.length} promises resolved!`);
    }
  }
  rejectEndpointIdIsConnectedToCloud(targetEndpointId: string) {
    const existingResolverCollection = this._endpointConnectedPromises.get(targetEndpointId);
    if(existingResolverCollection) {
      existingResolverCollection.forEach(m => m.reject(new Error('AppController.rejectEndpointIdIsConnectedToCloud()')));

      this.log.info(`AppController.rejectEndpointIdIsConnectedToCloud(${targetEndpointId}) - all ${existingResolverCollection.length} promises rejected!`);
    }
  }
  private _endpointConnectedPromises: Map<string, PromiseCollectionEntry[]> = new Map<string, PromiseCollectionEntry[]>();

  setLeaderEndpointId(proposedLeaderEndpointId: string) {
    // De-duplicate if the leader didn't change?
    const prompterState = usePrompterSession.getState();
    if(proposedLeaderEndpointId === prompterState.currentLeaderId) {
      this.log.trace(`setLeaderEndpointId(proposedLeaderEndpointId === prompterState.currentLeaderId) ${proposedLeaderEndpointId} === ${prompterState.currentLeaderId}, returning early`);
      return;
    }

    // Update our list of peers with the currently designated leader EndpointId.
    this.deviceHost
      .allDevices<PrompterPeerInstance>(DeviceConnectionType.Network)
      .forEach((peerDevice) => {
        peerDevice.handleLeaderDesignation(proposedLeaderEndpointId);
      });

    // Update our PrompterSessionState
    prompterState.setCurrentLeaderId(proposedLeaderEndpointId);
  }

  setLeaderIsSelf() {
    if(!this.localEndpointId) {
      throw new Error('Error missing AppController.uniqueId');
    }

    this.setLeaderEndpointId(this.localEndpointId);
  }

  /*
   * TODO: Delete this method.
   *
  settleOpenPromisesForEndpointId(deviceId: string, receivedPeerPong: boolean) {
    //
    // If we just registered a PrompterPeerInstance and there was any unresolved promise(s) waiting
    // for this PrompterPeerInstace, then resolve those promises now.
    //
    // ex: If we receive an SdpMessage without having an existing PrompterPeerInstance, it will
    // await a promised PrompterPeerInstance.
    //
    if(this._endpointConnectedPromises.has(deviceId)) {
      const peerPromiseResolvers = this._endpointConnectedPromises.get(deviceId);
      if(peerPromiseResolvers?.length) {
        peerPromiseResolvers.map(promise => receivedPeerPong ? promise.resolve(true) : promise.resolve(false));
      }
      this._endpointConnectedPromises.delete(deviceId);
    }
  }
  */

  // resolverFn: (value: boolean | PromiseLike<boolean>) => void

  /**
   * This handler will be called whenever the webapp receives a message from a native host app
   * ie: iOS, Android, Windows, Mac.
   * @param msg The message received from the native host app.
   * @returns {void}
   */
  handleIPCMessage(msg: string) {
    if (!(this._ipcChannel instanceof ReactWebViewChannel)) {
      throw new Error('Unexpected channel type. When handling react native IPC messages only ReactWebViewChannel is supported.');
    }

    this._ipcChannel.getMessageHandler()(msg);
  }
}
