import React from 'react';

import useConfigurationStore, { MediaPanelPosition } from '../../state/ConfigurationStore';
import usePrompterSession, { MediaSourceType } from '../../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';

import { isIOS, isTablet } from 'react-device-detect';
import FocusGuide from '../../models/FocusGuide';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface IViewportDimensions {
  topOffset: number;
  leftOffset: number;
  rightOffset: number;
  bottomOffset: number;
  width: number;
  height: number;
  //
  contentWidth: number;
  leftGutter: number;
  rightGutter: number;
  prompterMaskTopHeight: string;
  prompterMaskTopOffset: string;
  prompterMaskBottomHeight: string;
  prompterMaskBottomOffset: string;
  prompterFocusTop: string;
  prompterFocusBottom: string;
}

function useViewportDimensions(): IViewportDimensions {
  const theme = useTheme();
  const viewportIsMediumOrLarger = useMediaQuery(theme.breakpoints.up('sm'));

  const configStore = useConfigurationStore(state => ({
    // textSize: state.textSize,
    // lineHeight: state.lineHeight,
    contentWidth: state.contentWidth,
    leftGutter: state.leftGutter,
    rightGutter: state.rightGutter,
    // backgroundColor: state.backgroundColor,
    // textColor: state.textColor,
    flipHorizontal: state.flipHorizontal,
    flipVertical: state.flipVertical,
    focusGuide: state.focusGuide,
    cuePositionPercentage: state.cuePositionPercentage,
    mediaPanelPosition: state.mediaPanelPosition,
    mediaPanelWidth: state.mediaPanelWidth,
    mediaPanelHeight: state.mediaPanelHeight,
  }), shallow);

  const {
    mediaPanelPosition,
    mediaPanelWidth,
    mediaPanelHeight,
    //
    focusGuide: focusGuidePosition,
    cuePositionPercentage,
    flipHorizontal,
    flipVertical,
  } = configStore;

  let adjustedMediaPanelPosition = mediaPanelPosition;
  if(flipHorizontal) {
    switch(mediaPanelPosition) {
      case MediaPanelPosition.Left:
        adjustedMediaPanelPosition = MediaPanelPosition.Right;
        break;
      case MediaPanelPosition.Right:
        adjustedMediaPanelPosition = MediaPanelPosition.Left;
        break;
    }
  }
  if(flipVertical) {
    switch(mediaPanelPosition) {
      case MediaPanelPosition.Top:
        adjustedMediaPanelPosition = MediaPanelPosition.Bottom;
        break;
      case MediaPanelPosition.Bottom:
        adjustedMediaPanelPosition = MediaPanelPosition.Top;
        break;
    }
  }

  const prompterSession = usePrompterSession(state => ({
    // prompterMode: state.prompterMode,
    isPlaying: state.isPlaying,
    isPaused: state.isPaused,
    isEditing: state.isEditing,
    play: state.play,
    pause: state.pause,
    edit: state.edit,
    // isLeader: state.isLeader,
    isBlanking: state.isBlanking,

    mediaSourceType: state.mediaSourceType,
  }), shallow);

  //
  // If we have a portion of the viewport taken up by the media panel, we need to account for that
  // while positioning our focus guide and cue position.
  //
  const availableRect = {
    topOffset: 0,
    leftOffset: 0,
    rightOffset: 0,
    bottomOffset: 0,
    width: 100,
    height: 100,
  };

  //
  // Reduce the availableRect viewport width/height by the size taken up by the media panel and
  // record the appropriate offet for the viewport position.
  //
  if(prompterSession.mediaSourceType !== MediaSourceType.None) {
    switch(adjustedMediaPanelPosition) {
      case MediaPanelPosition.Top:
        availableRect.topOffset = mediaPanelHeight;
        availableRect.height = 100 - mediaPanelHeight;
        break;
      case MediaPanelPosition.Bottom:
        availableRect.bottomOffset = mediaPanelHeight;
        availableRect.height = 100 - mediaPanelHeight;
        break;
      case MediaPanelPosition.Left:
        availableRect.leftOffset = mediaPanelWidth;
        availableRect.width = 100 - mediaPanelWidth;
        break;
      case MediaPanelPosition.Right:
        availableRect.rightOffset = mediaPanelWidth;
        availableRect.width = 100 - mediaPanelWidth;
        break;
    }
  }

  //
  // Some special accomodations for iPad which puts a small 13px overlay at the bottom of the
  // viewport, obstructing the UI if we don't offset our positioning.
  //
  const defaultAppBarHeight = 64;
  const viewportPortMarginBottom = 13;  // If we are on iPad, let's add some bottom margin to make room for the drag handle.
  const prompterMaskBottomHeightCollapsed = defaultAppBarHeight
    + (
      isIOS
        && isTablet
        && (adjustedMediaPanelPosition !== MediaPanelPosition.Bottom) // If we have the media panel at the bottom of the viewport, we don't need the 13px margin on iPad.
        ? viewportPortMarginBottom
        : 0
    );

  //
  // Calculate our Focus Area position values given the `cuePositionPercentage`.
  //
  const focusAreaHeightPercentage = focusGuidePosition === FocusGuide.None ? 1.0 : 0.4;  // This may become adjustable/configurable in future
  const halfOfFocusAreaHeightPercentage = focusAreaHeightPercentage / 2;
  const prompterMaskTotalHeightPercentage = 1 - focusAreaHeightPercentage;

  // The Cue Position could be at the very top pixel or very bottom pixel of the viewport.
  // The Focus area can therefor be clipped above or below the viewport.
  const hiddenFocusAreaClippedTopPercentage = Math.max(0, halfOfFocusAreaHeightPercentage - (cuePositionPercentage / 100));
  const hiddenFocusAreaClippedBottomPercentage = Math.max(0, ((cuePositionPercentage / 100) + halfOfFocusAreaHeightPercentage - 1));
  const visibleFocusAreaHeightPercentage = focusAreaHeightPercentage - hiddenFocusAreaClippedTopPercentage - hiddenFocusAreaClippedBottomPercentage;
  // console.log(`cuePositionPercentage: ${cuePositionPercentage}, visibleFocusAreaHeight: ${visibleFocusAreaHeight}, hiddenFocusAreaTopPercentage: ${hiddenFocusAreaTopPercentage}, hiddenFocusAreaBottomPercentage: ${hiddenFocusAreaBottomPercentage}`);


  const virtualTopMaskHeightPercentage = (cuePositionPercentage / 100) - halfOfFocusAreaHeightPercentage;
  const virtualBottomMaskHeightPercentage = prompterMaskTotalHeightPercentage - virtualTopMaskHeightPercentage;

  // If we are flipping the viewport vertically then we will swap the top and bottom mask heights
  const topMaskHeightPercentage = flipVertical ? virtualBottomMaskHeightPercentage : virtualTopMaskHeightPercentage;
  const bottomMaskHeightPercentage = flipVertical ? virtualTopMaskHeightPercentage : virtualBottomMaskHeightPercentage;

  //
  // Decide where the prompter masks should be.
  //
  const prompterFocusTop = `${Math.round(availableRect.height * topMaskHeightPercentage) + availableRect.topOffset}%`;
  const topOffsetPx = Math.round(window.innerHeight * availableRect.topOffset / 100);
  const prompterMaskTopHeight = prompterSession.isEditing
    ? `${64 + topOffsetPx}px`
    : prompterFocusTop;
  const prompterMaskTopOffset = prompterSession.isEditing
    ? `${64 + topOffsetPx}px`
    : '100%';

  const prompterFocusBottom = `${Math.round(availableRect.height * bottomMaskHeightPercentage) + availableRect.bottomOffset}%`;
  const bottomOffsetPx = Math.round(window.innerHeight * availableRect.bottomOffset / 100);
  const prompterMaskBottomHeight = prompterSession.isEditing
    ? `${prompterMaskBottomHeightCollapsed + bottomOffsetPx}px`
    : prompterFocusBottom;
  const prompterMaskBottomOffset = prompterSession.isEditing
    ? `-${prompterMaskBottomHeightCollapsed + bottomOffsetPx}px`
    : '-100%';

  // On small viewports (ex: portrait mobile phone), we will always show content at 100% width.
  // It's just too small to ever need margin around the prompter content.
  const contentWidth = viewportIsMediumOrLarger
    ? (availableRect.width * configStore.contentWidth / 100)
    : 100;
  const leftGutter = viewportIsMediumOrLarger
    ? (availableRect.width * configStore.leftGutter / 100)
      + availableRect.leftOffset
    : 0;
  const rightGutter = viewportIsMediumOrLarger
    ? (availableRect.width * configStore.rightGutter / 100)
      + availableRect.rightOffset
    : 0;

  return {
    topOffset: availableRect.topOffset,
    leftOffset: availableRect.leftOffset,
    rightOffset: availableRect.rightOffset,
    bottomOffset: availableRect.bottomOffset,
    width: availableRect.width,
    height: availableRect.height,
    //
    contentWidth,
    leftGutter,
    rightGutter,
    //
    prompterMaskTopHeight,
    prompterMaskTopOffset,
    prompterMaskBottomHeight,
    prompterMaskBottomOffset,
    //
    prompterFocusTop,
    prompterFocusBottom,
  };
}

export default useViewportDimensions;