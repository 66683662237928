import React from 'react';
import Navbar from '../Navbar';
import PrompterHudLeft from '../PrompterHudLeft';
import PrompterHudRight from '../PrompterHudRight';
import ControlBar from '../ControlBar';

import useConfigurationStore from '../../state/ConfigurationStore';
import useViewportDimensions from '../PrompterViewport/useViewportDimensions';

import './PrompterViewportUIContainer.scss';

function PrompterViewportUIContainer() {

  const showStats = useConfigurationStore(state => state.showStats);

  //
  // This is the new way to calculate viewport dimensions
  //
  const {
    topOffset,
    leftOffset,
    rightOffset,
    bottomOffset,
    width,
    height,
  } = useViewportDimensions();

  return (<div
    className='PrompterViewportUIContainer'
    style={{
      top: `${topOffset}vh`,
      left: `${leftOffset}vw`,
      right: `${rightOffset}vw`,
      bottom: `${bottomOffset}vh`,
    }}
  >
    {/* <div className='NoticeBarContainer'>Notice Bars</div> */}
    <div className='NavbarContainer'>
      <Navbar />
    </div>
    <div className='LeftHUDContainer'>
      <PrompterHudLeft />
    </div>
    {showStats && <div className='RightHUDContainer'>
      <PrompterHudRight />
    </div>}
    <div className='ControlBarContainer'>
      <ControlBar />
    </div>
  </div>);
}

export default PrompterViewportUIContainer;