import React from 'react';
import List from '@mui/material/List';
import TocEntryRenderer from './TocEntryRenderer';

import { ElementTypes, PrompterElement, PrompterSegment } from '../../models/EditorTypes';

import usePrompterSession from '../../state/PrompterSessionState';
import { useAppController } from '../../controllers/AppController';
import { NavigateMessage } from '@fluidprompter/core';

import './PrompterHudToc.scss';

const PrompterHudToc = React.memo(function PrompterHudToc() {

  const ref = React.createRef<HTMLDivElement>();

  const scriptNodes = usePrompterSession(state => state.scriptNodes);

  const appController = useAppController();

  const handleListItemClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number,
  ) => {
    appController.dispatchMessage('prompter.local.HideCursorTimer.Restart');
    appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.Position, {
      segmentIndex: index
    }));
  };

  const scrollToActive = React.useCallback((currentScriptNodeIndex: number) => {
    const scrollEl = ref.current;
    if(!scrollEl) {
      return;
    }

    // Figure out the scroll offset for the currently active node.
    const nodeCount = scriptNodes.length;
    const scrollHeight = scrollEl.scrollHeight; // Total content height
    const nodeHeight = scrollHeight / nodeCount;

    const targetScrollPosition = ((currentScriptNodeIndex) * nodeHeight);

    scrollEl.scroll({
      top: targetScrollPosition,
      behavior: 'smooth',
    });
  }, [ref, scriptNodes]);

  //
  // To minimize React DOM changes, we don't want to re-render the entire PrompterHudToc component
  // when the the current node changes. Instead we can subscribe to PrompterSession state changes
  // and scroll the ToC without re-rendering using a DOM element ref.
  //
  React.useEffect(() => {
    const unsubscribe = usePrompterSession.subscribe(
      (config, previousConfig) => {
        if(config.currentScriptNodeIndex === previousConfig.currentScriptNodeIndex) {
          return;
        }

        scrollToActive(config.currentScriptNodeIndex);
      }
    );

    return unsubscribe;
  }, [scrollToActive]);

  //
  // On initial component mount, scroll to the currently active ToC entry.
  // This component may mount/unmount through app lifecycle if user changes HUD widgets.
  //
  React.useEffect(() => {
    const currentScriptNodeIndex = usePrompterSession.getState().currentScriptNodeIndex;
    scrollToActive(currentScriptNodeIndex);
  }, [scrollToActive]);

  return (<div ref={ref} className="PrompterHudTocContainer invisible-scrollbars">
    <List
      component="nav"
      disablePadding
      dense={true}
      className="PrompterHudToc"
    >
      {scriptNodes.map((prompterSegment, segmentIndex) => {
        const segmentType = (prompterSegment as PrompterElement).type || 'text';

        let segmentTitle = '';
        switch(segmentType) {
          case ElementTypes.STARTSCRIPT:
            segmentTitle = 'Start of Script';
            break;
          case ElementTypes.ENDSCRIPT:
            segmentTitle = 'End of Script';
            break;
          case ElementTypes.PAUSE:
            segmentTitle = 'Pause';
            break;
          case ElementTypes.SCRIPT_SEGMENT: {
            const segmentAsAny = prompterSegment as PrompterSegment;
            const segmentNumber = segmentAsAny.number || 0;
            segmentTitle = ((segmentNumber > 0) ? `(${segmentNumber}) ` : '') + (segmentAsAny.title || 'Text Segment'); // tc('segmentelement.defaultcaption')
            break;
          }
          default:
            break;
        }

        return (<TocEntryRenderer
          key={`toc-${segmentIndex}`}
          segmentIndex={segmentIndex}
          segmentTitle={segmentTitle}
          handleListItemClick={handleListItemClick}
        />);
      })}
    </List>
  </div>);
});

export default PrompterHudToc;