import DeviceHost from '../DeviceHost';
import {
  IDeviceDescriptor,
  DeviceComponent,
  DeviceConnectionType
} from '../BaseDevice';
import BaseRemote from '../BaseRemote';
import { DeviceButtonEvent } from '../events/DeviceButtonEvent';

import DigitalButtonStateMachine from '../common/DigitalButtonStateMachine';

import { TFunction } from 'i18next';
import RemoteNeewerIcon from './images/remote-neewer-icon.png';
import NeewerRT110RemoteUI from './UI';

import {
  BluetoothDevice,
  BluetoothCharacteristic,
  IBluetoothProvider,
  BluetoothStatus
} from '@fluidprompter/ipc-interfaces';

const NEEWERRT110_TYPE = 'neewerrt110';

class NeewerRT110Remote extends BaseRemote {
  readonly type = NEEWERRT110_TYPE;

  public static readonly DEVICE_TYPE: string = NEEWERRT110_TYPE;

  // This is an undocumented service/characteristic on the Neewer RT110 Remote:
  static PRIMARY_SERVICE_UUID = '69400001-b5a3-f393-e0a9-e50e24dcca99';
  static PRIMARY_CHARACTERISTIC_UUID = '69400003-b5a3-f393-e0a9-e50e24dcca99';

  _buttons: DigitalButtonStateMachine[];

  // Name: NEEWER-RT-110
  // MAC: D3:1E:17:EB:92:22
  // Company ID: <0x9222> 0XEB171ED3

  constructor(deviceHost: DeviceHost) {
    super(deviceHost);
    this.icon = RemoteNeewerIcon;
    this.name = 'Neewer RT110 Remote';
    this.connectionType = DeviceConnectionType.Bluetooth;

    const buttons = [{
      bitmask: 0x01,
      name: 'left',
    }, {
      bitmask: 0x02,
      name: 'right',
    }, {
      bitmask: 0x04,
      name: 'up',
    }, {
      bitmask: 0x08,
      name: 'down',
    }, {
      bitmask: 0x10,
      name: 'ok',
    }, {
      bitmask: 0x20,
      name: 'minus',
    }, {
      bitmask: 0x40,
      name: 'plus',
    }];

    this._buttons = [];
    for (let i = 0; i < buttons.length; i++) {
      const btnInstance = new DigitalButtonStateMachine(buttons[i], this.onButtonEvent.bind(this));
      this._buttons.push(btnInstance);
    }
  }

  getRequestDeviceOptions(): RequestDeviceOptions {
    //
    // This should catch all Neewer remote with factory device name.
    //
    const neewerBleDeviceFilterByName: BluetoothLEScanFilter = {
      // readonly name?: string | undefined;
      // readonly namePrefix?: string | undefined;
      name: 'NEEWER-RT-110'
    };
    const neewerBleRT111FilterByName: BluetoothLEScanFilter = {
      // readonly name?: string | undefined;
      // readonly namePrefix?: string | undefined;
      name: 'NEEWER-RT111'
    };
    const neewerBleRT113FilterByName: BluetoothLEScanFilter = {
      // readonly name?: string | undefined;
      // readonly namePrefix?: string | undefined;
      name: 'NEEWER-RT113'
    };

    //
    // This was gathered from Ben's remote.
    //
    const neewerBleDeviceFilter1: BluetoothLEScanFilter = {
      manufacturerData: [{
        companyIdentifier: 0x9222 // 37410 // 0x9222
      }],
    };

    //
    // This was gathered from John@Airturn for a remote that was manufactured 6 months earlier than
    // Ben's remote despite having the same chipset and same PCB revision (so must be firmware).
    //
    const neewerBleDeviceFilter2: BluetoothLEScanFilter = {
      manufacturerData: [{
        companyIdentifier: 0xFEDC // 65244 // 0xFEDC
      }],
    };

    const requestDeviceOptions: RequestDeviceOptions = {
      filters: [neewerBleDeviceFilterByName, neewerBleRT111FilterByName, neewerBleRT113FilterByName, neewerBleDeviceFilter1, neewerBleDeviceFilter2],
      optionalServices: [NeewerRT110Remote.PRIMARY_SERVICE_UUID, 'battery_service'], // Battery Level
    };

    return requestDeviceOptions;
  }

  /*
  async onConnect(device: globalThis.BluetoothDevice, server: BluetoothRemoteGATTServer)  {

    switch(device.name) {
      case 'NEEWER-RT110':
        // this.icon = RemoteAirturnDigit3Icon;
        this.name = 'Neewer RT110 Remote';
        break;
      case 'NEEWER-RT111':
        // this.icon = RemoteAirturnDuoIcon;
        this.name = 'Neewer RT111 Remote';
        break;
      case 'NEEWER-RT113':
        // this.icon = RemoteAirturnQuadIcon;
        this.name = 'Neewer RT113 Remote';
        break;
      default:
        // This is an unknown Neewer device.
        break;
    }
    // this.requestDeviceReport();

    await super.onConnect(device, server);
  }
  */

  async attachServicesAndCharacteristics(
    device: BluetoothDevice,
    provider: IBluetoothProvider,
    subscriptionTopic: string) {

    super.attachServicesAndCharacteristics(device, provider, subscriptionTopic);

    await provider.notify(device.id,
      NeewerRT110Remote.PRIMARY_SERVICE_UUID,
      NeewerRT110Remote.PRIMARY_CHARACTERISTIC_UUID,
      (status: BluetoothStatus, char?: BluetoothCharacteristic, error?: number) => {
        if (status === BluetoothStatus.Success && char?.value) {
          this.onNotifyDigital(this.numArrToDataView(char.value));

        } else {
          console.log(`Received error on button notification. Error code is ${error}`);
        }
      },
      subscriptionTopic
    );

    //
    // Let's subscribe to battery level updates.
    //
    // await this.subscribeToBatteryStatus(device);
  }

  /**
   * Fired when our state machines have detected a user input event.
   * @param {*} buttonName
   * @param {*} eventType
   */
  onButtonEvent(buttonName: string, eventType: string) {
    const eventName = `${buttonName}.${eventType}`;
    console.log(`NeewerRT110Remote.onButtonEvent ${eventName}`);
    this.emit('buttonreport', new DeviceButtonEvent(this, buttonName, eventType));
  }

  /**
   * Fired when we receive a BLE characteristic notification for a digital button state change (button being pressed or released).
   * @param {*} notifyData
   */
  onNotifyDigital(notifyData: DataView) {
    // Neewer RT110 remote will notify with 5 bytes.
    // Bytes 1-3 appear to be a fixed prefix.
    //
    // Byte 1 = 0x78 (5)
    // Byte 2 = 0x10 (165)
    // Byte 3 = 0x01
    // Byte 4 = Button State
    // Byte 5 = Checksum??
    //
    // Button State Bitmasks:
    // 0x01 = Left
    // 0x02 = Right
    // 0x04 = Up
    // 0x08 = Down
    // 0x10 = Center (Play/Pause)
    // 0x20 = Minus
    // 0x40 = Plus

    if(notifyData.byteLength < 5) {
      throw new Error('Unexpected data length from Neewer RT110 Remote.');
    }

    const buttonState = notifyData.getUint8(3);  // Need to get the byte as _unsigned_ value
    const checksumByte = notifyData.getUint8(4);  // Need to get the byte as _unsigned_ value

    console.log(`Neewer buttong state ${buttonState} with checksum ${checksumByte}`);
    let buttonName = 'unknown';
    switch(buttonState) {
      case 1:
        buttonName = 'ok';
        if(checksumByte !== 138) {
          // ERROR!
          return;
        }
        break;
      case 2:
        buttonName = 'left';  //'minus';
        if(checksumByte !== 139) {
          // ERROR!
          return;
        }
        break;
      case 3:
        buttonName = 'right';  //'plus';
        if(checksumByte !== 140) {
          // ERROR!
          return;
        }
        break;
      case 4:
        buttonName = 'up';
        if(checksumByte !== 141) {
          // ERROR!
          return;
        }
        break;
      case 5:
        buttonName = 'down';
        if(checksumByte !== 142) {
          // ERROR!
          return;
        }
        break;
    }

    const eventName = `${buttonName}.down`;
    console.log(`NeewerRT110Remote.onButtonEvent ${eventName}`);
    this.emit('buttonreport', new DeviceButtonEvent(this, buttonName, 'down'));

    /*
    for (let i = 0; i < this._buttons.length; i++) {
      this._buttons[i].processState(buttonState);
    }
    */
  }

  static readonly DeviceKey: string = 'neewer';
  static getDeviceDescriptors(t: TFunction): IDeviceDescriptor[] {
    return [{
      connectionType: DeviceConnectionType.Bluetooth,
      deviceKey: NeewerRT110Remote.DeviceKey,
      deviceName: `Neewer ${t('connectdevicedialog.remote')}`,
      deviceIcon: RemoteNeewerIcon,
      requiresPlanLevel: 1,
      requiresBluetooth: true,
    }];
  }

  getDeviceUIComponent(): DeviceComponent {
    return NeewerRT110RemoteUI;
  }
}

export default NeewerRT110Remote;