import ZustandStoreSlice from '../utils/ZustandStoreSlice';

export enum MediaSourceType {
  None = 'none',
  Webcam = 'webcam',
  Desktop = 'desktop',
  Remote = 'remote',
}

export enum MediaPanelPosition {
  Top = 'top',
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right',
  Background = 'background',
}

export enum MediaScalingMode {
  // None = 'none',
  Contain = 'contain',
  Cover = 'cover',
}

export interface IPrompterVideoLayoutSlice {
  mediaPanelPosition: MediaPanelPosition;
  setMediaPanelPosition: (mediaPanelPosition: MediaPanelPosition) => void;
  mediaPanelWidth: number;
  setMediaPanelWidth: (mediaPanelWidth: number) => void;
  mediaPanelHeight: number;
  setMediaPanelHeight: (mediaPanelHeight: number) => void;

  media1ScalingMode: MediaScalingMode;
  setMedia1ScalingMode: (backgroundLayout: MediaScalingMode) => void;
}

const createPrompterMediaConfigSlice: ZustandStoreSlice<IPrompterVideoLayoutSlice> = (set) => ({
  mediaPanelPosition: MediaPanelPosition.Bottom,
  setMediaPanelPosition: (mediaPanelPosition: MediaPanelPosition) => set(() => ({ mediaPanelPosition })),
  mediaPanelWidth: 40,
  setMediaPanelWidth: (proposedMediaPanelWidth: number) => set(() => {
    let mediaPanelWidth = proposedMediaPanelWidth;
    if(mediaPanelWidth < 25) { mediaPanelWidth = 25; }
    if(mediaPanelWidth > 65) { mediaPanelWidth = 65; }
    return ({ mediaPanelWidth });
  }),
  mediaPanelHeight: 40,
  setMediaPanelHeight: (proposedMediaPanelHeight: number) => set(() => {
    let mediaPanelHeight = proposedMediaPanelHeight;
    if(mediaPanelHeight < 25) { mediaPanelHeight = 25; }
    if(mediaPanelHeight > 65) { mediaPanelHeight = 65; }
    return ({ mediaPanelHeight });
  }),

  media1ScalingMode: MediaScalingMode.Contain,
  setMedia1ScalingMode: (media1ScalingMode: MediaScalingMode) => set(() => ({ media1ScalingMode })),
});

export default createPrompterMediaConfigSlice;