import { IViewportMeta } from '@fluidprompter/core';
import { StateCreator } from 'zustand';
import { IPrompterAttributesSlice } from './PrompterAttributesSlice';

export interface IPrompterViewportSlice {
  viewportMeta: IViewportMeta;
  setViewportMeta: (viewportMeta: IViewportMeta) => void;

  viewportWidth: number;
  viewportHeight: number;
  setViewportDimensions: (viewportWidth: number, viewportHeight: number) => void;
}

const createPrompterViewportSlice: StateCreator<
  IPrompterAttributesSlice & IPrompterViewportSlice,
  [],
  [],
  IPrompterViewportSlice
> = (set, get) => ({
  viewportMeta: {
    viewportWidth: 0,
    viewportHeight: 0,
    viewportMarginTop: 0,
    viewportMarginBottom: 0,
    availableViewportHeight: 0,
    cuePositionOffset: 0,
    scriptMarginStart: 0,
    scriptMarginEnd: 0,
  },
  setViewportMeta: (viewportMeta: IViewportMeta) => set(() => ({ viewportMeta })),

  viewportWidth: 0,
  viewportHeight: 0,
  setViewportDimensions: (viewportWidth: number, viewportHeight: number) => set((state) => {
    if(
      viewportWidth !== state.viewportWidth
        || viewportHeight !== state.viewportHeight
    ) {
      // Our browser viewport dimensions have changed.
      return { viewportWidth, viewportHeight };
    }

    // Returning the existing state will result in no change, and no hooks will be triggered.
    return state;
  }),
});

export default createPrompterViewportSlice;