/**
 * © Copyright 2024 fluidprompter.com
 */
import { IPCServer, MessageChannel } from '@fluidprompter/ipc';

import {
  IBluetoothProvider,
  BluetoothProvider,
  BluetoothStatus,
  BluetoothDeviceState,
  BluetoothDevice,
  BluetoothService,
  BluetoothCharacteristic,
  BluetoothEventType,
  DeviceId,
  IPCServers,
  BluetoothEvent,
  BluetoothState,
  BluetoothNotifyCallback
} from '@fluidprompter/ipc-interfaces';

type WebBluetoothDevice = {
  bleDevice: globalThis.BluetoothDevice
  gattServer?: BluetoothRemoteGATTServer 
  eventListeners?: Map<string, (this: globalThis.BluetoothDevice, ev: Event) => void>;
}
const eventMap: { [key in BluetoothEventType]: string } = {
  [BluetoothEventType.ServerDisconnectedEvent]: 'gattserverdisconnected',
  [BluetoothEventType.AvailabilityChangedEvent]: 'availabilitychanged',
};

type WebBluetoothDeviceMap = Map<DeviceId, WebBluetoothDevice>;

/**
 * Implement the BluetoothProvider interface using the WebBluetoothAPI in a web browser.
 */
export class BluetoothProviderWebIPCServer implements IBluetoothProvider
{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  _deviceMap: WebBluetoothDeviceMap;
  private _serverHandle: IPCServer<IBluetoothProvider>;

  constructor(IPCChannel: MessageChannel) {
    this._deviceMap = new Map();
    this._serverHandle = new IPCServer(IPCServers.BLE, IPCChannel, this);
  }

  async getProvider() {
    return BluetoothProvider.WEB;
  }

  async connect(
    deviceId: string
  ) : Promise<BluetoothDevice | undefined> {

    // Connect to BLE GATT Server!
    const device = this._deviceMap.get(deviceId);
    if (!device) {
      throw new Error(`connect: Device with id ${deviceId} doesn't exist`);
    }

    const gattServer = device.bleDevice.gatt;
    if(!gattServer) {
      throw new Error('connect: BleDevice.gatt is null');
    }

    await gattServer.connect();

    device.gattServer = gattServer;

    return {
      id: device.bleDevice.id,
      name: device.bleDevice.name ?? null,
      serviceUUIDs: device.bleDevice.uuids ?? null
    };
  }

  async disconnect(deviceId: string): Promise<BluetoothDevice | undefined> {

    const device = this._deviceMap.get(deviceId);
    if (!device) {
      throw new Error(`disconnect: Device with id ${deviceId} doesn't exist`);
    }

    if(device.gattServer) {
      device.gattServer.disconnect();
    }

    this._deviceMap.delete(deviceId);

    return {
      id: device.bleDevice.id,
      name: device.bleDevice.name ?? null,
      serviceUUIDs: device.bleDevice.uuids ?? null
    };
  }

  addEventListener(
    deviceId: DeviceId,
    event: BluetoothEvent,
  ) : void {
    // Factory for proxy handlers that convert the value retrieved from the Web
    // Bluetooth and covert it to and expected value defined by IBluetoothProvider 
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const createEventListener = (event: BluetoothEvent): any =>  {
      switch(event.eventType) {
        case BluetoothEventType.AvailabilityChangedEvent:
          return (webAvailabilityChangedEvent: {value: boolean}) => {
            event.handler(webAvailabilityChangedEvent.value ? BluetoothState.PoweredOn : BluetoothState.PoweredOff);
          };

        case BluetoothEventType.ServerDisconnectedEvent:
          return () => {
            event.handler();
          };
      }
    };

    // Add listener to all devices
    if (deviceId === '*') {
      if (navigator && navigator.bluetooth) {
        navigator.bluetooth.addEventListener(eventMap[event.eventType], createEventListener(event));
      }
      return;
    }

    // Add listener to a specific device
    const device = this._deviceMap.get(deviceId);
    if (!device) {
      console.log(`registerEventListener: Device with id ${deviceId} doesn't exist`);
      return;
    }
    device.bleDevice.addEventListener(eventMap[event.eventType], createEventListener(event));
  }

  removeEventListener(
    deviceId: DeviceId,
    event: BluetoothEvent,
  ) : void {

    const device = this._deviceMap.get(deviceId);
    if (!device) {
      throw new Error(`unregisterEventListener: Device with id ${deviceId} doesn't exist`);
    }
    device.bleDevice.removeEventListener(eventMap[event.eventType], event.handler as never);
  }

  async getState(): Promise<BluetoothDeviceState> {
    try {
      const state = await navigator.bluetooth.getAvailability();
      if(state) {
        return BluetoothDeviceState.PoweredOn;
      }
    } catch (error) {
      console.log(`getState error: ${error}`);
    }
    return BluetoothDeviceState.Unsupported;
  }

  async startScanning(requestOptions: RequestDeviceOptions, onDiscoverCallback: (device?: BluetoothDevice, error?: Error) => void): Promise<void> {

    const devices: BluetoothDevice[] = [];

    const bleDevice = await navigator.bluetooth.requestDevice(requestOptions);
    if(!bleDevice) {
      onDiscoverCallback(undefined, new Error('startScanning: BleDevice is null'));
    }

    this._deviceMap.set(bleDevice.id, {bleDevice: bleDevice, eventListeners: new Map()});

    devices.push({
      id: bleDevice.id,
      name: bleDevice.name ?? null,
      serviceUUIDs: bleDevice.uuids ?? null
    });

    // Since it's only possible to pick one device, a list will contain one entry
    onDiscoverCallback(devices[0]);
  }

  async stopScanning(): Promise<BluetoothStatus> {
    return BluetoothStatus.Success;
  }

  async discoverServices(deviceId: DeviceId): Promise<BluetoothService[]> {
    const services: BluetoothService[] = [];

    const device = this._deviceMap.get(deviceId);
    if (!device) {
      throw new Error(`discoverServices: Device with id ${deviceId} doesn't exist`);
    }

    const gattServer = device.gattServer;
    if (!gattServer) {
      throw new Error('discoverServices: gattServer is null');
    }

    // Get all services 
    const bleServices = await gattServer.getPrimaryServices();

    for (const service of bleServices) {
      const serviceChars: BluetoothCharacteristic[] = [];

      // Get all characteristics per service
      const chars = await service.getCharacteristics();

      chars.forEach(char => {
        serviceChars.push({
          uuid: char.uuid,
          serviceUUID: char.service.uuid,
          deviceID: char.service.device.id.toString(),
          isReadable: char.properties.read,
          isNotifiable: char.properties.notify,
        });
      });

      services.push({
        id: service.device.id,
        uuid: service.uuid,
        deviceID: service.device.id,
        isPrimary: service.isPrimary,
        characteristics: serviceChars
      });
    }

    return services;
  }

  async read(
    deviceId: DeviceId,
    serviceUUID: string,
    characteristicUUID: string
  ) : Promise<number[] | null> {

    const device = this._deviceMap.get(deviceId);
    if (!device) {
      throw new Error(`read: Device with id ${deviceId} doesn't exist`);
    }

    const gattServer = device.gattServer;
    if (!gattServer) {
      throw new Error('read: gattServer is null');
    }

    // Get service 
    const service = await gattServer.getPrimaryService(serviceUUID);
    if(!service) {
      return null;
    }

    // Get all characteristics per service
    const characteristic = await service.getCharacteristic(characteristicUUID);
    if(!characteristic) {
      return null;
    }

    const charValue = await characteristic.readValue();

    return [...new Uint8Array(charValue.buffer)];
  }


  async notify(
    deviceId: string,
    serviceUUID: string,
    characteristicUUID: string,
    handler: BluetoothNotifyCallback,
    topic: string | undefined
  ) : Promise<void> {

    const device = this._deviceMap.get(deviceId);
    if (!device) {
      throw new Error(`notify: Device with id ${deviceId} doesn't exist`);
    }

    const gattServer = device.gattServer;
    if (!gattServer) {
      throw new Error('notify: gattServer is null');
    }

    const service = await gattServer.getPrimaryService(serviceUUID);
    const characteristics = await service.getCharacteristics();

    const characteristic = characteristics.find(c => c.uuid === characteristicUUID);
    if(characteristic) {

      await characteristic.startNotifications();

      characteristic.addEventListener('characteristicvaluechanged', (e) => {
        const eventCharacteristic = e.target as BluetoothRemoteGATTCharacteristic;

        if(eventCharacteristic.value) {
          const ReceivedCharacteristic: BluetoothCharacteristic = {
            uuid: eventCharacteristic.uuid,
            serviceUUID: eventCharacteristic.service.uuid,
            deviceID: deviceId,
            value: [...new Uint8Array(eventCharacteristic.value.buffer)]
          };

          handler(BluetoothStatus.Success, ReceivedCharacteristic, 0);
        }
      });
    }
  }
}
