import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useSearchParams,
  useNavigate,
} from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { AppState, RedirectLoginOptions } from '@auth0/auth0-react';
import { useAppController, useMessageHandler } from './controllers/AppController';
import { AppLifecycleState } from '@fluidprompter/core';

import AppConfig from './utils/AppConfig';
// import { useServiceWorker } from './hooks/useServiceWorker';
import useApplicationContext from './hooks/useApplicationContext';
import useApplicationState from './state/ApplicationState';
import useConfigurationStore from './state/ConfigurationStore';
import useFeatureFlagsStore from './state/FeatureFlagsStore';
import usePrompterSession from './state/PrompterSessionState';
import WindowMode from './models/WindowMode';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import WifiOutlinedIcon from '@mui/icons-material/WifiOutlined';
import WifiOffOutlinedIcon from '@mui/icons-material/WifiOffOutlined';

// TODO: Code Splitting: https://reactjs.org/docs/code-splitting.html#route-based-code-splitting
import StyledToastContainer from './components/StyledToastContainer';
import TeleprompterPage from './pages/TeleprompterPage';
import ViewerPage from './pages/ViewerPage';
import RemoteJoinPage from './pages/RemoteJoinPage';
import RemotePage from './pages/RemotePage';
import SignupPage from './pages/SignupPage';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import AuthCallbackPage from './pages/AuthCallbackPage';
import NotFoundPage from './pages/NotFoundPage';
import UserSetupPage from './pages/UserSetupPage';
import AppLoadingIndicator from './components/AppLoadingIndicator';

import FeedbackSSOPage from './pages/FeedbackSSOPage';
import { toast, Id, ToastContent, ToastOptions, UpdateOptions } from 'react-toastify';
import { TFunction } from 'i18next';
import logger, { mapLevelNameToNumber, mapLevelNumberToName } from './utils/Logger';

const LOCALSTORAGE_KEY_APP_VERSION = 'fp-web-version';

const processLaunchQueryParam = (searchParams: URLSearchParams): URLSearchParams => {
  const launcherParam = searchParams.get('launcher');
  if(!launcherParam) {
    return searchParams;
  }

  switch(launcherParam) {
    case 'pwa':
      useApplicationState.getState().setWindowMode(WindowMode.PWA);
      break;
    case 'reactnative':
      useApplicationState.getState().setWindowMode(WindowMode.REACTNATIVE);
      break;
    case 'electron':
      useApplicationState.getState().setWindowMode(WindowMode.ELECTRON);
      break;
    default:
      useApplicationState.getState().setWindowMode(WindowMode.UNKNOWN);
      break;
  }
  // We are running as a progressive web app!
  searchParams.delete('launcher');
  return searchParams;
};

const processFeatureFlagQueryParams = (searchParams: URLSearchParams): URLSearchParams => {
  const featureFlags = useFeatureFlagsStore.getState();

  if(searchParams.has('enable_account')) {
    if(!featureFlags.prompterDrawerLogonMenu) {
      featureFlags.setPrompterDrawerLogonMenu(true);
    }

    searchParams.delete('enable_account');
  }

  // enable_viewmenu
  if(searchParams.has('enable_viewmenu')) {
    if(!featureFlags.prompterDrawerViewMenu) {
      featureFlags.setPrompterDrawerViewMenu(true);
    }

    searchParams.delete('enable_viewmenu');
  }

  // enable_voice
  if(searchParams.has('enable_voice')) {
    if(!featureFlags.voiceDictation) {
      featureFlags.setVoiceDictation(true);
    }

    searchParams.delete('enable_voice');
  }

  // enable_media
  if(searchParams.has('enable_media')) {
    if(!featureFlags.mediaSharing) {
      featureFlags.setMediaSharing(true);
    }

    searchParams.delete('enable_media');
  }

  return searchParams;
};

const processReturnFromQueryParam = (t: TFunction, searchParams: URLSearchParams, logout: () => void): URLSearchParams => {
  const return_from = searchParams.get('return_from');
  const error_param = searchParams.get('error');
  // const error_description_param = searchParams.get('error_description');
  // const state_param = searchParams.get('state');

  if(return_from && !error_param) {
    let toastContent: ToastContent = '';
    const toastOptions: ToastOptions = {
      type: 'default',
      isLoading: false,
      autoClose: 5000,
      hideProgressBar: false
    };
    switch(return_from) {
      case 'login':
        // toastContent = 'You have been logged in.';
        toastContent = t('notifications.loggedin');
        toastOptions.type = 'success';
        break;
      case 'signup':
        // toastContent = 'You have been logged in.';
        break;
      case 'logout':
        // toastContent = 'You have been logged out.';
        toastContent = t('notifications.loggedout');
        toastOptions.type = 'info';
        break;
      case 'delete_account':
        // toastContent = <>Your account was deleted as requested. You are now logged out.</>;
        toastContent = t('notifications.accountdeleted');
        toastOptions.type = 'error';
        toastOptions.autoClose = 10000;
        break;
      default:
        break;
    }
    if(toastContent) {
      toast(toastContent, toastOptions);
    }

    searchParams.delete('return_from');
  }

  //
  // Did we encounter an error logging in?
  //
  if(return_from && error_param) {
    logout();

    let toastContent = '';
    const toastOptions: ToastOptions = {
      type: 'error',
      isLoading: false,
      autoClose: 5000,
      hideProgressBar: false
    };
    switch(return_from) {
      case 'login':
        toastContent = 'Error: Login not successful.';
        break;
      case 'signup':
        // toastContent = 'You have been logged in.';
        break;
      case 'logout':
        toastContent = 'Error: Logout not successful.';
        break;
      default:
        break;
    }
    if(toastContent) {
      toast(toastContent, toastOptions);
    }

    searchParams.delete('return_from');
  }

  return searchParams;
};

const processAffiliateQueryParam = (searchParams: URLSearchParams): URLSearchParams => {
  const affiliateParam = searchParams.get('via');
  if(!affiliateParam) {
    return searchParams;
  }

  const currentUrl = new URL(window.location.href);
  currentUrl.hash = '';   // Don't track hashes in affiliate landing pages.
  currentUrl.search = ''; // Don't track query strings in affiliate landing pages.
  const page = currentUrl.toString();

  const { referrer } = document;

  const affiliateClickData = {
    page,
    via: affiliateParam,
    referrer,
  };

  (async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/click`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify(affiliateClickData)
    });

    // if(response.status !== 200) {
    //   // We received an error response.
    //   throw new Error('Error: could not post click data.');
    // }

    // const clickRecord = await response.json();
  })();

  // We are running as a progressive web app!
  searchParams.delete('via');
  return searchParams;
};

const processLogLevelQueryParam = (searchParams: URLSearchParams): URLSearchParams => {
  const logLevelParam = searchParams.get('log');
  if(!logLevelParam) {
    return searchParams;
  }

  //
  // If we load the page with a query paraemter log=fatal|error|warn|info|debug|trace, then set
  // our logger level. We will default the app to log level error or none, and then opt in to more
  // verbose logging when needed for debugging.
  //
  switch(logLevelParam) {
    case 'fatal':
    case 'error':
    case 'warn':
    case 'info':
    case 'debug':
    case 'trace':
      logger.level(mapLevelNameToNumber(logLevelParam));
      useConfigurationStore.getState().setLogLevel(mapLevelNameToNumber(logLevelParam));
      break;
    default:
      break;
  }

  searchParams.delete('log');
  return searchParams;
};

interface QueryParamsProcessorProps {
  t: TFunction;
  isLoading: boolean;
  logout: () => void;

  setLoginInitiated: React.Dispatch<React.SetStateAction<boolean>>;
  loginWithRedirect: (options?: RedirectLoginOptions<AppState> | undefined) => Promise<void>;
  // loginWithPopup: (options?: PopupLoginOptions | undefined, config?: PopupConfigOptions | undefined) => Promise<void>;
}
const QueryParamsProcessor = ({ t, isLoading, logout, setLoginInitiated, loginWithRedirect }: QueryParamsProcessorProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const pageUrlRef = useRef<string>();
  React.useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const { searchParams } = currentUrl;
    const pageUrl = currentUrl.origin + currentUrl.pathname;  // Ignore the searchParams or hash segment.

    if(isLoading) {
      // console.log('AppRouter: skip URL processing while appContext.isLoading');
      return;
    }
    if(location.pathname.indexOf('/auth') >= 0) {
      // console.log('AppRouter: skip URL processing on /auth page.');
      return;
    }
    if(pageUrlRef.current === pageUrl) {
      // Prevent duplicate processing under React Strict Mode
      return;
    }
    pageUrlRef.current = pageUrl;

    // Track Page View via CloudFlare Zaraz
    // TODO: zaraz.track();

    // If we load the page with a query paraemter log=fatal|error|warn|info|debug|trace, then set
    // our logger level. We will default the app to log level error or none, and then opt in to more
    // verbose logging when needed for debugging.
    processLogLevelQueryParam(searchParams);

    // If we have a `launcher` query parameter, set the application state to reflect if we are
    // running as a PWA or EXPO app (or in future Electron App).
    processLaunchQueryParam(searchParams);

    // If we have a `enable_account` query parameter then set the feature flag to enable account
    // login UI. This is used to hide the feature publicly before its ready while allowing for
    // live testing with production Auth0/Stripe accounts.
    processFeatureFlagQueryParams(searchParams);

    // If we are returning from login, signup, or logout, let's show the user a notification.
    processReturnFromQueryParam(t, searchParams, () => {
      logout();
    });

    // If we are landing here from an affiliate link, lets track it.
    processAffiliateQueryParam(searchParams);

    // Only replace state if it has changed after processing.
    if(
      currentUrl.pathname !== location.pathname
        || currentUrl.search !== location.search
        || currentUrl.hash !== location.hash
    ) {
      // window.location.replace(currentUrl);
      // window.history.replaceState(null, document.title, currentUrl);
      navigate(currentUrl, {
        replace: true,
      });
    }
  }, [isLoading, location, searchParams, pageUrlRef, t, logout]);

  const requestLogin = useCallback(async function (loginHint?: string) {
    // Initiate a Login Flow now.
    const returnUrl = new URL(window.location.href);
    returnUrl.searchParams.set('return_from', 'login');

    // Make sure we don't lose any temporarily enabled feature flags during the login redirects.
    const featureFlags = useFeatureFlagsStore.getState();
    featureFlags.encodeFeatureFlagParams(returnUrl.searchParams);

    // Persist the log level across user logins
    const logLevelNumber = logger.level();
    if(logLevelNumber > 0 && logLevelNumber < 40) {
      const logLevelName = mapLevelNumberToName(logLevelNumber);
      returnUrl.searchParams.set('log', logLevelName);
    }

    const finalUrl = returnUrl.toString();

    returnUrl.pathname = '/auth/';
    returnUrl.search = '';
    returnUrl.hash = '';

    useApplicationState.getState().setMenuOpen(false);

    setLoginInitiated(true);

    // If the user hits the back button and back/forward cache is used, we want to clear the loading indicator.
    window.addEventListener('pageshow', () => {
      setLoginInitiated(false);
    }, { once: true });

    //
    // If we are in an iFrame, let's use a pop-up style login prompt.
    //
    // if(window.location !== window.parent.location) {
    //   loginWithPopup(baseLoginOptions, {
    //     timeoutInSeconds: 75,
    //   }).then(() => {
    //     setLoginInitiated(false);
    //   });
    //   return;
    // }

    // User is not currently authenticated/
    try {
      loginWithRedirect({
        // openUrl: (url: string) => {
        //   window.location.href = url;
        //   window.history.pushState()
        // },
        appState: {
          returnTo: finalUrl,
        },
        authorizationParams: {
          screen_hint: loginHint,

          /**
           * The URL where Auth0 will redirect your browser to with
           * the authentication result. It must be whitelisted in
           * the "Allowed Callback URLs" field in your Auth0 Application's
           * settings.
           */
          redirect_uri: returnUrl.toString(),
        },
      });
    } catch(err) {
      //
      // This occurs when the Auth0Provider is not present or misconfigured.
      // The Auth0Provider will not be loaded in a non-secure context outside of localhost.
      //
      alert('Unable to login. Please contact support.');
      setLoginInitiated(false);
    }
  }, [loginWithRedirect]);
  useMessageHandler('prompter.local.login', requestLogin.bind(this, 'login'));
  useMessageHandler('prompter.local.signup', requestLogin.bind(this, 'signup'));

  return (<></>);
};

const AppRouter = () => {
  const {
    appContext,
    getApiToken,
    subscription,
    loginWithRedirect,
    loginWithPopup,
    logout,
  } = useApplicationContext(true);
  const {
    isLoading,
    accountSetupRequired,
    isAuthenticated,
    userProfile,
    internetOnline,
  } = appContext;

  const appController = useAppController();

  const { t } = useTranslation('common');

  /**
   * We need to create a universal router for postmessage events between a webview host app and the FluidPrompter web app.
   * The job of the router is to send and receive messages between multiple different "modules" such as Bluetooth, HID, Auth, or others.
   */
  React.useEffect(() => {
    window.receiveReactNativeMessage = (msg: string) => {
      console.log(`Received from native ${msg}`);
      appController.handleIPCMessage(msg);
    };

    return () => {
      window.receiveReactNativeMessage = undefined;
    };
  }, [appController]);

  //
  // Handlers to request login/signup
  //
  const [loginInitiated, setLoginInitiated] = useState<boolean>(false);

  const requestLogout = useCallback(async function () {
    // Initiate a Logout Flow now.
    const returnUrl = new URL(window.location.href);
    returnUrl.searchParams.set('return_from', 'logout');

    // Make sure we don't lose any temporarily enabled feature flags during the login redirects.
    const featureFlags = useFeatureFlagsStore.getState();
    featureFlags.encodeFeatureFlagParams(returnUrl.searchParams);

    // Persist the log level across user logins
    const logLevelNumber = logger.level();
    if(logLevelNumber > 0 && logLevelNumber < 40) {
      const logLevelName = mapLevelNumberToName(logLevelNumber);
      returnUrl.searchParams.set('log', logLevelName);
    }

    const finalUrl = returnUrl.toString();

    returnUrl.pathname = '/auth/';
    returnUrl.search = '';    // Make sure we are not forwarding the /logout portion of the current page URL, but do preserve any querystrings!
    returnUrl.searchParams.set('action', 'logout');
    returnUrl.searchParams.set('redirectUrl', finalUrl);

    logout({
      openUrl: (url: string) => {
        // window.history.replaceState(null, document.title, url);
        window.location.replace(url);
      },
      logoutParams: {
        returnTo: returnUrl.toString(),
      },
    });
  }, [logout]);
  useMessageHandler('prompter.local.logout', requestLogout);

  const requestUpgrade = useCallback(async function () {
    // This is not required if the subscription.switchToSoloCreatorPlan(); method will set
    // isLoading to true
    // setLoginInitiated(true);

    // This is not required if the subscription.switchToSoloCreatorPlan(); method will set
    // isLoading to true
    // try {
    //   subscription.switchToSoloCreatorPlan();
    // } catch(err) {
    //   setLoginInitiated(false);
    // }
    subscription.switchToSoloCreatorPlan();
  }, [subscription]);
  useMessageHandler('prompter.local.upgrade', requestUpgrade);

  const redirectToFeedbackPortal = useCallback(async () => {
    //
    // If we are not authenticated, then don't bother with SSO.
    //
    if(!appContext.isAuthenticated) {
      window.open('https://feedback.fluidprompter.com/', '_blank');
      return;
    }

    //
    // If we are authenticated, then let's SSO into the feedback portal.
    //
    try {
      const token = await getApiToken();

      const response = await fetch(`${process.env.REACT_APP_API_URL}/feedback/getportalurl`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`,
        },
      });
      if(response.status !== 200) {
        // We received an error response.
        //
        // If we didn't succeed above, something went wrong with out SSO to the feedback portal.
        // Let's just open the feedback portal unauthenticated.
        window.open('https://feedback.fluidprompter.com/', '_blank');
        return;
      }

      console.log('Got app API account response from API serverless function', response);

      const feedbackPortalInfo = await response.json();

      //
      // If we got a JWT, let Nolt know.
      //
      const feedbackPortalJwt = feedbackPortalInfo.jwt as string;
      // if(feedbackPortalJwt) {
      //   nolt('identify', {
      //     jwt: feedbackPortalJwt
      //   });
      // }

      const feedbackPortalUrl = feedbackPortalInfo.url as string;
      if(feedbackPortalUrl) {
        // https://YOUR_BOARD.nolt.io/sso/JWT_FOR_THIS_USER?returnUrl=RETURN_URL
        // `https://fluidprompter.nolt.io/sso/${feedbackPortalJwt}?returnUrl=RETURN_URL`
        // window.location.href = feedbackPortalUrl;
        const feedbackPortalUrlAuth = `https://feedback.fluidprompter.com/sso/${feedbackPortalJwt}`;
        window.open(feedbackPortalUrlAuth, '_blank');
        return;
      }
    } catch (e) {
      console.error(e);
    }
  }, [appContext.isAuthenticated, getApiToken]);
  useMessageHandler('prompter.local.featurerequest', redirectToFeedbackPortal);

  const openContactUs = useCallback(async function () {
    const contactUrl = new URL('/contact/', process.env.REACT_WEB_URL || 'https://www.fluidprompter.com');
    window.open(contactUrl.toString(), '_blank');
  }, [subscription]);
  useMessageHandler('prompter.local.contactus', openContactUs);

  //
  // Track user login/logout
  //
  React.useEffect(() => {
    if(!isAuthenticated || isLoading) {
      // We can't identify the user if we aren't authenticated
      return;
    }

    if(!userProfile) {
      return;
    }

    //
    // If we get here our appContext has changed because the user signed in, signed out, or updated
    // their profile or account.
    //
    const { WORDLIMIT_SIGNEDOUT_USER, WORDLIMIT_FREE_USER } = AppConfig.current;
    const prompterSession = usePrompterSession.getState();
    switch(userProfile.fluidprompter_plan) {
      case 'free':
        if(prompterSession.wordLimit !== WORDLIMIT_FREE_USER) {
          prompterSession.setWordLimit(WORDLIMIT_FREE_USER);
        }
        break;
      case 'pro':
      case 'studio':
        if(prompterSession.wordLimit !== undefined) {
          prompterSession.setWordLimit(undefined);
        }
        break;
      default:
        // No plan set - either unauthenticated or user will be directed to onboarding
        // flow later.
        if(prompterSession.wordLimit !== WORDLIMIT_SIGNEDOUT_USER) {
          prompterSession.setWordLimit(WORDLIMIT_SIGNEDOUT_USER);
        }
        break;
    }

    const userTraits: object = {
      email: userProfile.email,
      plan: userProfile.fluidprompter_plan,
      subscription_currency: userProfile.subscription_currency,
      subscription_interval: userProfile.subscription_interval,
    };
    // REMOVE TWILIO SEGMENT: analytics.identify(userProfile.auth0_user_id, userTraits);
    // TODO: Replace with CloudFlare Zaraz event
  }, [isLoading, isAuthenticated, userProfile]);

  const lastOnlineStatus = useRef<boolean>(internetOnline);
  const lastOnlineStatusToastId = useRef<Id>();
  useEffect(() => {
    if(internetOnline === lastOnlineStatus.current) {
      return;
    }

    appController.setIsOnline(internetOnline);

    let toastContent = (<Trans>{t('notifications.offline')}</Trans>);
    const toastOptions: UpdateOptions = {
      type: 'error',
      isLoading: false,
      autoClose: 5000,
      hideProgressBar: false,
      icon: (<WifiOffOutlinedIcon sx={{ color: '#d32f2f' }} />),
    };

    if(internetOnline) {
      console.log('transition internetOnline = true');
      toastOptions.type = 'success';
      toastOptions.icon = (<WifiOutlinedIcon sx={{ color: '#07bc0c' }} />);
      toastContent = (<Trans>{t('notifications.online')}</Trans>);
    } else {
      console.log('transition internetOnline = false');
    }

    // Save a ref to our last saved state.
    lastOnlineStatus.current = internetOnline;

    //
    // Either update or create our toast message
    const existingToastId = lastOnlineStatusToastId.current;
    if(existingToastId && toast.isActive(existingToastId)) {
      if(toastContent && toastOptions) {
        toastOptions['render'] = toastContent;
      }
      toast.update(existingToastId, toastOptions);
      return;
    }
    lastOnlineStatusToastId.current = toast(toastContent, toastOptions as ToastOptions);
  }, [t, internetOnline]);

  //
  // Hook into page lifecycle events
  //
  const hasFocusRef = useRef<boolean>(true);
  const documentVisibleRef = useRef<boolean>(true);
  useEffect(() => {
    const reevaluateAppLifecycleState = () => {
      let proposedAppLifecycleState = AppLifecycleState.Active;
      if(!hasFocusRef.current) {
        proposedAppLifecycleState = AppLifecycleState.Passive;
      }
      if(!documentVisibleRef.current) {
        proposedAppLifecycleState = AppLifecycleState.Hidden;
      }
      appController.appLifecycleState = proposedAppLifecycleState;
    };
    const handleWindowFocus = (e: FocusEvent) => {
      hasFocusRef.current = true;
      reevaluateAppLifecycleState();
    };
    const handleWindowBlur = (e: FocusEvent) => {
      hasFocusRef.current = false;
      reevaluateAppLifecycleState();
    };
    const handleWindowPageShow = (e: PageTransitionEvent) => {
      hasFocusRef.current = document.hasFocus();
      documentVisibleRef.current = (document.visibilityState === 'visible');
      reevaluateAppLifecycleState();
    };
    // const handleWindowPageHide = (e: PageTransitionEvent) => {
    //   if(e.persisted)
    // };
    const handleDocumentVisibilityChange = (e: Event) => {
      documentVisibleRef.current = (document.visibilityState === 'visible');
      reevaluateAppLifecycleState();
    };

    // Subscribe to page lifecycle events.
    window.addEventListener('focus', handleWindowFocus);
    window.addEventListener('blur', handleWindowBlur);
    // window.addEventListener('pagehide', handleWindowPageHide);
    window.addEventListener('pageshow', handleWindowPageShow);
    document.addEventListener('visibilitychange', handleDocumentVisibilityChange);

    // Clean-up
    return () => {
      window.removeEventListener('focus', handleWindowFocus);
      window.removeEventListener('blur', handleWindowBlur);
      // window.removeEventListener('pagehide', handleWindowPageHide);
      window.removeEventListener('pageshow', handleWindowPageShow);
      document.removeEventListener('visibilitychange', handleDocumentVisibilityChange);
    };
  });

  // Detect app updates and notify the user when applied.
  React.useEffect(() => {
    if(typeof(Storage) === 'undefined') {
      // localStorage not supported - on some mobile browsers
      return;
    }

    const currentVersion = process.env.REACT_APP_VERSION;
    if(!currentVersion) {
      return;
    }

    const previousVersion = localStorage.getItem(LOCALSTORAGE_KEY_APP_VERSION);
    if(previousVersion !== currentVersion) {
      // Only show a toast popup if the user had a previously installed version.
      if(previousVersion) {
        // Tell the user they got a new version!
        toast(t('notifications.appupdated', { version: currentVersion }), {
          type: 'success',
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false
        });
      }

      // Save our most recent app version to localstorage
      localStorage.setItem(LOCALSTORAGE_KEY_APP_VERSION, currentVersion);
    }
  }, []);

  // If we have an authenticated user and their userprofile, check if we are missing any important
  // account setup.
  if(isAuthenticated
    && !isLoading
    && accountSetupRequired
    && (window.location.pathname !== '/logout')
  ) {
    return (<UserSetupPage accountSetupRequired={accountSetupRequired} />);
  }

  //
  // If we get here, we either have an unauthenticated user, or the authenticated user has a fully
  // configured account.
  //
  return (<>
    <QueryParamsProcessor
      t={t}
      isLoading={isLoading !== false}
      logout={logout}
      setLoginInitiated={setLoginInitiated}
      loginWithRedirect={loginWithRedirect}
    />

    {/* We don't want to render the route page until we have finished loading the user context */}
    {isLoading === false &&
      <Routes>
        {/*<Route path="/redirect" element={ <Navigate to="/error-page" /> } />*/}
        <Route path='/signup' element={<SignupPage/>} />
        <Route path='/login' element={<LoginPage/>} />
        <Route path='/feedback' element={<FeedbackSSOPage/>} />
        <Route path='/logout' element={<LogoutPage/>} />
        <Route path='/auth' element={<AuthCallbackPage/>} />
        <Route path='/remote' element={<RemoteJoinPage/>} />
        <Route path='/remote/:prompterId' element={<RemotePage/>} />
        <Route path='/viewer' element={<ViewerPage/>} />
        <Route path='/' element={<TeleprompterPage/>} />
        <Route path="*" element={<NotFoundPage/>}/>
      </Routes>
    }
    <AppLoadingIndicator isLoading={isLoading} loginInitiated={loginInitiated} />

    {/* StyledToastContainer will render in portal as <div class="Toastify"></div> */}
    <StyledToastContainer />
  </>);
};

export default AppRouter;