var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuidv4 } from 'uuid';
import { IPC_FUNCTION_TAG_PROPERTY, IPC_FUNCTION_TAG_VALUE, } from './interfaces.js';
import { IPCError } from './IPCError.js';
export class IPCClient {
    /**
     * Create a new IPCClient instance.
     * @param name The name of the client used to identify the source of messages by the audience
     * @param destination The name of the target audience for messages
     * @param messageChannel The message channel used to send and receive messages
     */
    constructor(name, destination, messageChannel) {
        this.name = name;
        this.destination = destination;
        this.requestIdToPromiseMap = new Map();
        this.callbackMap = new Map();
        this.send = messageChannel.send.bind(messageChannel);
        messageChannel.addOnMessageListener(this.name, this.messageHandler.bind(this));
        // Create a proxy object that will intercept all function calls and forward them to
        // the IPC server.
        this.handle = new Proxy({}, {
            get: (_, property) => {
                return (...args) => {
                    return this.callHandler(property, ...args);
                };
            },
        });
    }
    /**
     * Get the callback id from the callback reference
     * @param cbRef The callback reference
     * @returns The callback id, otherwise null when not found
     */
    getCallbackIdFromCallbackRef(cbRef) {
        for (const [callbackId, callback] of this.callbackMap.entries()) {
            if (callback === cbRef) {
                return callbackId;
            }
        }
        return null;
    }
    /**
     * Convenience method to initialize an IPCClient and retuning immediately the
     * handle object to interact with the IPC server.
     * @param name The name of the client used to identify the source of messages by the audience
     * @param destination The name of the target audience for messages
     * @param messageChannel The message channel used to send and receive messages
     * @returns The IPC handle
     */
    static createIPCClient(name, destination, messageChannel) {
        const instance = new IPCClient(name, destination, messageChannel);
        return instance.handle;
    }
    /**
     * Recursively replaces all function refs with an object containing a tag used
     * to signal to the IPC server the presence of a function at the current property.
     * This allows to serialize nested functions in objects.
     * @param object The object to replace function refs in
     * @returns A new object with all function refs replaced
     */
    insertFunctionTags(input) {
        // Replace all function refs with IPC rags
        if (typeof input === 'function') {
            const callbackId = uuidv4();
            this.callbackMap.set(callbackId, input);
            return {
                [IPC_FUNCTION_TAG_PROPERTY]: IPC_FUNCTION_TAG_VALUE,
                callbackId,
            };
        }
        // Attempt to insert IPC function tags per each element in the array
        if (Array.isArray(input)) {
            return input.map((value) => this.insertFunctionTags(value));
        }
        // Iterate through the object properties to replace function refs with IPC tags
        if (input && typeof input === 'object') {
            const outObj = Object.assign({}, input);
            for (const [key, value] of Object.entries(outObj)) {
                if (typeof value === 'function') {
                    const callbackId = uuidv4();
                    this.callbackMap.set(callbackId, value);
                    outObj[key] = {
                        [IPC_FUNCTION_TAG_PROPERTY]: IPC_FUNCTION_TAG_VALUE,
                        callbackId,
                    };
                }
                outObj[key] = this.insertFunctionTags(value);
            }
            return outObj;
        }
        // Basic types
        return input;
    }
    /**
     * Given a function name and arguments, send a request to the IPC server
     * @param functionName The name of the function to call on the IPC server
     * @param args The arguments to pass to the function
     * @returns A promise that will resolve with the result of the function call
     */
    callHandler(functionName, ...args) {
        const id = uuidv4();
        const msg = {
            type: "Request" /* MessageType.Request */,
            id,
            functionName,
            functionArgs: args.map((arg) => {
                // Function references cannot be serialized.
                // Replace them with a callbackId that the server can use to notify the client.
                // Use the already existing id when the callback is already registered
                const existingCallbackId = this.getCallbackIdFromCallbackRef(arg);
                if (existingCallbackId) {
                    return {
                        type: "Callback" /* ArgumentType.Callback */,
                        callbackId: existingCallbackId,
                    };
                }
                // Iterate objects and arrays to replace all functions with IPC tags
                arg = this.insertFunctionTags(arg);
                // Create a new callback id when the callback is not registered
                if (typeof arg === 'function') {
                    const callbackId = uuidv4();
                    this.callbackMap.set(callbackId, arg);
                    return {
                        type: "Callback" /* ArgumentType.Callback */,
                        callbackId,
                    };
                }
                return {
                    type: "Object" /* ArgumentType.Object */,
                    value: arg,
                };
            }),
            source: this.name,
            destination: this.destination,
        };
        return new Promise((resolve, reject) => {
            this.requestIdToPromiseMap.set(id, { resolve, reject });
            this.send(this.name, msg);
        });
    }
    /**
     * Handle incoming messages from the IPC server
     * @param data The incoming message
     */
    messageHandler(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const msg = data;
            if (msg.destination !== this.name)
                return;
            switch (msg.type) {
                case "Response" /* MessageType.Response */:
                    this.handleResponse(msg);
                    break;
                case "CallbackRequest" /* MessageType.CallbackRequest */:
                    this.handleCallbackRequest(msg);
                    break;
                case "CallbackGarbageCollection" /* MessageType.CallbackGarbageCollection */:
                    this.handleCallbackGarbageCollection(msg);
                    break;
                default:
                    throw new IPCError(`Unexpected or unsupported message type: ${msg.type}`);
            }
        });
    }
    /**
     * Handle an incoming response message from the IPC server
     * @param msg The incoming response message
     */
    handleResponse(msg) {
        const requestPromise = this.requestIdToPromiseMap.get(msg.id);
        if (!requestPromise)
            return;
        const { resolve, reject } = requestPromise;
        if (msg.success) {
            resolve(msg.data);
        }
        else {
            reject(msg.data);
        }
        this.requestIdToPromiseMap.delete(msg.id);
    }
    /**
     * Handle an incoming callback request message from the IPC server
     * @param msg The incoming callback request message
     */
    handleCallbackRequest(msg) {
        return __awaiter(this, void 0, void 0, function* () {
            const callback = this.callbackMap.get(msg.callbackId);
            if (!callback) {
                return;
            }
            let data;
            let success;
            try {
                const fnArgs = msg.functionArgs.map((arg) => arg.value);
                data = yield callback(...fnArgs);
                success = true;
            }
            catch (err) {
                data = err;
                success = false;
            }
            const response = {
                type: "CallbackResponse" /* MessageType.CallbackResponse */,
                requestId: msg.requestId,
                callbackId: msg.callbackId,
                source: this.name,
                destination: msg.source,
                success,
                data,
            };
            this.send(this.name, response);
        });
    }
    /**
     * Handle an incoming callback garbage collection message from the IPC server
     * @param msg The incoming callback garbage collection message
     */
    handleCallbackGarbageCollection(msg) {
        this.callbackMap.delete(msg.callbackId);
    }
}
