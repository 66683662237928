import { StateCreator } from 'zustand';
// import { PrompterMode } from '@fluidprompter/core';

export enum MediaSourceType {
  None = 'none',
  Webcam = 'webcam',
  Desktop = 'desktop',
  Remote = 'remote',
}

export interface IPrompterMediaSlice {
  mediaSourceType: MediaSourceType;
  setMediaSourceType: (backgroundType: MediaSourceType) => void;
}

const createPrompterMediaSlice: StateCreator<
  IPrompterMediaSlice,
  [],
  [],
  IPrompterMediaSlice
> = (set, get) => ({
  mediaSourceType: MediaSourceType.None,
  setMediaSourceType: (mediaSourceType: MediaSourceType) => set(() => ({ mediaSourceType })),
});

export default createPrompterMediaSlice;