import EasingFunctions, { EasingFunctionKeys, EasingFunctionTypes } from '../../utils/EasingFunctions';

/**
 * The maximum time permitted to elapsed between sync messages sent from the leader prompter to any
 * peer prompters.
 */
const MAXIMUM_TIME_BETWEEN_SYNC_MESSAGES_MS = 1000;

class PositionLedgerEntry {

  public type: string;
  public timestamp: number;
  public speed: number;
  public reversed: boolean;
  public elapsed: number;
  private _scrollPosition: number;
  public scrollPositionMax?: number;
  public pauseAtPosition: number;

  /**
   * The performance.now() timestamp for when the keyframe was last set.
   */
  public keyframeTimestamp: number;

  /**
   * The scroll position we were at when the keyframe was last set.
   */
  public keyframeScrollPosition: number;

  /**
   * skipStart is the scrollPosition where a skip animation should begin from.
   */
  public skipStartPosition: number;

  // private _skipEasingFunction: EasingFunctionKeys = "Linear";
  private _skipEasingFunction: EasingFunctionKeys;
  // private _skipEasingFunction: EasingFunctionKeys = "Quadratic";
  // private _skipEasingFunction: EasingFunctionKeys = "Cubic";
  // private _skipEasingFunction: EasingFunctionKeys = "Circular";
  private _skipEasingFunctionType: EasingFunctionTypes;


  /**
   * skipOffset is the number of pixels (positive or negative) that we want to
   * skip forward or back on screen over some length of time.
   */
  private _skipOffset: number;

  /**
   * skipTime is the length of time in ms over which we want to complete the
   * skip animation. For smaller skips, we may choose a smaller length of time.
   * For larger skips, we may choose a larger length of time.
   */
  public skipTimeTotal: number;

  /**
   * skipSetAtTime is the origin timestamp in milliseconds at which the skip was set.
   */
  private skipSetAtTime: number;


  private syncAdjustmentDistance: number;
  private syncAdjustmentDuration: number;
  private syncAdjustmentSetAtTime: number;

  /**
   * The timestamp at which the last sync message was sent to any connected peers from this peer.
   * Sync messages include a "node changed" message when the prompter moves from one block element
   * to the next block element in the script, but in the case of a really long block element (long
   * paragraph of text), we will supplement with our own 'sync' message every 1000ms.
   */
  private syncLastSyncTransmittedTime: number;

  // private _syncAdjustmentEasingFunction: EasingFunctionKeys = 'Linear';
  // private _syncAdjustmentEasingFunctionType: EasingFunctionTypes = 'InOut';

  /**
   * Constructor for `PositionLedgerEntry` class. Used to create a new instance
   * of `PositionLedgerEntry` when you have no previous instance to clone.
   * @param isPlaying
   * @param currentScrollSpeed
   */
  constructor(isPlaying: boolean, reversed: boolean, currentScrollSpeed: number, scrollPositionMax?: number) {
    // console.log('PositionLedgerEntry.constructor()');
    this.elapsed = 0;
    this._scrollPosition = 0;
    this.pauseAtPosition = 0;
    this.keyframeScrollPosition = 0;
    this.skipStartPosition = 0;
    this._skipEasingFunction = 'Sinusoidal';
    this._skipEasingFunctionType = 'InOut';
    this._skipOffset = 0;
    this.skipTimeTotal = 0;
    this.skipSetAtTime = 0;

    // SyncAdjustment attributes
    this.syncAdjustmentDistance = 0;
    this.syncAdjustmentDuration = 0;
    this.syncAdjustmentSetAtTime = 0;
    this.syncLastSyncTransmittedTime = 0;

    // Initialize based on constructor parameters.
    this.type = isPlaying ? 'play' : 'pause';
    this.speed = currentScrollSpeed;
    this.reversed = reversed;
    this.timestamp = performance.now();
    this.keyframeTimestamp = this.timestamp;    // This seed value will be replaced in clone() method if we aren't creating our very first frame.
    this.scrollPositionMax = scrollPositionMax;

    //
    // Ease in animation
    //
    // const easeInTime = 500;
    // const easeInDistance = currentScrollSpeed * easeInTime / 1000;
    // this.setCurrentSkip(easeInDistance, easeInTime, 'Sinusoidal');
  }

  get scrollPosition() {
    return this._scrollPosition;
  }
  set scrollPosition(value: number) {
    if(Number.isNaN(value)){
      throw new Error('scrollPosition cannot be set to NaN!');
    }
    this._scrollPosition = value;
  }

  get skipOffset() {
    return this._skipOffset;
  }
  private set skipOffset(value: number) {
    if(Number.isNaN(value)){
      throw new Error('skipOffset cannot be set to NaN!');
    }
    this._skipOffset = value;
  }

  /**
   * Clone a PositionLedgerEntry by creating a copy of the current ledger entry
   * values and applying any state change for 'isPlaying'. This is used during
   * each animation frame to record any change in the prompter position between
   * frames.
   * @param isPlaying
   * @param currentScrollSpeed
   * @returns
   */
  clone(isUserPlaying: boolean, reversed: boolean, currentScrollSpeed: number, scrollPositionMax?: number): PositionLedgerEntry {
    // console.log('PositionLedgerEntry.clone()');

    const isAnimationPlaying = this.skipAnimationInProgress();
    const isPlaying = isUserPlaying || isAnimationPlaying;

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const previousLedger = this;
    const newPositionLedgerEntry = new PositionLedgerEntry(isPlaying, reversed, currentScrollSpeed, scrollPositionMax);

    const newPlayTime = newPositionLedgerEntry.timestamp - previousLedger.timestamp;

    // newPositionLedgetEntry.timestamp = (previousLedger && previousLedger.type === 'play') ? previousLedger.timestamp : performance.now();
    newPositionLedgerEntry.elapsed = (isPlaying && previousLedger && previousLedger.type === 'play') ? previousLedger.elapsed + newPlayTime : previousLedger.elapsed;
    newPositionLedgerEntry.scrollPosition = previousLedger.scrollPosition;
    newPositionLedgerEntry.scrollPositionMax = scrollPositionMax || previousLedger.scrollPositionMax;
    newPositionLedgerEntry.pauseAtPosition = previousLedger.pauseAtPosition;

    newPositionLedgerEntry.keyframeTimestamp = previousLedger.keyframeTimestamp;
    newPositionLedgerEntry.keyframeScrollPosition = previousLedger.keyframeScrollPosition;

    //
    // If we are not done performing a skip animation, then copy our skip state data to the next frame.
    //
    if(previousLedger.skipOffset !== 0 && previousLedger.skipTimeTotal > 0) {
      // We're not done out skip animation yet! Keep this data in our state object.
      newPositionLedgerEntry.skipSetAtTime = previousLedger.skipSetAtTime;
      newPositionLedgerEntry.skipStartPosition = previousLedger.skipStartPosition;
      newPositionLedgerEntry.skipOffset = previousLedger.skipOffset;
      newPositionLedgerEntry.skipTimeTotal = previousLedger.skipTimeTotal;
    }

    //
    // Copy our syncAdjustment fields
    //
    if(previousLedger.syncAdjustmentDistance !== 0 && previousLedger.syncAdjustmentDuration > 0) {
      newPositionLedgerEntry.syncAdjustmentDistance = previousLedger.syncAdjustmentDistance;
      newPositionLedgerEntry.syncAdjustmentDuration = previousLedger.syncAdjustmentDuration;
      newPositionLedgerEntry.syncAdjustmentSetAtTime = previousLedger.syncAdjustmentSetAtTime;
    }
    newPositionLedgerEntry.syncLastSyncTransmittedTime = previousLedger.syncLastSyncTransmittedTime;

    // If we just toggled play/pause state, then we will record a keyframe from which to
    // calculate any scroll position on future animation frames.
    if(previousLedger.type !== newPositionLedgerEntry.type) {
      //
      // Ease in animation
      //
      /*
      console.log('previousLedger.type !== newPositionLedgetEntry.type');
      if(newPositionLedgetEntry.type === 'play') {
        // We transitioned from not playing to playing (east up to play speed)
        const easeInTime = 2500;
        const easeInDistance = currentScrollSpeed * easeInTime / 1000;
        newPositionLedgetEntry.setCurrentSkip(easeInDistance, easeInTime, 'Cubic', 'Out');
      } else {
        // We transitioned from playing to paused/editing (ease to a stop)
      }
      */

      //
      //
      //
      newPositionLedgerEntry.updateKeyframe();
    }

    // 1. Calculate next scroll position based on the last keyframe position and the time elapsed
    // since the keyframe multipled by current scroll speed.
    newPositionLedgerEntry.calculateNextPosition(this.reversed, previousLedger.speed);

    // If we just changed the prompter scroll speed we want to record a keyframe AFTER the
    // current frames scroll position has been calculated above.
    if(previousLedger.speed !== newPositionLedgerEntry.speed
        || previousLedger.reversed !== newPositionLedgerEntry.reversed)
    {
      newPositionLedgerEntry.updateKeyframe();
    }

    // 2. Calculate any pending skip animation
    newPositionLedgerEntry.applyCurrentSkipAnimation();

    // 3. Adjust our scrolling progress for any sync error between networked prompters.
    newPositionLedgerEntry.applyCurrentSyncAdjustment();

    // 4. If we have any pending pause position, check if that is where we are at.
    newPositionLedgerEntry.checkPendingPausePosition();

    // 5. Check if we have reached the beginning or end of the script and need to pause.
    newPositionLedgerEntry.pauseWhenReachedEnd();

    return newPositionLedgerEntry;
  }

  updateKeyframe() {
    this.keyframeTimestamp = this.timestamp;
    this.keyframeScrollPosition = this.scrollPosition;
    // console.log(`keyframe set at ${this.keyframeScrollPosition}`);
  }

  calculateNextPosition(previousReversed: boolean, previousSpeed: number) {
    // const newScrollAmount = (this.type === 'play') ? (this.speed * newPlayTime / 1000) : 0;
    // this.scrollPosition = this.scrollPosition + newScrollAmount;
    if(this.type === 'play') {
      const playtimeSinceKeyframe = this.timestamp - this.keyframeTimestamp;
      const scrollSinceKeyframe = previousSpeed * (previousReversed ? -1.5 : 1) * playtimeSinceKeyframe / 1000;
      this.scrollPosition = this.keyframeScrollPosition + scrollSinceKeyframe;
    }
  }

  applyCurrentSkipAnimation() {
    const skipTimeElapsed = performance.now() - this.skipSetAtTime;

    //
    // If we have a skip animation in progress... let's do the math for where we are in this
    // animation!
    //
    if(this.skipOffset !== 0 && this.skipTimeTotal > 0)
    {
      // Calculate how far we are through our skip animation as a number between 0.0 - 1.0
      let completionFraction = (skipTimeElapsed / this.skipTimeTotal);

      // Because the length of time between frames is usually 16-33 ms, we may not end
      // *exactly* on the target completion time. If we have a completion fraction greater
      // than 1, let's just finish exactly.
      if(completionFraction > 1) { completionFraction = 1.0; }

      // Calculate the target scroll position for our current position in the skip animation.
      let proposedscrollPosition = this.skipStartPosition + (this.skipOffset * completionFraction);
      // Easing function
      if(this._skipEasingFunction) {
        proposedscrollPosition = this.skipStartPosition + (this.skipOffset * EasingFunctions[this._skipEasingFunction][this._skipEasingFunctionType](completionFraction));
      }
      this.scrollPosition = (proposedscrollPosition < 0) ? 0 : proposedscrollPosition;

      /*
      const skipTimeCompleted = previousLedger.skipTimeCompleted + newPlayTime;
      const easeAmount = 1 - Math.pow(1 - (skipTimeCompleted / previousLedger.skipTimeTotal), 3);
      skipAmount = previousLedger.skipOffset * easeAmount;
      /-*
      function easeOutCubic(x: number): number {
          return 1 - pow(1 - x, 3);
      }
      */
    }

    //
    // If the current skip animation is done, let's clean-up!
    //
    if(this.skipTimeTotal && skipTimeElapsed >= this.skipTimeTotal) {
      // We reached the end of a skip animation. Clear out our skip related ledger fields.
      this.skipSetAtTime = 0;
      this.skipStartPosition = 0;
      this.skipOffset = 0;
      this.skipTimeTotal = 0;

      // Record a keyframe for the end position of this skip animation.
      this.updateKeyframe();
    }
  }

  /**
   * Update the timestamp at which we last transmitted a sync message to any connected peers from
   * this prompter.
   */
  syncMessageWasSent() {
    this.syncLastSyncTransmittedTime = performance.now();
  }

  /**
   * Checks the last timestamp we transmitted a sync message to any connected peers.
   * If >= 1000ms has elapsed since we last sent a sync message, and we are not paused, then yes we
   * want to send a sync message.
   * @returns True if we should send a 'sync' message
   */
  syncMessageRequired(): boolean {
    return this.type !== 'pause'
      && (performance.now() - this.syncLastSyncTransmittedTime) >= MAXIMUM_TIME_BETWEEN_SYNC_MESSAGES_MS;
  }

  /**
   * Check if our sync adjustment distance and duration are both non-zero values.
   * @returns True if a sync adjustment is in progress.
   */
  syncAdjustmentInProgress(): boolean {
    return this.syncAdjustmentDistance !== 0 && this.syncAdjustmentDuration > 0;
  }

  /**
   * Set a new sync-adjustment distance and time over which to apply the adjustment distance. If
   * there was a sync-adjustment in progress that has not finished yet, we need include the portion
   * of that sync adjustment that was completed in our future scroll positions.
   */
  setSyncAdjustment(syncAdjustmentDistance: number, syncAdjustmentDuration: number) {
    // We don't attempt to syncronize the prompter scrolling while a skip animation is in progress
    // (up, down, page up, page down, home, end, navigating to sections within a script)
    if(this.skipAnimationInProgress()) {
      return;
    }

    // If we had a sync adjustment in progress, record a key frame at our current position from
    // which the next sync adjustment wil be applied.
    if(this.syncAdjustmentInProgress()) {
      // console.log(`${performance.now()} PositionLedgerEntry.setSyncAdjustment().syncAdjustmentInProgress() === true`, {
      //   ...this
      // });

      // Save a key frame from which the new sync adjustment will be calculated.
      this.updateKeyframe();
    }

    // Now set our new sync-adjustement relative to the position we were at.
    this.syncAdjustmentDistance = syncAdjustmentDistance;
    this.syncAdjustmentDuration = syncAdjustmentDuration;
    this.syncAdjustmentSetAtTime = performance.now();
  }

  /**
   * Clear any sync-adjustment that was in-progress. This will be called when we pause the prompter
   * to reset our animation state.
   */
  clearSyncAdjustment() {
    this.syncAdjustmentDistance = 0;
    this.syncAdjustmentDuration = 0;
    this.syncAdjustmentSetAtTime = 0;
  }

  /**
   * Calculate how much time has passed since the current sync adjustment was set and apply the
   * offset portion that has elapsed to the current scroll position.
   */
  applyCurrentSyncAdjustment() {
    const elapsedSinceSyncAdjustment = performance.now() - this.syncAdjustmentSetAtTime;

    if(this.syncAdjustmentInProgress())
    {
      // Calculate how far we are through our sync adjustment as a number between 0.0 - 1.0
      let completionFraction = (elapsedSinceSyncAdjustment / this.syncAdjustmentDuration);

      // Because the length of time between frames is usually 16-33 ms, we may not end
      // *exactly* on the target completion time. If we have a completion fraction greater
      // than 1, let's just finish exactly.
      if(completionFraction > 1) { completionFraction = 1.0; }

      // const syncAdjustmentDistanceComplete = (this.syncAdjustmentDistance * completionFraction);
      // console.log(`${syncAdjustmentDistanceComplete}px / ${this.syncAdjustmentDistance}px in ${elapsedSinceSyncAdjustment}ms / ${this.syncAdjustmentDuration}`);

      // Easing function
      let proposedScrollPosition = 0;
      // if(this._syncAdjustmentEasingFunction && this._syncAdjustmentEasingFunctionType) {
      //   proposedScrollPosition = this.scrollPosition
      //     + (this.syncAdjustmentDistance * EasingFunctions[this._skipEasingFunction][this._skipEasingFunctionType](completionFraction));
      // } else {
      //   // Calculate the target scroll position for our current position in the skip animation.
      proposedScrollPosition = this.scrollPosition + (this.syncAdjustmentDistance * completionFraction);
      //}
      this.scrollPosition = (proposedScrollPosition < 0) ? 0 : proposedScrollPosition;
    }

    //
    // If the current sync adjustment is done, let's clean-up!
    //
    if(this.syncAdjustmentDuration && elapsedSinceSyncAdjustment >= this.syncAdjustmentDuration) {
      // We reached the end of a sync adjustment. Clear out our sync related ledger fields.
      this.clearSyncAdjustment();

      // Record a keyframe for the end position of this skip animation.
      this.updateKeyframe();
    }
  }

  /**
   * Returns true, if skip animation is in progress.
   * @returns True, if skip animation is in progress.
   */
  skipAnimationInProgress(): boolean {
    return (this.skipSetAtTime !== 0
      || this.skipStartPosition !== 0
      || this.skipOffset !== 0
      || this.skipTimeTotal !== 0
    );
  }

  checkPendingPausePosition() {
    if(this.pauseAtPosition <= 0) {
      return;
    }

    //
    // If we have a target position to pause at, and we just passed that position, then let's stop here!
    //
    if(
      this.reversed
        ? (this.scrollPosition <= this.pauseAtPosition) // Pause position while scrolling in reverse
        : (this.scrollPosition >= this.pauseAtPosition) // Pause position while scrolling forwards
    ) {
      this.scrollPosition = this.pauseAtPosition;
      this.pauseAtPosition = 0; // Clear out the target pause position as we just arrived here!
      // continueAnimation = false;
      // prompterSession.pause();

      this.type = 'pause';    // We hit the target pause position! Let's do the pause.
    }
  }

  setCurrentSkip(skipOffset: number, skipTimeTotal: number, easingFunction?: EasingFunctionKeys, easingFunctionType?: EasingFunctionTypes) {
    // Anytime we begin a skip animation for larger scale scrolling animation (up, down, page up,
    // page down, home, end, navigating to sections within a script), we will clear out any current
    // sync adjustment
    this.clearSyncAdjustment();

    // Record a keyframe for the start of this skip animation (which also finalized any sync
    // adjustment that was previously in progress)
    this.updateKeyframe();

    //
    // Now we can begin our skip annimation and it won't have any interference from sync
    // adjustments.
    this.skipSetAtTime = performance.now();
    this.skipStartPosition = this.scrollPosition;

    let proposedSkipOffset = skipOffset;
    if(skipOffset < 0 && Math.abs(skipOffset) > this.scrollPosition) { proposedSkipOffset = -this.scrollPosition; }
    if(this.scrollPositionMax) {
      const skipOffsetMax = this.scrollPositionMax - this.scrollPosition;
      if(proposedSkipOffset > skipOffsetMax) { proposedSkipOffset = skipOffsetMax; }
    }
    this._skipOffset = proposedSkipOffset;

    this.skipTimeTotal = skipTimeTotal;

    if(easingFunction) {
      this._skipEasingFunction = easingFunction;
    }
    this._skipEasingFunctionType = (easingFunctionType !== undefined) ? easingFunctionType : 'InOut';  // 'In', 'Out', 'InOut'
    // console.log(`setCurrentSkip(${skipOffset}, ${skipTimeTotal}, ${easingFunction})`);
  }

  pauseWhenReachedEnd() {
    if(this.scrollPosition < 0) {
      this.scrollPosition = 0;
      this.type = 'pause';    // The next/current frame should be in paused state as we hit the minimum scroll position.
    }
    if(this.scrollPositionMax && this.scrollPositionMax > 0 && this.scrollPosition > this.scrollPositionMax) {
      this.scrollPosition = this.scrollPositionMax;
      this.type = 'pause';    // The next/current frame should be in paused state as we hit the maximum scroll position.
    }
  }
}

export default PositionLedgerEntry;